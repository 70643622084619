import './ParcoursTdsFdc.css';
import React, { useEffect, useContext } from 'react';
import ParcoursCarousel from '../../organisms/parcoursCarousel/ParcoursCarousel';
import TrouverSociete from '../../forms/parcours/titresSocietes/step2/TrouverSociete';
import DefinitionAffaire from '../../forms/parcours/titresSocietes/step3/DefinitionAffaire';
import BilanComptable from '../../forms/parcours/titresSocietes/step4/BilanComptable';
import ImpotsTaxes from '../../forms/parcours/titresSocietes/step5/ImpotsTaxes';
import ChargesRedevance from '../../forms/parcours/titresSocietes/step6/ChargesRedevances';
import Retraitements from '../../forms/parcours/titresSocietes/step7/Retraitements';
import Emplacement from '../../forms/parcours/titresSocietes/step8/Emplacement';
import CaracteristiquesLocalFdc from '../../forms/parcours/titresSocietes/step9/CaracteristiquesLocalFdcTds';
import LoyerBail from '../../forms/parcours/titresSocietes/step10/LoyerBail';
import NormesAccessibilite from '../../forms/parcours/titresSocietes/step11/NormesAccessibilite';
import RatioClefs from '../../forms/parcours/titresSocietes/step12/RatioClefs';
import Reputation from '../../forms/parcours/titresSocietes/step13/Reputation';
import { ParcoursContext } from '../../../context/ParcoursContext';
import StarterTdsFdc from '../../forms/parcours/titresSocietes/step1/StarterTdsFdc';

function ParcoursTdsFdc() {
  const {
    estimationValues,
    setInputsNames,
    step,
    dependanceLocalCommercial,
    parcoursType,
    setStepsSummary,
    typesCommerce,
  } = useContext(ParcoursContext);

  useEffect(() => {
    initParcours();
  }, []);

  useEffect(() => {
    setInputsNames(array => {
      array[3] =
        parcoursType === 2 ? 'immobilisations_financieres' : typesCommerce.includes(1) ? 'ca_hebergement_ht' : 'ca_ht';
      array[10] = dependanceLocalCommercial ? 'etat_materiel' : 'accessibilite_pmr';
      return array;
    });
  }, [estimationValues]);

  function setTdsFdcInputsNames() {
    setInputsNames([
      '', // starter
      'raison_sociale',
      'activite',
      parcoursType === 2 ? 'immobilisations_financieres' : typesCommerce.includes(1) ? 'ca_hebergement_ht' : 'ca_ht',
      'total_impots',
      'redevance',
      'salaire_gerant_annuel',
      'type_ville0',
      'largeur_facade',
      'soumission_bail',
      dependanceLocalCommercial ? 'etat_materiel' : 'accessibilite_pmr',
      'salaries',
      'note_google',
    ]);
  }

  function initParcours() {
    setTdsFdcInputsNames();

    setStepsSummary([
      {
        name: "L'entreprise",
        steps: [1, 2, 3],
      },
      {
        name: 'Les chiffres',
        steps: [4, 5, 6, 7],
      },
      {
        name: "L'emplacement",
        steps: [8, 9, 10, 11],
      },
      {
        name: 'Le complément',
        steps: [12, 13],
      },
    ]);
  }

  return (
    <ParcoursCarousel addBottomMargin={step == 2} step={step}>
      <StarterTdsFdc index={1} />
      <TrouverSociete index={2} />
      <DefinitionAffaire index={3} />
      <BilanComptable index={4} />
      <ImpotsTaxes index={5} />
      <ChargesRedevance index={6} />
      <Retraitements index={7} />
      <Emplacement index={8} />
      <CaracteristiquesLocalFdc index={9} />
      <LoyerBail index={10} />
      <NormesAccessibilite index={11} />
      <RatioClefs index={12} />
      <Reputation index={13} />
    </ParcoursCarousel>
  );
}

export default ParcoursTdsFdc;
