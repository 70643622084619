import './CalculateurCotisationsForm.css';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toInt, toggleCollapseInput, removeSpaces, toggleCollapseElement } from '../../../utils/Utils';
import Toggle from '../../molecules/formComponents/toggle/Toggle';
import NumberInput from '../../molecules/formComponents/numberInput/NumberInput';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import Cross from '../../atoms/icons/general/cross/Cross';
import Trash from '../../atoms/icons/general/trash/Trash';

function CalculateurCotisationsForm({ visible, setVisible, setParcoursValues, className = '' }) {
  const { register, setValue, watch, setValues } = useForm();
  const Form = { register, setValue, watch };

  const parcours = visible !== undefined;

  const [tnsFloors, setTnsFloors] = useState([]);
  const [salariesFloors, setSalariesFloors] = useState([]);
  const [typeExploitant, setTypeExploitant] = useState(1);
  const [remunerations, setRemunerations] = useState([
    {
      type: typeExploitant,
      remunerationNette: null,
      cotSociales: null,
      netMensuel: null,
      netAnnuel: null,
      brutMensuel: null,
      brutAnnuel: null,
      cotPatronMensuelles: null,
      cotPatronAnnuelles: null,
      coutEntrepriseMensuel: null,
      coutEntrepriseAnnuel: null,
    },
  ]);
  const [shouldDeleteData, setShouldDeleteData] = useState(true);
  const [visibleRemuneration, setVisibleRemuneration] = useState(0);
  const ref = useRef(null);
  const tnsRef = useRef(null);
  const salarieRef = useRef(null);

  useEffect(() => {
    setTnsArray();
    setSalarieArray();
  }, []);

  useEffect(() => {
    if (typeExploitant === 1) {
      toggleCollapseElement(tnsRef.current, false);
      toggleCollapseElement(salarieRef.current, true);
    }
    if (typeExploitant === 0) {
      toggleCollapseElement(tnsRef.current, true);
      toggleCollapseElement(salarieRef.current, false);
    }
    toggleRemunerationType(visibleRemuneration, typeExploitant);
  }, [typeExploitant]);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseInput(ref.current, visible, 500);
  }, [visible]);

  useEffect(() => {
    showRemuneration(visibleRemuneration);
  }, [visibleRemuneration]);

  function reportParcoursValues() {
    const values = {
      salaire: 0,
      charges: 0,
    };
    for (const index of remunerations) {
      values.salaire += index.brutAnnuel;
      values.salaire += index.remunerationNette;

      values.charges += index.cotSociales;
      values.charges += index.cotPatronAnnuelles;
    }

    setParcoursValues(values.salaire, values.charges);
    setVisible(false);
  }

  function updateRemuneration(values, index = visibleRemuneration) {
    const tmp = [...remunerations];

    for (const key of Object.keys(values)) {
      tmp[index][key] = Math.round(values[key]);
    }

    setRemunerations(tmp);
  }

  function toggleRemunerationType(index, type) {
    if (!shouldDeleteData) {
      setShouldDeleteData(true);
      return;
    }

    const tmp = [...remunerations];
    for (const key of Object.keys(tmp[index])) {
      tmp[index][key] = null;
    }
    setValuesFromArray(tmp[index]);
    tmp[index].type = type;

    setRemunerations(tmp);
  }

  function deleteRemuneration(e, index) {
    e.stopPropagation();
    const tmp = [...remunerations];
    tmp.splice(index, 1);
    if (visibleRemuneration === remunerations.length - 1) setVisibleRemuneration(visibleRemuneration - 1);
    else if (index === visibleRemuneration) showRemuneration(index + 1, true);

    setRemunerations(tmp);
  }

  function addRemuneration() {
    const tmp = [...remunerations];
    tmp.push({
      type: typeExploitant,
      remunerationNette: null,
      cotSociales: null,
      netMensuel: null,
      netAnnuel: null,
      brutMensuel: null,
      brutAnnuel: null,
      cotPatronMensuelles: null,
      cotPatronAnnuelles: null,
      coutEntrepriseMensuel: null,
      coutEntrepriseAnnuel: null,
    });

    setRemunerations(tmp);
    setVisibleRemuneration(tmp.length - 1);
  }

  function showRemuneration(index, force = false) {
    if (!force) setVisibleRemuneration(index);
    if (remunerations[index].type !== typeExploitant) {
      setTypeExploitant(remunerations[index].type);
      setShouldDeleteData(false);
    }

    for (const key of Object.keys(remunerations[index])) {
      setValue(key, remunerations[index][key]);
    }
  }

  function setFloor(array, fieldRef, field, percentField) {
    array.forEach(element => {
      element[field] = Math.floor(element[fieldRef] * (percentField ? element[percentField] / 100 : 12));
    });
    return array;
  }

  function getValueFromFloor(floorsArray, value, refField, percent, multiply = true) {
    let index = -1;
    floorsArray.forEach(element => {
      if (Number(value) >= element[refField]) index++;
    });
    return Number(
      multiply
        ? (value * floorsArray[index === -1 ? 0 : index][percent]) / 100
        : value / (floorsArray[index === -1 ? 0 : index][percent] / 100),
    );
  }

  function getPercent(floorsArray, value, refField, percent) {
    let index = -1;
    floorsArray.forEach(element => {
      if (value >= element[refField]) index++;
    });
    return floorsArray[index === -1 ? 0 : index][percent] / 100;
  }

  function setTnsArray() {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/calcul-cotisation/calctns`)
        .then(res => {
          let tmp = [];

          for (let i = 0; i < res.data.length; i++) {
            tmp.push({
              remunerationNette: res.data[i].rem_nette_tns,
              ratio: res.data[i].ratio,
            });
          }
          tmp = setFloor(tmp, 'remunerationNette', 'cotSociales', 'ratio');

          setTnsFloors(tmp);
        })
        .catch(error => console.log(error));
    } catch (error) {
      console.log(error);
    }
  }

  function sortRows(a, b) {
    return Number(a.net_annuel) - Number(b.net_annuel);
  }

  function setSalarieArray() {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/calcul-cotisation/calcsalarie`)
        .then(res => {
          res.data = res.data.sort(sortRows);
          let tmp = [];
          for (let i = 0; i < res.data.length; i++) {
            tmp.push({
              netAnnuel: res.data[i].net_annuel,
              ratio_cot_soc_salarie: res.data[i].ratio_cot_soc_salarie,
              ratio_cot_soc_patronales: res.data[i].ratio_cot_soc_patronales,
            });
          }
          tmp = setFloor(tmp, 'netAnnuel', 'cotSalarieAnnuelles', 'ratio_cot_soc_salarie');
          tmp.forEach(element => {
            element.brutAnnuel = Number(element.netAnnuel) + Number(element.cotSalarieAnnuelles);
          });
          tmp = setFloor(tmp, 'brutAnnuel', 'cotPatronAnnuelles', 'ratio_cot_soc_patronales');
          tmp.forEach(element => {
            element.coutEntrepriseAnnuel = Number(element.brutAnnuel) + Number(element.cotPatronAnnuelles);
          });

          tmp = setFloor(tmp, 'netAnnuel', 'netMensuel');
          tmp = setFloor(tmp, 'brutAnnuel', 'brutMensuel');
          tmp = setFloor(tmp, 'coutEntrepriseAnnuel', 'coutEntrepriseMensuel');

          setSalariesFloors(tmp);
        })
        .catch(error => console.log(error));
    } catch (error) {
      console.log(error);
    }
  }

  function getFromNetMensuel(e) {
    const values = {};
    values.netMensuel = Number(e.target.value);
    values.netAnnuel = values.netMensuel * 12;
    values.brutAnnuel =
      values.netAnnuel + getValueFromFloor(salariesFloors, values.netAnnuel, 'netAnnuel', 'ratio_cot_soc_salarie');
    values.brutMensuel = Math.round(values.brutAnnuel / 12);
    values.cotPatronAnnuelles = getValueFromFloor(
      salariesFloors,
      values.brutAnnuel,
      'brutAnnuel',
      'ratio_cot_soc_patronales',
    );
    values.cotPatronMensuelles = Math.round(values.cotPatronAnnuelles / 12);
    values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
    values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

    setValuesFromArray(values);
  }

  function getFromNetAnnuel(e) {
    const values = {};
    values.netAnnuel = Number(e.target.value);
    values.netMensuel = values.netAnnuel / 12;
    values.brutAnnuel =
      values.netAnnuel + getValueFromFloor(salariesFloors, values.netAnnuel, 'netAnnuel', 'ratio_cot_soc_salarie');
    values.brutMensuel = Math.round(values.brutAnnuel / 12);
    values.cotPatronAnnuelles = getValueFromFloor(
      salariesFloors,
      values.brutAnnuel,
      'brutAnnuel',
      'ratio_cot_soc_patronales',
    );
    values.cotPatronMensuelles = Math.round(values.cotPatronAnnuelles / 12);
    values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
    values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

    setValuesFromArray(values);
  }

  function getFromBrutMensuel(e) {
    const values = {};
    values.brutMensuel = Number(e.target.value);
    values.brutAnnuel = values.brutMensuel * 12;
    values.netAnnuel =
      values.brutAnnuel / (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
    values.netMensuel = values.netAnnuel / 12;
    values.cotPatronAnnuelles = getValueFromFloor(
      salariesFloors,
      values.brutAnnuel,
      'brutAnnuel',
      'ratio_cot_soc_patronales',
    );
    values.cotPatronMensuelles = Math.round(values.cotPatronAnnuelles / 12);
    values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
    values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

    setValuesFromArray(values);
  }

  function getFromBrutAnnuel(e) {
    const values = {};
    values.brutAnnuel = Number(e.target.value);
    values.brutMensuel = values.brutAnnuel / 12;
    values.netAnnuel =
      values.brutAnnuel / (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
    values.netMensuel = values.netAnnuel / 12;
    values.cotPatronAnnuelles = getValueFromFloor(
      salariesFloors,
      values.brutAnnuel,
      'brutAnnuel',
      'ratio_cot_soc_patronales',
    );
    values.cotPatronMensuelles = Math.round(values.cotPatronAnnuelles / 12);
    values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
    values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

    setValuesFromArray(values);
  }

  function getFromCotPatronMensuelles(e) {
    const values = {};
    values.cotPatronMensuelles = Number(e.target.value);
    values.cotPatronAnnuelles = values.cotPatronMensuelles * 12;
    values.brutAnnuel =
      values.cotPatronAnnuelles /
      getPercent(salariesFloors, values.cotPatronAnnuelles, 'cotPatronAnnuelles', 'ratio_cot_soc_patronales');
    values.brutMensuel = Math.floor(values.brutAnnuel / 12);
    values.netAnnuel =
      values.brutAnnuel / (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
    values.netMensuel = values.netAnnuel / 12;
    values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
    values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

    setValuesFromArray(values);
  }

  function getFromCotPatronAnnuelles(e) {
    const values = {};

    values.cotPatronAnnuelles = Number(e.target.value);
    values.cotPatronMensuelles = Math.floor(values.cotPatronAnnuelles / 12);
    values.brutAnnuel =
      values.cotPatronAnnuelles /
      getPercent(salariesFloors, values.cotPatronAnnuelles, 'cotPatronAnnuelles', 'ratio_cot_soc_patronales');
    values.brutMensuel = Math.floor(values.brutAnnuel / 12);
    values.netAnnuel =
      values.brutAnnuel / (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
    values.netMensuel = values.netAnnuel / 12;
    values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
    values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

    setValuesFromArray(values);
  }

  function getFromCoutEntrepriseMensuel(e) {
    const values = {};
    values.coutEntrepriseMensuel = Number(e.target.value);
    values.coutEntrepriseAnnuel = values.coutEntrepriseMensuel * 12;
    values.brutAnnuel =
      values.coutEntrepriseAnnuel /
      (1 + getPercent(salariesFloors, values.coutEntrepriseAnnuel, 'brutAnnuel', 'ratio_cot_soc_patronales'));
    values.brutMensuel = Math.round(values.brutAnnuel / 12);
    values.netAnnuel =
      values.brutAnnuel / (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
    values.netMensuel = values.netAnnuel / 12;
    values.cotPatronMensuelles = values.coutEntrepriseMensuel - values.brutMensuel;
    values.cotPatronAnnuelles = values.cotPatronMensuelles * 12;

    setValuesFromArray(values);
  }

  function getFromCoutEntrepriseAnnuel(e) {
    const values = {};
    values.coutEntrepriseAnnuel = Number(e.target.value);
    values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);
    values.brutAnnuel =
      values.coutEntrepriseAnnuel /
      (1 + getPercent(salariesFloors, values.coutEntrepriseAnnuel, 'brutAnnuel', 'ratio_cot_soc_patronales'));
    values.brutMensuel = Math.round(values.brutAnnuel / 12);
    values.netAnnuel =
      values.brutAnnuel / (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
    values.netMensuel = values.netAnnuel / 12;
    values.cotPatronMensuelles = values.coutEntrepriseMensuel - values.brutMensuel;
    values.cotPatronAnnuelles = values.cotPatronMensuelles * 12;

    setValuesFromArray(values);
  }

  function getFromRemunerationGerance(e) {
    const result = Math.floor(getValueFromFloor(tnsFloors, toInt(e.target.value), 'remunerationNette', 'ratio'));

    setValue('cotSociales', result);

    updateRemuneration({
      remunerationNette: toInt(e.target.value),
      cotSociales: result,
    });
  }

  function getFromCotSocialesTns(e) {
    const result = Math.floor(getValueFromFloor(tnsFloors, toInt(e.target.value), 'cotSociales', 'ratio', false));

    setValue('remunerationNette', result);

    updateRemuneration({
      remunerationNette: result,
      cotSociales: toInt(e.target.value),
    });
  }

  function setValuesFromArray(values) {
    for (const [key, value] of Object.entries(values)) {
      setValue(key, Math.round(value));
    }
    updateRemuneration(values);
  }

  return (
    <fieldset
      ref={ref}
      className={`borderless-fieldset calculateur-container 
          ${parcours ? 'parcours-calculateur' : 'full-page-form'} 
          ${visible ? '' : ' hidden-fieldset '} 
          ${className}`}>
      <div className='s-between'>
        {parcours && (
          <>
            <h2>Calculateur de cotisations</h2>
            <Cross onClick={() => setVisible(false)} />
          </>
        )}
      </div>
      <i className='calculateur-cotisations-top-text'>
        * Saisissez un montant dans l’une de ces cases, les autres se rempliront automatiquement
      </i>
      <div className='centered'>
        <Toggle
          name='typeExploitant'
          label='exploitant tns'
          bgColor='var(--sky-blue)'
          label1='salarié de sa société'
          checked={typeExploitant}
          onChange={e => (e.target.checked ? setTypeExploitant(1) : setTypeExploitant(0))}
        />
      </div>
      <div className={`tns-container ${typeExploitant === 1 ? 'd-none' : ''}`} ref={tnsRef}>
        <h2>Rémunération de gérance</h2>
        <NumberInput
          useForm={Form}
          name='remunerationNette'
          label='rémunération annuelle nette *'
          icon='euro'
          onChange={e => getFromRemunerationGerance(e)}
        />
        <h2>Cotisations sociales</h2>
        <NumberInput
          useForm={Form}
          name='cotSociales'
          label='annuelles *'
          icon='euro'
          onChange={e => getFromCotSocialesTns(e)}
        />
      </div>
      <div className={`salarie-container ${typeExploitant === 1 ? '' : 'd-none'}`} ref={salarieRef}>
        <h2 className={typeExploitant === 1 ? '' : 'd-none'}>Salaire</h2>
        <p className={`form-subtitle${typeExploitant === 1 ? '' : ' d-none'}`}>
          Indiquez les salaires individuellement (ne pas additionner plusieurs salaires ici)
        </p>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            name='netMensuel'
            label='net mensuel avant impôts *'
            icon='euro'
            onChange={getFromNetMensuel}
          />
          <NumberInput
            useForm={Form}
            name='netAnnuel'
            label='net annuel avant impôts *'
            icon='euro'
            onChange={getFromNetAnnuel}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            name='brutMensuel'
            label='brut mensuel *'
            icon='euro'
            onChange={getFromBrutMensuel}
          />
          <NumberInput
            useForm={Form}
            name='brutAnnuel'
            label='brut annuel *'
            bgColor={parcours ? 'var(--gold' : undefined}
            icon='euro'
            onChange={getFromBrutAnnuel}
          />
        </div>
        <h2>Cotisations sociales patronales</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            name='cotPatronMensuelles'
            label='mensuelles *'
            icon='euro'
            onChange={getFromCotPatronMensuelles}
          />
          <NumberInput
            useForm={Form}
            name='cotPatronAnnuelles'
            label='annuelles *'
            icon='euro'
            bgColor={parcours ? 'var(--gold' : undefined}
            onChange={getFromCotPatronAnnuelles}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            name='coutEntrepriseMensuel'
            label='coût total entreprise mensuel *'
            icon='euro'
            onChange={getFromCoutEntrepriseMensuel}
          />
          <NumberInput
            useForm={Form}
            name='coutEntrepriseAnnuel'
            label='coût total entreprise annuel *'
            icon='euro'
            onChange={getFromCoutEntrepriseAnnuel}
          />
        </div>
      </div>
      {parcours && (
        <>
          <div className='row-1000 gap-md f-center my-md'>
            <FilledButton onClick={addRemuneration} hoverColor='var(--white)' bgColor='var(--sky-blue)'>
              Ajouter une rémunération
            </FilledButton>
            <FilledButton onClick={reportParcoursValues} hoverColor='var(--white)' bgColor='var(--sky-blue)'>
              Reporter salaire & charges sur le parcours
            </FilledButton>
          </div>

          <div className='remuneration-buttons-container'>
            {remunerations.length > 1 &&
              remunerations.map((remuneration, key) => (
                <div
                  key={key}
                  className={`remuneration-button ${visibleRemuneration === key ? 'active' : ''}`}
                  onClick={() => {
                    showRemuneration(key);
                  }}>
                  <div>
                    Rémuneration {key + 1}
                    <div className='remuneration-tooltip'>
                      Modifier la <br />
                      rémunération {key + 1}
                    </div>
                  </div>
                  <Trash color='var(--white)' onClick={e => deleteRemuneration(e, key)} />
                </div>
              ))}
          </div>
        </>
      )}
      <div className='form-legend'>*Obligatoire</div>
    </fieldset>
  );
}

export default CalculateurCotisationsForm;
