import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../../../molecules/formComponents/input/Input.js';
import { max2500Validation, numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import './DefinitionAffaire.css';
import { ParcoursContext } from '../../../../../context/ParcoursContext.js';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { formatDate, getFormattedDate } from '../../../../../utils/Utils.js';
import Info from '../../../../atoms/icons/general/info/Info.js';

function DefinitionAffaire({ id, index }) {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const {
    showTooltip,
    moveToNextStep,
    step,
    activites,
    setStep,
    getStepToGo,
    setGoToStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const [activitesDropdownVisible, setActivitesDropdownVisible] = useState(false);
  const [displayedActivites, setDisplayedActivites] = useState(activites);
  const watchActivite = watch('activite');

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'infos',
      ['enseigne', 'ville', 'code_postal', 'rue', 'raison_sociale', 'siret'],
      setValue,
      estimationValues,
    );
    if (estimationValues?.infos?.date_bilan) setValue('date_bilan', formatDate(estimationValues?.infos?.date_bilan));
    else setValue('date_bilan', formatDate('31/12/2023'));
    setValue('activite', estimationValues?.infos?.activite?.description);
    setValue('note_step_3', estimationValues?.notes?.note_step_3);
  }, [estimationValues]);

  useEffect(() => {
    if (!watchActivite) return setDisplayedActivites(activites);

    setDisplayedActivites(
      activites.filter(element => element.description.toLowerCase().includes(watchActivite.toLowerCase())),
    );
  }, [activites, watchActivite]);

  async function formSubmit(values) {
    try {
      let activiteId;
      if (activites.length) {
        activiteId = activites.find(element => element.description === values.activite)?.id;
        if (!activiteId) {
          setError('activite', { message: 'Veuillez choisir une activité dans la liste' }, { shouldFocus: true });
          document.getElementById('activite').focus();
          return;
        }
      }

      let payload = {
        infos: values,
        notes: {
          note_step_3: values.note_step_3,
        },
      };

      payload.infos.activite = { id: activiteId };
      payload.infos.date_bilan = getFormattedDate(payload.infos.date_bilan);

      delete payload.infos.note_step_3;

      delete payload.infos.imageUpload1;
      delete payload.infos.imageUpload2;
      delete payload.infos.imageUpload3;

      postValues(payload);

      if (getStepToGo() != 0) {
        setStep(getStepToGo());
        setGoToStep(0);
      } else moveToNextStep(index);
    } catch (e) {
      console.log(e);
    }
  }

  function setChosenActivite(activite) {
    setValue('activite', activite.description);

    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  function closeDropwdown() {
    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>définissons l’affaire à évaluer</h2>
        <Input
          setValue={setValue}
          placeholder='Cliquez et déroulez, ou cherchez par mot-clé...'
          label='activité (vous ne pourrez pas la changer par la suite)'
          name='activite'
          register={register}
          disabled={estimationValues?.infos?.activite?.description ? true : false}
          error={errors?.activite && errors.activite.message}
          icon='search'
          onFocus={() => setActivitesDropdownVisible(true)}
          onBlur={closeDropwdown}
          preventAutoComplete
          validation={{}}
          bgColor='var(--gold)'>
          <ul className={'activites-list' + (activitesDropdownVisible ? '' : ' h-none')}>
            {displayedActivites.map((activite, key) => {
              return (
                <li key={key} onClick={() => setChosenActivite(activite)}>
                  {activite.description}
                </li>
              );
            })}
            {displayedActivites.length === 0 && <li className='activites-no-result'>Aucun résultat</li>}
          </ul>
        </Input>
        <Input
          setValue={setValue}
          label='enseigne / nom de l’affaire à évaluer'
          register={register}
          error={errors?.enseigne && errors.enseigne.message}
          name='enseigne'
        />
        <Input
          setValue={setValue}
          label='n°/nom de rue'
          register={register}
          error={errors?.rue && errors.rue.message}
          name='rue'
        />
        <div className='row-1000 mb-sm'>
          <Input
            setValue={setValue}
            label='ville'
            register={register}
            error={errors?.ville && errors.ville.message}
            name='ville'
          />
          <Input
            setValue={setValue}
            label='code postal'
            register={register}
            error={errors?.code_postal && errors.code_postal.message}
            name='code_postal'
          />
        </div>
        <div className='row-1000 mb-sm'>
          <Input
            setValue={setValue}
            label='raison sociale'
            register={register}
            error={errors?.raison_sociale && errors.raison_sociale.message}
            name='raison_sociale'
          />
          <Input
            setValue={setValue}
            label='siret'
            validation={numberValidation}
            register={register}
            error={errors?.siret && errors.siret.message}
            name='siret'
          />
        </div>
        <Input
          setValue={setValue}
          name='date_bilan'
          register={register}
          type='date'
          label={
            <>
              date du dernier bilan
              <Info fill='var(--gold)' onClick={() => showTooltip('Tutoetape3FDCtitreschoixanneedernierbilan.webp')} />
            </>
          }
          bgColor='var(--pale-blue)'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
        />
        <Textarea
          error={errors?.note_step_3 && errors.note_step_3.message}
          validation={max2500Validation}
          register={register}
          name='note_step_3'
          label='notes et remarques'
          className='mt-md'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        <Input
          setValue={setValue}
          type='file'
          name='imageUpload1'
          register={register}
          label='image 1'
          validation={''}
        />
        <Input
          setValue={setValue}
          type='file'
          name='imageUpload2'
          register={register}
          label='image 2'
          validation={''}
        />
        <Input
          setValue={setValue}
          type='file'
          name='imageUpload3'
          register={register}
          label='image 3'
          validation={''}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default DefinitionAffaire;
