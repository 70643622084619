import Bracket from '../../atoms/icons/general/bracket/Bracket';
import './Paginator.css';
import React from 'react';

function Paginator({ className = '', setActivePage = () => {}, activePage = 0, totalPages = 0 }) {
  return totalPages ? (
    <div className={`paginator-container ${className}`}>
      <div
        className={`paginator-button ${activePage <= 0 ? 'disabled' : ''}`}
        onClick={() => setActivePage(activePage - 1)}>
        <Bracket color='var(--blue)' rotation='270deg' width='15px' /> Précédent
      </div>
      <div className='paginator-indexes'>
        {activePage > 1 && <div className='paginator-dots'>...</div>}
        {activePage > 0 && (
          <div className='paginator-index' onClick={() => setActivePage(activePage - 1)}>
            {activePage - 1}
          </div>
        )}
        <div className='paginator-index paginator-index-active'>{activePage}</div>
        {activePage < totalPages - 1 && (
          <div className='paginator-index' onClick={() => setActivePage(activePage + 1)}>
            {activePage + 1}
          </div>
        )}
        {totalPages - 1 - activePage > 1 && <div className='paginator-dots'>...</div>}
      </div>
      <div
        className={`paginator-button ${activePage === totalPages - 1 ? 'disabled' : ''}`}
        onClick={() => setActivePage(activePage + 1)}>
        Suivant <Bracket color='var(--blue)' rotation='90deg' width='15px' />
      </div>
    </div>
  ) : null;
}

export default Paginator;
