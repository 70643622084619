import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FiltersGroup from '../../../components/organisms/filtersGroup/FiltersGroup';
import ThumbnailsGroup from '../../../components/organisms/thumbnailsGroup/ThumbnailsGroup';
import './OutilsPratiques.css';
import React, { useContext, useEffect, useState } from 'react';
import ThumbnailsRow from '../../../components/organisms/thumbnailsRow/ThumbnailsRow';
import TextHr from '../../../components/molecules/textHr/TextHr';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import StickySearchbar from '../../../components/molecules/stickySearchbar/StickySearchbar';
import { fetchAllOutilsPratiquesApi } from '../../../api/OutilsApi';
import AppContext from '../../../context/AppContext';
import { getPrettyText } from '../../../utils/Utils';
import { useNavigate } from 'react-router-dom';

function OutilsPratiques() {
  const [tags, setTags] = useState([]);
  const [outils, setOutils] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [displayedOutils, setDisplayedOutils] = useState([]);
  const [tagsFilters, setTagsFilters] = useState([]);
  const [activeTagId, setActiveTagId] = useState(-1);
  const [thumbnailWidth, setThumbnailWidth] = useState('0px');
  const [resetSearchValueTrigger, setSearchResetValueTrigger] = useState(false);

  const { width, _ } = useWindowSize();
  const [windowScroll] = useWindowScroll();
  const { setAppLoaderVisible, createNotification } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchAllData();

    window.addEventListener('load', () => {
      setTimeout(() => {
        const thumbnail = document.getElementsByClassName('thumbnail-container')[0];
        if (thumbnail) setThumbnailWidth(`${thumbnail.offsetWidth}px`);
      }, 300);
    });
  }, []);

  useEffect(() => {
    setTags(getTagsFromArticles());
  }, [outils, width]);

  useEffect(() => {
    setTagsFilters(getTagsFilters());
  }, [tags, width]);

  useEffect(() => {
    const thumbnail = document.getElementsByClassName('thumbnail-container')[0];
    if (thumbnail) setThumbnailWidth(`${thumbnail.offsetWidth}px`);
  }, [width]);

  async function fetchAllData() {
    try {
      await fetchOutils();
      await fetchSuggestions();
      setAppLoaderVisible(false);
    } catch (error) {
      navigate('/404');
    }
  }

  async function fetchOutils() {
    try {
      const { data } = await fetchAllOutilsPratiquesApi();

      data.forEach(outil => {
        outil.picture = `${process.env.REACT_APP_API_URL}/assets/OutilsPratiques/${outil.article_url}/miniature/Image_de_tete_Vente_fonds_commerce.svg`;
        outil.picture = `${process.env.REACT_APP_API_URL}/assets/OutilsPratiques/${outil.article_url}/miniature/${outil.img_ex}`;
        outil.title = getPrettyText(outil.name);
        outil.linkText = "Accéder à l'outil";
        if (!outil.tag_name) outil.tag_name = '';
        outil.tag_name = getPrettyText(outil.tag_name);

        delete outil.name;
      });
      setOutils(data);
      setDisplayedOutils(data);
    } catch (error) {
      throw error;
    }
  }

  async function fetchSuggestions() {
    try {
      throw new Error('Not implemented');
      setSuggestions([
        {
          title: '1Les 15 points de vérification du bail commercial',
          alt: 'super cool',
          tag: 'Gestion d’affaire',
          picture: '/conseilsPros/2/thumbnail/Image_de_tete_Vente_fonds_commerce.svg',
          link: '/outils-pratiques/todo',
          linkText: "Lire l'article",
        },
        {
          title: '2Canevas d’informations à envoyer au rédacteur du compromis',
          alt: 'super cool',
          tag: 'Gestion d’affaire',
          picture: '/conseilsPros/2/thumbnail/Image_de_tete_Vente_fonds_commerce.svg',
          link: '/outils-pratiques/todo',
          linkText: "Lire l'article",
        },
        {
          title: '3Canevas d’informations à envoyer au rédacteur du compromis',
          alt: 'super cool',
          tag: 'Gestion d’affaire',
          picture: '/conseilsPros/3/thumbnail/Image_de_tete_Valorisation_murs_commerciaux.jpeg',
          link: '/outils-pratiques/todo',
          linkText: "Lire l'article",
        },
        {
          title: '4Canevas d’informations à envoyer au rédacteur du compromis',
          alt: 'super cool',
          tag: 'Gestion d’affaire',
          picture: '/conseilsPros/4/thumbnail/Image_de_tete_Imposition_plus-value_fonds_commerce.jpeg',
          link: '/outils-pratiques/todo',
          linkText: "Lire l'article",
        },
        {
          title: '5Check-list à suivre pour visiter un local commercial',
          alt: 'super cool',
          tag: 'Gestion d’affaire',
          picture: '/conseilsPros/5/thumbnail/Image_de_tete_Evaluation_entreprises_titres_societes.jpeg',
          link: '/outils-pratiques/todo',
          linkText: "Lire l'article",
        },
      ]);
    } catch (error) {
      createNotification(
        'Une erreur est survenue lors de la récupération des suggestions',
        'var(--red)',
        'var(--dark-blue)',
      );
    }
  }

  function getTagsFromArticles() {
    const data = [];

    outils.forEach(article => {
      if (!data.includes(article.tag_name) && article.tag_name) data.push(article.tag_name);
    });

    data.map((tag, index) => {
      data[index] = {
        name: tag,
        id: index,
      };
    });
    if (width > 576) {
      data.unshift({
        name: 'Tous',
        id: -1,
      });
    }

    return data;
  }

  function getTagsFilters() {
    const data = [];

    tags.forEach(tag => {
      data.push({
        name: tag.name,
        onClick: filterArticles,
        params: [tag.id],
      });
    });

    return data;
  }

  function filterArticles(tagId) {
    if (tagId !== activeTagId) setSearchResetValueTrigger(value => !value);
    setActiveTagId(tagId);

    if (width > 576) {
      if (tagId === -1) {
        setDisplayedOutils(outils);
        return;
      }
      const tag = tags.filter(tag => tag.id == tagId)[0].name;

      setDisplayedOutils(outils.filter(article => article.tag_name == tag));
    } else if (tagId !== -1) {
      window.scrollTo(
        0,
        document.getElementById(tags.filter(tag => tag.id == tagId)[0].id).getBoundingClientRect().top +
          window.scrollY -
          100,
      );
    }
  }

  function handleSearchbarChange(value) {
    if (value.length < 1) filterArticles(activeTagId);
    else {
      setDisplayedOutils(
        outils.filter(
          outil =>
            (activeTagId === -1 || outil.tag_name === tags.filter(tag => tag.id == activeTagId)[0].name) &&
            (outil.title.toLowerCase().includes(value.toLowerCase()) ||
              outil.tag_name.toLowerCase().includes(value.toLowerCase())),
        ),
      );
    }

    if (windowScroll.y > 500) window.scrollTo(0, 0);
  }

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            {
              name: 'Accueil',
              to: '/',
            },
            {
              name: 'Outils: Outils pratiques',
              to: '/outils-pratiques',
            },
          ]}
        />
        <Banner
          title='Outils pratiques'
          subtitle='Téléchargez nos outils exclusifs pour vous aider à optimiser, céder ou acquérir une affaire'
        />
      </section>
      <div>
        <FiltersGroup data={tagsFilters} />
        <StickySearchbar resetValueTrigger={resetSearchValueTrigger} onChange={handleSearchbarChange} />
        {width > 576 ? (
          <>
            <section className='container'>
              <ThumbnailsGroup data={displayedOutils} />
              {!displayedOutils.length && (
                <p className='text-xl text-center outfit-semibold'>Aucun résultat ne correspond à votre recherche</p>
              )}
              <FilledButton className='mid-page-button'>Démarrer le parcours d'estimation</FilledButton>
            </section>
            <ThumbnailsRow
              margin='0 auto'
              thumbnailWidth={thumbnailWidth}
              data={suggestions}
              title={<h2 className='mb-lg px-xl'>Découvrez également nos conseils de pros !</h2>}
              id='suggestions'
            />
            <FilledButton className={`mid-page-button ${width <= 1200 ? 'mt-md' : 'mt-xl'}`}>
              Voir tous nos conseils de pros
            </FilledButton>
          </>
        ) : (
          <>
            {tags.map((tag, key) => {
              if (tag.id !== -1) {
                return (
                  <ThumbnailsRow
                    key={key}
                    title={<TextHr id={key}>{tag.name}</TextHr>}
                    data={outils.filter(article => article.tag_name === tag.name)}
                  />
                );
              }
            })}
            <FilledButton className='mid-page-button'>Démarrer le parcours d'estimation</FilledButton>
          </>
        )}
      </div>
    </div>
  );
}

export default OutilsPratiques;
