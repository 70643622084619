import React, { useEffect, useContext } from 'react';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import { numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { removeSpaces } from '../../../../../utils/Utils.js';

function LoyerCharges({ index }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const Form = { register, setValue, watch };

  const {
    step,
    moveToNextStep,
    setStep,
    submitFormsMc,
    postValues,
    getStepToGo,
    setGoToStep,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'surface',
      ['surface_plain_pied', 'surface_sous_sol', 'surface_etage'],
      setValue,
      estimationValues,
    );
    setValue('note_step_7', estimationValues?.notes?.note_step_7);
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      surface: {
        surface_plain_pied: removeSpaces(values.surface_plain_pied),
        surface_sous_sol: removeSpaces(values.surface_sous_sol),
        surface_etage: removeSpaces(values.surface_etage),
      },
      notes: {
        note_step_7: values.note_step_7,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }
  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
        <fieldset className='borderless-fieldset'>
          <h2>Surface</h2>
          <div className='row-1000 mb-sm'>
            <NumberInput
              useForm={Form}
              label='surface de plain pied'
              name='surface_plain_pied'
              error={errors?.surface_plain_pied && errors.surface_plain_pied.message}
              validation={numberValidation}
            />
            <NumberInput
              useForm={Form}
              label='surface étage(s)'
              name='surface_etage'
              error={errors?.surface_etage && errors.surface_etage.message}
              validation={numberValidation}
            />
          </div>
          <NumberInput
            useForm={Form}
            label='surface sous-sol'
            name='surface_sous_sol'
            error={errors?.surface_sous_sol && errors.surface_sous_sol.message}
            validation={numberValidation}
          />
          <Textarea
            register={register}
            name='note_step_7'
            className='mt-md'
            label='notes et remarques'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        </fieldset>
      </form>
    </>
  );
}

export default LoyerCharges;
