import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from '../../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../../components/molecules/banner/Banner';
import { useNavigate, useParams } from 'react-router-dom';
import FilledButton from '../../../../components/molecules/buttons/filledButton/FilledButton';
import AppContext from '../../../../context/AppContext';
import {
  addSecondaryYearApi,
  deleteSecondaryYearApi,
  fetchEstimationWithSecondaryYearsApi,
} from '../../../../api/EstimationApi';
import Input from '../../../../components/molecules/formComponents/input/Input';
import Card from '../../../../components/molecules/cards/card/Card';
import './Synthese.css';
import UnfilledButton from '../../../../components/molecules/buttons/unfilledButton/UnfilledButton';
import { DateToString, formatDate } from '../../../../utils/Utils';

function Synthese() {
  const { estimationIdParam } = useParams();
  const [estimationData, setEstimationData] = useState([]);
  const [isMursCommerciaux, setIsMursCommerciaux] = useState(true);
  const [addYearInputValue, setAddYearInputValue] = useState('');
  const [formVisible, setFormVisible] = useState(false);
  const { createNotification, setModalVisible, setModalContent } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!/^\d+$/.test(estimationIdParam)) navigate('/404');

    fetchEstimationWithSecondaryYears();
  }, [estimationIdParam]);

  async function fetchEstimationWithSecondaryYears() {
    try {
      const data = (await fetchEstimationWithSecondaryYearsApi(estimationIdParam)).data;

      let estimationYears = [];
      if (data.base_estimation) {
        data.base_estimation[0].isMainYear = true;
        estimationYears = [...data.base_estimation, ...data.related_estimations];
        estimationYears.sort((a, b) => getBilanYear(a) - getBilanYear(b));

        setIsMursCommerciaux(false);
      } else {
        estimationYears = [data.estimation];
      }

      setEstimationData(estimationYears);
    } catch (error) {
      console.log(error);
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de votre estimation.{' '}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        'var(--red)',
        'var(--dark-blue)',
      );
    }
  }

  function getBilanYear(bilan) {
    return Number(bilan.infos.date_bilan.slice(-4));
  }

  function showAddYearForm(futureBilan) {
    const bilan = futureBilan ? estimationData[estimationData.length - 1] : estimationData[0];
    const splitedDate = bilan.infos.date_bilan.split(' ')[0].split('/').map(Number);
    const date = futureBilan
      ? new Date(splitedDate[2] + 1, splitedDate[1] - 1, splitedDate[0])
      : new Date(splitedDate[2] - 1, splitedDate[1] - 1, splitedDate[0]);

    setAddYearInputValue(formatDate(DateToString(date)));
    setFormVisible(true);
  }

  async function deleteSecondaryYear(id) {
    try {
      await deleteSecondaryYearApi(id);

      await fetchEstimationWithSecondaryYears();
      createNotification(<>Le bilan a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression du bilan. Veuillez réessayer plus tard</>,
        'var(--red)',
        'var(--dark-blue)',
      );
      console.log(error);
    }
    setModalVisible(false);
  }

  async function addEstimationYear() {
    try {
      const id = (await addSecondaryYearApi(estimationIdParam, addYearInputValue)).data.id;

      setModalVisible(false);
      navigate(`/detail-parcours/secondaire/${id}`);
    } catch (error) {
      console.log(error);
      createNotification(
        <>Une erreur est survenue lors de la création du bilan. Veuillez réessayer plus tard</>,
        'var(--red)',
        'var(--dark-blue)',
      );
    }
    setFormVisible(false);
  }

  function showDeleteSecondaryYearModal(bilan) {
    setModalContent({
      title: 'Supprimer le bilan',
      content: (
        <>
          <p>Voulez-vous supprimer le bilan {getBilanYear(bilan)} ? </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' onClick={() => deleteSecondaryYear(bilan.id)}>
              Valider
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  return (
    <section className='page-container container'>
      <Breadcrumbs
        routes={[
          { name: 'Accueil', to: '/' },
          { name: 'Estimer : Synthèse', to: '/synthese' },
        ]}
      />
      <Banner title='Synthèse' subtitle={estimationData[0]?.infos?.enseigne} />
      <div className='row-1000 gap-md relative centered'>
        <Card padding='15px' className={`synthese-add-year-form-container ${formVisible ? '' : 'hidden-card'}`}>
          <form>
            <Input
              value={addYearInputValue}
              onChange={e => setAddYearInputValue(e.target.value)}
              type='date'
              label='Quelle est la date du bilan que vous voulez ajouter (revoir le wording)'
            />
            <div className='modal-buttons-row'>
              <UnfilledButton padding='10px 25px' onClick={() => setFormVisible(false)}>
                Annuler
              </UnfilledButton>
              <FilledButton padding='10px 25px' onClick={addEstimationYear}>
                Ajouter le bilan
              </FilledButton>
            </div>
          </form>
        </Card>
        {isMursCommerciaux ? (
          <FilledButton to={`/detail-parcours/murs-commerciaux/${estimationData[0]?.id}`}>
            Modifier mon estimation
          </FilledButton>
        ) : (
          <>
            {estimationData.length < 4 && (
              <FilledButton onClick={() => showAddYearForm(false)}>Ajouter une année</FilledButton>
            )}
            {estimationData.map((bilan, key) => {
              if (bilan.isMainYear)
                return (
                  <div key={key}>
                    <FilledButton
                      to={`/detail-parcours/${bilan.type.id === 1 ? 'fonds-de-commerce' : 'titres-de-societe'}/${
                        bilan?.id
                      }`}>
                      Modifier l'estimation principale
                    </FilledButton>
                  </div>
                );
              return (
                <div key={key}>
                  <FilledButton to={`/detail-parcours/secondaire/${bilan?.id}`}>
                    Modifier le bilan ({getBilanYear(bilan)})
                  </FilledButton>
                  <FilledButton onClick={() => showDeleteSecondaryYearModal(bilan)} className='mt-sm w-100'>
                    Supprimer le bilan
                  </FilledButton>
                </div>
              );
            })}
            {estimationData.length < 4 && (
              <FilledButton onClick={() => showAddYearForm(true)}>Ajouter une année</FilledButton>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default Synthese;
