import React from 'react';
import './ParcoursCarousel.css';

function ParcoursCarousel({ children, step, addBottomMargin = false }) {
  return (
    <section id='carousel' className={`parcours-carousel-container ${addBottomMargin ? ' with-margin' : null}`}>
      <div className='parcours-carousel' style={{ '--step': step - 1 }}>
        {children}
      </div>
    </section>
  );
}

export default ParcoursCarousel;
