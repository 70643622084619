import { useWindowSize } from '@uidotdev/usehooks';
import Check from '../../atoms/icons/general/check/Check';
import './StepsRow.css';
import React, { useEffect, useState } from 'react';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import Bracket from '../../atoms/icons/general/bracket/Bracket';

// stepsGroup = [
//     {
//       name: "name",
//       steps: [1, 2, 3],
//     },
//     {
//       name: 'name 2',
//       steps: [4, 5, 6, 7],
//     },
//   ]
function StepsRow({ stepsGroup, actualStep, onStepClick = () => {}, isStepChecked = () => {} }) {
  const [slidePosition, setSlidePosition] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!stepsGroup.length) return;

    stepsGroup.forEach(subGroup => {
      if (subGroup.steps.includes(actualStep)) return setSlidePosition(stepsGroup.indexOf(subGroup));
    });
  }, [actualStep]);

  function isAtFirstPosition() {
    if (width > 992 || slidePosition <= 0) return true;
  }

  function isAtLastPosition() {
    if (width > 992 || slidePosition >= stepsGroup.length - 1) return true;
  }

  function toNextGroup(upper) {
    if (upper) {
      if (slidePosition < stepsGroup.length - 1) setSlidePosition(slidePosition + 1);
    } else if (slidePosition > 0) setSlidePosition(slidePosition - 1);
  }

  return (
    <div className='steps-group-container'>
      <div className='steps-group-slider' style={{ '--slide-position': slidePosition }}>
        {stepsGroup.length &&
          stepsGroup.map((stepsSubgroup, key) => (
            <div className='steps-subgroup-container' key={key}>
              <div className='steps-subgroup-title'>{stepsSubgroup.name}</div>
              <div className='steps-subgroup'>
                {stepsSubgroup.steps.length &&
                  stepsSubgroup.steps.map((step, key) => (
                    <React.Fragment key={key}>
                      <div
                        className={`step ${step == step ? 'current' : ''}`}
                        key={key}
                        onClickCapture={() => onStepClick(step)}>
                        {step}
                        <div className={`step-check ${isStepChecked(step) ? 'done' : null}`}>
                          <Check width='15px' color='var(--white)' />
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ))}
      </div>
      <FilledButton
        className={`steps-row-bracket ${isAtFirstPosition() ? 'd-none' : ''}`}
        onClick={() => toNextGroup()}
        padding='12px 10px'>
        <Bracket rotation='270deg' marginLeft='0' />
      </FilledButton>
      <FilledButton
        className={`steps-row-bracket ${isAtLastPosition() ? 'd-none' : ''}`}
        onClick={() => toNextGroup(true)}
        padding='12px 10px'>
        <Bracket rotation='90deg' marginLeft='0' />
      </FilledButton>
    </div>
  );
}

export default StepsRow;
