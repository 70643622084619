import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Info from '../../../../atoms/icons/general/info/Info';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import Select from '../../../../molecules/formComponents/select/Select';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import { max2500Validation } from '../../../../../utils/formValidation/FormValidation';
import { fetchDropdownValuesApi } from '../../../../../api/ParcoursApi';
import { getSelectValue } from '../../../../../utils/Utils';
import ZoneInfluence from '../../../../atoms/parcours/zoneInfluence/ZoneInfluence';
import { useWindowSize } from '@uidotdev/usehooks';

function Emplacement({ id, index }) {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    showTooltip,
    dependanceLocalCommercial,
    setDependanceLocalCommercial,
    moveToNextStep,
    step,
    getStepToGo,
    setGoToStep,
    setStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillSelectsFromEstimationValues,
  } = useContext(ParcoursContext);
  const [dropdownsValues, setDropdownsValues] = useState({});
  const { width } = useWindowSize();

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    if (
      estimationValues?.step >= 8 &&
      !estimationValues?.emplacement?.qualite_secteur?.id &&
      !estimationValues?.emplacement?.qualite_implantation?.id
    ) {
      setValue('dependance_local_commercial', true);
      setDependanceLocalCommercial(true);
    } else {
      fillSelectsFromEstimationValues(
        'emplacement',
        ['qualite_secteur', 'qualite_implantation_rue'],
        setValue,
        estimationValues,
      );
    }
    setValue('type_ville', estimationValues?.emplacement?.type_ville?.id + '');
    setValue('note_step_8', estimationValues?.notes?.note_step_8);
  }, [estimationValues, dropdownsValues]);

  async function formSubmit(values) {
    const payload = {
      emplacement: {
        type_ville: {
          id: values.type_ville,
        },
        qualite_secteur: { id: values.dependance_local_commercial ? null : getSelectValue(values.qualite_secteur) },
        qualite_implantation_rue: {
          id: values.dependance_local_commercial ? null : getSelectValue(values.qualite_implantation_rue),
        },
      },
      notes: {
        note_step_8: values.note_step_8,
      },
    };

    postValues(payload);
    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    const values = {};
    try {
      values.qualiteSecteur = (await fetchDropdownValuesApi('qualite_secteur')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.qualiteImplantationRue = (await fetchDropdownValuesApi('qualite_implantation_rue')).data;
    } catch (error) {
      console.error(error);
    }

    setDropdownsValues(values);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>Emplacement</h2>

        <Radio
          padding='2rem'
          label={
            <>
              zone d’influence de l’affaire
              <Info onClick={() => showTooltip('1Rayonnement_agglomeration.webp')} />{' '}
            </>
          }
          register={register}
          name='type_ville'
          className='zone-influence-radio'
          error={errors?.type_ville && errors.type_ville.message}
          content={[
            {
              label: (
                <>
                  {width > 1200 && <br />}
                  Rayonnement local
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />}
                  Très petite ville et village
                  <br /> (-5.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xs' className='parcours-zone-influence' />
                </>
              ),
              value: 1,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  multicommunal
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Petite ville {width > 1200 && <br />}
                  (5.000 à 20.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='sm' className='parcours-zone-influence' />
                </>
              ),
              value: 2,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  départemental
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Ville moyenne {width > 1200 && <br />}
                  (20.000 à 100.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='lg' className='parcours-zone-influence' />
                </>
              ),
              value: 3,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />} régional&nbsp;ou&nbsp;+
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Grande ville {width > 1200 && <br />}(+100.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xl' className='parcours-zone-influence' />
                </>
              ),
              value: 4,
            },
          ]}
        />
        <Checkbox
          label="l'activité ne dépend pas d’un local commercial"
          name='dependance_local_commercial'
          register={register}
          onChange={e => setDependanceLocalCommercial(e.target.checked)}
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && e.target.checked) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        <Select
          visible={!dependanceLocalCommercial}
          label={
            <>
              qualité de secteur et de clientèle dans l’agglomération
              <Info onClick={() => showTooltip('2_Qualite_de_secteur.webp')} />
            </>
          }
          name='qualite_secteur'
          register={register}
          error={errors?.qualite_secteur && errors.qualite_secteur.message}>
          {dropdownsValues?.qualiteSecteur?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <Select
          visible={!dependanceLocalCommercial}
          label={
            <>
              qualité d’implantation dans la rue
              <Info onClick={() => showTooltip('2bis_Implantation_rue.webp')} />
            </>
          }
          name='qualite_implantation_rue'
          register={register}
          error={errors?.qualite_implantation_rue && errors.qualite_implantation_rue.message}>
          {dropdownsValues?.qualiteImplantationRue?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <Textarea
          register={register}
          name='note_step_8'
          label='notes et remarques'
          className='mt-md'
          error={errors?.note_step_8 && errors.note_step_8.message}
          validation={max2500Validation}
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default Emplacement;
