import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/organisms/header/Header';
import Footer from '../../../components/organisms/footer/Footer';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import TextHr from '../../../components/molecules/textHr/TextHr';

function Cgu() {
  return (
    <section className='container page-container'>
      <Breadcrumbs
        routes={[
          { name: 'Accueil', route: '/' },
          { name: "Liens légaux: Conditions générales d'utilisation", route: '/cgu' },
        ]}
      />
      <Banner title="Conditions générales d'utilisation" subtitle='Applicables au 05 juillet 2022' />
      <div className='big-text-container mt-xl'>
        <p className='outfit-bold'>
          L'accès au site Internet{' '}
          <Link className='link' to='/'>
            www.estimermoncommerce.fr
          </Link>
          , sa consultation et son utilisation sont subordonnés à l'acceptation sans réserve des présentes conditions
          générales d'utilisation (ci-après « les CGU ») par tout internaute.
        </p>

        <p className='outfit-bold'>
          En naviguant sur le site{' '}
          <Link className='link' to='/'>
            www.estimermoncommerce.fr
          </Link>
          , vous reconnaissez avoir pris connaissance et avoir accepté pleinement et sans réserve les présentes CGU.
        </p>
        <p className='outfit-bold'>GALAXICO attire votre attention sur l'importance de les lire attentivement.</p>
        <TextHr className='my-xl'>Article 1. Présentation du Site</TextHr>
        <p>
          Le site Internet accessible à l’adresse{' '}
          <Link className='link' to='/'>
            www.estimermoncommerce.fr
          </Link>{' '}
          (ci-après « le Site ») est édité et exploité par la société GALAXICO, SAS au capital social de 10 000 €,
          immatriculée au RCS de PARIS sous le numéro 878 387 885, ayant son siège social situé 41 rue de Cronstadt
          75015 PARIS (FRANCE), et dont les coordonnées sont les suivantes :
        </p>
        <ul>
          <li>
            Téléphone : 06 63 84 52 56, du lundi au vendredi, hors jours fériés et congés annuels, de 10h à 12h et de
            14h à 17h ;
          </li>
          <li>
            Email :{' '}
            <Link className='link' to='mailto:contact@estimermoncommerce.fr'>
              contact@estimermoncommerce.fr
            </Link>
            .
          </li>
        </ul>
        <p>
          GALAXICO met à votre disposition sur le Site un service d’estimation de fonds de commerce, de titres de
          société ou de murs commerciaux, ainsi que des informations, des conseils, des actualités et des outils
          pratiques relatifs à l’estimation, l’optimisation et la transmission de commerce et d’entreprise.
        </p>
        <p>
          Le Site vous permet également d’être mis en relation avec GALAXICO pour qu’elle vous recontacte, de vous
          inscrire à sa newsletter et de créer et accéder à votre compte client (ci-après le « Compte »).
        </p>
        <TextHr className='my-xl'>Article 2. Objet</TextHr>
        <p>
          Les CGU ont pour objet de déterminer les conditions d’utilisation du Site par tout utilisateur, personne
          physique, quel qu’il soit, âgé d’au moins 18 ans (« vous »).
        </p>
        <p>
          Elles visent notamment à définir les conditions dans lesquelles vous pouvez accéder et naviguer sur le Site et
          utiliser les différentes fonctionnalités qui y sont proposées.
        </p>
        <TextHr className='my-xl'>Article 3. Acceptation des CGU</TextHr>
        <p>
          En accédant ou en utilisant le Site, vous reconnaissez avoir pris connaissance de l'ensemble des CGU et les
          accepter expressément sans réserve ni modification de quelque nature que ce soit.
        </p>
        <p>
          Si vous n’approuvez pas l’une ou l’autre des stipulations des CGU ou si vous n'avez pas la capacité juridique
          de les accepter, vous n’êtes pas autorisé à accéder au Site et à l’utiliser.
        </p>
        <p>
          Vous êtes également invité à consulter la{' '}
          <Link className='link' to='politique-confidentialite'>
            Politique de confidentialité
          </Link>{' '}
          du Site.
        </p>
        <TextHr className='my-xl'>Article 4. Accès au site</TextHr>
        <p>
          Pour accéder au Site, vous devez disposer d’un accès à Internet, dont le coût est à votre charge, et
          reconnaissez que :
        </p>
        <ul>
          <li>
            Il vous appartient de prendre toute mesure que vous jugerez appropriée pour assurer la sécurité de votre
            terminal de navigation ;
          </li>
          <li>
            Tout terminal de navigation connecté au Site est et reste sous votre entière responsabilité, la
            responsabilité de GALAXICO ne pouvant pas être recherchée pour tout dommage direct ou indirect qui pourrait
            survenir du fait de votre connexion au Site.
          </li>
        </ul>
        <p>L’accès au Site est gratuit, hors coût des télécommunications et d’accès au réseau Internet.</p>
        <p>
          La souscription aux offres de service proposées par GALAXICO sur le Site est soumise aux{' '}
          <Link className='link' to='CGV'>
            Conditions Générales de Vente
          </Link>{' '}
          du Site.
        </p>
        <TextHr className='my-xl'>Article 5. Fonctionnalités du Site</TextHr>
        <p>
          Le Site est ouvert à tout internaute remplissant les conditions prévues à l’article « Objet » ci-dessus, sous
          réserve de disposer d’une connexion au réseau Internet.
        </p>
        <p>Le Site vous permet notamment d’avoir accès aux fonctionnalités suivantes :</p>
        <h3 className='h2-uppercase my-md'>1. Formulaire de contact</h3>
        <p>
          Vous pouvez remplir un formulaire de contact, en renseignant votre nom, votre prénom, votre adresse e-mail,
          votre numéro de téléphone l’objet du message et un message afin d’être contacté par GALAXICO.
        </p>
        <h3 className='h2-uppercase my-md'>2. Inscription à la newsletter</h3>
        <p>
          Vous pouvez vous abonner à la newsletter de GALAXICO en renseignant votre adresse e-mail, afin de recevoir des
          informations concernant l’actualité, les activités et les offres commerciales de GALAXICO.
        </p>
        <p>
          Vous pourrez à tout moment vous désinscrire de cette newsletter en cliquant sur le lien inséré à cet effet
          dans les e-mails de newsletter qui vous sont adressés par GALAXICO.
        </p>
        <h3 className='h2-uppercase my-md'>3. Création d’un Compte </h3>
        <p>Vous pouvez créer un Compte dans les conditions prévues à l’article « Création d’un compte » ci-dessous.</p>
        <h3 className='h2-uppercase my-md'>4. Souscription aux offres de service</h3>
        <p>
          Vous pouvez consulter les offres de service proposés à la vente sur le Site par GALAXICO et les commander
          après avoir créé un Compte et vous y être identifié.
        </p>
        <h3 className='h2-uppercase my-md'>5. Consultation de documentation</h3>
        <p>
          Vous pouvez consulter la documentation fournie par GALAXICO sur le Site, à savoir des conseils, et des outils
          pratiques relatifs à l’estimation de commerce et d’entreprise, ainsi qu’à l’univers de la transmission.
        </p>
        <h3 className='h2-uppercase my-md'>6. Demande de démonstration de l’outil</h3>
        <p>
          Vous pouvez demander une démonstration en ligne (visioconférence) en cliquant sur la touche « demander une
          démo ».
        </p>
        <TextHr className='my-xl'>Article 6. Compte</TextHr>
        <h3 className='h2-uppercase my-md'>1. Règles générales</h3>
        <p>
          Votre Compte vous est strictement personnel : en conséquence, vous vous interdisez de céder ou transférer, de
          quelque manière que ce soit et à quelque titre que ce soit, le droit d’accès à votre Compte à un tiers.
        </p>
        <h3 className='h2-uppercase my-md'>2. Création du Compte </h3>
        <p>
          Vous pouvez procéder à la création d’un Compte depuis la rubrique « Connexion », puis en cliquant sur «
          Inscription ».
        </p>
        <p>La création d’un Compte est obligatoire pour pouvoir souscrire à une offre de service payante.</p>
        <p>Pour créer votre Compte, vous devez renseigner les informations suivantes :</p>
        <ul className='dots-list'>
          <li>Votre nom ;</li>
          <li>Votre prénom ;</li>
          <li>La raison sociale de votre structure ;</li>
          <li>Votre adresse postale ;</li>
          <li>Votre adresse e-mail ;</li>
          <li>Votre téléphone ;</li>
          <li>Votre nature d'utilisateur ;</li>
          <li>Un mot de passe de votre choix.</li>
        </ul>
        <p>
          En procédant à la création de votre Compte, vous certifiez que vous êtes une personne physique âgée de plus de
          18 ans, bénéficiant de la capacité juridique nécessaire.
        </p>
        <p>De plus, vous vous engagez à fournir des informations exactes, complètes et à jour.</p>
        <p>Vous pouvez, lors de la création de votre compte, vous inscrire à la newsletter.</p>
        <h3 className='h2-uppercase my-md'>3. Mot de passe</h3>
        <p>
          Le mot de passe de votre Compte étant strictement personnel et confidentiel, il vous appartient de le
          conserver précieusement, sans le communiquer à quiconque.
        </p>
        <p>
          Dès lors que vous êtes seul responsable de la conservation et de l’utilisation de votre mot de passe, vous
          répondez seul de toutes les conséquences d’une utilisation par vous ou par un tiers, frauduleuse ou non, de
          votre Compte et vous engagez à signaler dans les meilleurs délais à GALAXICO toute utilisation frauduleuse de
          votre Compte dont vous auriez connaissance.
        </p>
        <h3 className='h2-uppercase my-md'>4. Fonctionnement</h3>
        <p>
          Lors de chaque connexion à votre Compte, vous reconnaissez que la saisie successive de votre adresse e-mail et
          de votre mot de passe vaut identification de votre part.
        </p>
        <p>
          Votre Compte vous permet, depuis la rubrique <u>Mon compte</u>, d’accéder aux sous-rubriques suivantes :
        </p>
        <h4 className='outfit-bold'>• Mes estimations :</h4>
        <ul className='dots-list'>
          <li>
            Vous permet de consulter les rapports synthétiques et détaillés réalisés par GALAXICO dans le cadre de votre
            utilisation du Site ;
          </li>
        </ul>
        <h4 className='outfit-bold'>• Mon Profil :</h4>
        <ul className='dots-list'>
          <li>
            Vous permet de consulter, ajouter, mettre à jour et supprimer vos coordonnées (prénom, nom, raison sociale,
            adresse e-mail, adresse postale, téléphone, mot de passe) ;
          </li>
          <li> Vous permet de vous inscrire ou vous désinscrire de la newsletter de GALAXICO ;</li>
        </ul>
        <h4 className='outfit-bold'>• Mon Offre :</h4>
        <ul className='dots-list'>
          <li>
            Vous permet de consulter les conditions de l’offre de service en cours de validité souscrite auprès de
            GALAXICO.
          </li>
          <li> Vous permet de souscrire à des Offres additionnelles</li>
          <li>
            Vous permet de modifier votre logo / visuel en tête de vos rapports (fonctionnalité accessible uniquement
            avec les Offres « LIBERTY », « MAESTRO » et « OPTIMUM »)
          </li>
          <li>
            Vous permet d’enlever et remettre les mentions EstimerMonCommerce.fr en pieds de pages des rapports
            d'estimations
          </li>
          <li> Vous permet de gérer le renouvellement de votre Offre</li>
        </ul>
        <h4 className='outfit-bold'>• Mes factures :</h4>
        <ul className='dots-list'>
          <li>Vous permet de consulter les factures de vos Offres payées dans le cadre de votre utilisation du Site</li>
        </ul>
        <p>
          Pour permettre le bon fonctionnement de votre Compte, vous vous engagez à tenir en permanence à jour les
          informations personnelles qu’il renferme.
        </p>
        <h3 className='h2-uppercase my-md'>5. Désactivation et Suppression</h3>
        <p>
          Vous pouvez à tout moment solliciter la désactivation ou la suppression de votre Compte sans avoir à donner de
          justification particulière, en formulant auprès de GALAXICO une demande écrite en ce sens par courrier postal
          ou e-mail, aux coordonnées indiquées en préambule.
        </p>
        <p>
          La désactivation ou la suppression de votre Compte sera effectuée par GALAXICO dans les meilleurs délais
          suivant la réception de votre demande écrite en ce sens.
        </p>
        <p>
          En cas de désactivation ou de suppression de votre Compte, toutes les sommes dues à GALAXICO par vous
          resteront dues en intégralité. Tout Offre d’abonnement en cours restera en cours jusqu’à son terme
          d’engagement, et ne sera pas renouvelée en cas de désactivation ou de suppression de votre Compte.
        </p>
        <p>
          Vous êtes informé(e) que GALAXICO peut être amenée à désactiver ou supprimer un compte qui n’aurait pas de
          commande en cours en application de la règlementation relative à la protection des données personnelles.
        </p>
        <TextHr className='my-xl'>Article 7. Votre responsabilité</TextHr>
        <p>En accédant au Site, vous vous engagez à en faire un usage paisible et vous interdisez notamment de :</p>
        <ul>
          <li>
            Collecter de quelque façon que ce soit des informations sur les autres visiteurs du Site, sans leur accord
            exprès, préalable et écrit ainsi que celui de GALAXICO ;
          </li>
          <li>
            Perturber ou tenter de perturber le fonctionnement du Site, diffuser des virus ou toutes autres technologies
            susceptibles de nuire à GALAXICO et aux autres visiteurs du Site, créer une saturation, inonder le serveur,
            tenter de sonder, de scruter ou de tester la vulnérabilité d'un système ou d'un réseau ou encore enfreindre
            les mesures de sécurité ou d'authentification sans en avoir reçu l'accord exprès, préalable et écrit de
            GALAXICO ;
          </li>
          <li>
            Procéder de manière automatisée à l’extraction, la reproduction, l’utilisation ou la suppression de données
            issues du Site.
          </li>
        </ul>
        <TextHr className='my-xl'>Article 8. Responsabilité de GALAXICO</TextHr>
        <h3 className='h2-uppercase my-md'>1. Responsabilité relative au Site</h3>
        <p>
          GALAXICO s’engage à mettre en œuvre les moyens nécessaires afin d'assurer au mieux l'accès, la sécurité et le
          bon fonctionnement du Site.
        </p>
        <p>
          Ceci étant, en naviguant sur le Site, vous reconnaissez connaître et accepter les caractéristiques, risques et
          limites du réseau Internet, qui peuvent empêcher de manière temporaire l’accès au Site, en particulier ses
          performances techniques, les temps de réponse pour consulter, interroger ou transférer des données et les
          risques liés à la sécurité des communications.
        </p>
        <p>
          En dépit des soins et efforts qu'elle apporte à son Site, GALAXICO ne garantit pas que celui-ci sera exempt
          d’anomalies ou d’erreurs, que celles-ci pourront être corrigées, que le Site fonctionnera sans interruption ou
          panne, que des dysfonctionnements imputables à des logiciels de tiers surviennent, ni encore que le Site soit
          compatible avec la nature ou la configuration de votre terminal de navigation.
        </p>
        <p>
          Vous reconnaissez être seul responsable de l'utilisation que vous faites du Site et que GALAXICO décline toute
          responsabilité en cas :
        </p>
        <ul>
          <li>
            De dommages inhérents à l'utilisation du réseau Internet, notamment en cas de suspension ou d’interruption
            de l'accès au Site en raison d’un sinistre, rupture de service, coupure d’électricité, intrusion extérieure,
            présence de virus informatiques ou de fichiers corrompus, maintenance ou mise à jour du Site, violation de
            données du Site ou accès non autorisé ;
          </li>
          <li>
            De dommages de tous types, prévisibles ou imprévisibles (incluant la perte de données, de bénéfices, de
            chiffres d'affaires, d'activité, d’opportunité, d’économies, de profits, etc.) découlant de l’utilisation ou
            de l’impossibilité d’accéder totalement ou partiellement au Site ou de l'utilisation que vous pourriez en
            faire en violation des présentes CGU.
          </li>
        </ul>
        <p>
          Vous reconnaissez et acceptez que GALAXICO se réserve la faculté, à tout moment et sans notification
          préalable, de suspendre tout ou partie de l’accès au Site, et ce notamment afin de procéder à toute opération
          de correction, de mise à jour ou de maintenance.
        </p>
        <p>
          Enfin, en complément des stipulations énoncées ci-dessus relatives à l’utilisation proprement dite du Site,
          vous êtes invité à lire attentivement les Conditions Générales de Vente de GALAXICO qui peuvent venir
          compléter les stipulations énoncées ci-dessus.
        </p>
        <h3 className='h2-uppercase my-md'>2. Responsabilité relative à la documentation figurant sur le Site</h3>
        <p>
          Les informations fournies par GALAXICO dans la documentation figurant sur le Site sont fournies uniquement à
          titre informatif.
        </p>
        <p>
          Vous reconnaissez être seul responsable de l’utilisation de ces informations, de leur adéquation à vos
          besoins, de l’usage et/ou des interprétations que vous pourrez faire des documents et données consultés et des
          résultats que vous pourriez en obtenir ou en déduire.
        </p>
        <TextHr className='my-xl'>Article 9. Propriété intellectuelle</TextHr>
        <p>
          Tous les droits de propriété intellectuelle portant tant sur la structure que sur le contenu du Site, et
          notamment les éléments graphiques, textuels ou visuels, les images, sons, vidéos, photographies, logos,
          marques, signes distinctifs, outils, logiciels, documents et données, en ce compris les rapports d’estimation
          réalisés par GALAXICO sont la propriété exclusive de GALAXICO ou, le cas échéant, de tiers et ont, dans cette
          hypothèse, fait l’objet d’une autorisation expresse et préalable de reproduction et de représentation de la
          part du titulaire des droits.
        </p>
        <p>
          Ces éléments sont mis à votre disposition pour la seule utilisation du Site et dans le cadre d'une utilisation
          normale de ses fonctionnalités.
        </p>
        <p>
          Lors de l’accès et de l'utilisation du Site, vous vous engagez à respecter les droits de propriété
          intellectuelle de GALAXICO et de ses partenaires, sous peine d’engager votre responsabilité personnelle et de
          vous exposer à des poursuites civiles et/ou pénales.
        </p>
        <p>
          Il vous est interdit de reproduire, représenter, publier, transmettre, modifier, utiliser, adapter, traduire,
          diffuser, céder, exploiter ou extraire, à des fins commerciales ou non, tout ou partie des éléments du Site,
          et ce de quelque manière que ce soit, sans l'accord exprès, préalable et écrit de GALAXICO ; par dérogation,
          vous êtes autorisé à télécharger une copie de certaines parties du Site sur un ordinateur personnel et/ou à
          procéder à une impression, exclusivement pour votre utilisation personnelle et non commerciale et sous réserve
          de n'apporter aucune modification aux éléments copiés.
        </p>
        <TextHr className='my-xl'>Article 10. Liens hypertextes</TextHr>
        <h3 className='h2-uppercase my-md'>1. Liens hypertextes depuis le Site</h3>
        <p>Le Site peut contenir des liens hypertextes redirigeant vers des sites Internet exploités par des tiers.</p>
        <p>
          Ces liens étant fournis à simple titre d'information, GALAXICO n'exerce aucun contrôle sur les sites Internet
          vers lesquels ils renvoient et décline toute responsabilité quant à l'accès, le contenu ou l'utilisation de
          ces sites, ainsi qu'aux dommages pouvant résulter de la consultation des informations qui y figurent, la
          décision d'activer ces liens hypertextes relevant de votre responsabilité exclusive.
        </p>
        <h3 className='h2-uppercase my-md'>2. Liens hypertextes vers le Site</h3>
        <p>
          Aucun lien hypertexte ne peut être créé vers le Site sans l'accord exprès, préalable et écrit de GALAXICO.
        </p>
        <p>
          Si vous entendez créer un lien hypertexte redirigeant vers le Site, et ce quel qu’en soit le support, vous
          devez préalablement prendre contact avec GALAXICO afin d'obtenir son accord exprès, préalable et écrit en lui
          adressant un e-mail à l'adresse indiquée en préambule des CGU.
        </p>
        <TextHr className='my-xl'>Article 11. Données personnelles</TextHr>
        <p>
          Dans le cadre de l’utilisation du Site, vous êtes susceptible de fournir des données personnelles vous
          concernant ou concernant des tiers faisant l’objet d’un traitement automatisé par GALAXICO, dont les
          conditions sont exposées dans la Politique de Confidentialité.
        </p>
        <p>
          Pour en savoir plus sur les conditions dans lesquelles sont traitées vos données personnelles, GALAXICO vous
          invite à consulter sa{' '}
          <Link className='link' to='politique-confidentialite'>
            Politique de Confidentialité
          </Link>
          .
        </p>
        <TextHr className='my-xl'>Article 12. Modification du Site</TextHr>
        <p>
          GALAXICO se réserve le droit, à tout moment et sans préavis, de modifier les règles de fonctionnement du Site,
          de modifier ou supprimer tout ou partie de son contenu ainsi que d'en interrompre, temporairement ou
          définitivement, l'accessibilité, notamment afin de procéder à toute opération de correction, de maintenance ou
          de mise à jour.
        </p>
        <TextHr className='my-xl'>Article 13. Violation des CGU</TextHr>
        <p>
          En cas de constatation par GALAXICO de la violation de votre part de l'une quelconque des stipulations des
          CGU, des dispositions légales en vigueur ou des droits des tiers, et après notification écrite de vous mettre
          en conformité demeurée infructueuse pendant plus de huit jours, sauf cause grave justifiant l'absence de
          préavis ou l'application d'un préavis plus court, GALAXICO se réserve le droit de restreindre, suspendre ou
          interdire, de manière temporaire ou définitive, votre accès au Site et à votre Compte.
        </p>
        <TextHr className='my-xl'>Article 14. Loi applicable – Compétence juridictionnelle</TextHr>
        <p>Les CGU et tous les litiges survenant entre vous et GALAXICO sont soumis au droit français. </p>
        <p>
          Vous êtes informé qu'en cas de contestation, vous pouvez recourir à une médiation de la consommation dans les
          conditions prévues dans les{' '}
          <Link className='link' to='CGV'>
            Conditions Générales de Vente
          </Link>{' '}
          du Site.
        </p>
        <p>
          En cas de litige auquel aurait pu donner lieu l’application des CGU et qui ne serait pas amiablement résolu
          avec GALAXICO, les tribunaux français sont exclusivement compétents, nonobstant pluralité de défendeurs, appel
          en garantie, en référé comme au fond, en application des règles éditées par le Code de procédure civile
          français.
        </p>
        <p>
          Tout litige intervenant entre vous, dans la mesure où vous auriez agi ans le cadre de vos activités
          professionnelles, et GALAXICO auxquels les CGU pourraient donner lieu seront de la compétence exclusive du
          Tribunal de commerce de PARIS (FRANCE), tant en référé qu'au fond, et ce nonobstant pluralité de parties ou
          appel en garantie.
        </p>
        <TextHr className='my-xl'>Article 15. Dispositions diverses</TextHr>
        <h3 className='h2-uppercase my-md'>1. Droit de cession</h3>
        <p>
          GALAXICO se réserve le droit de céder à des tiers, en tout ou partie, les droits et obligations issus des CGU,
          à condition de les céder dans les mêmes termes ou, à tout le moins, dans des termes qui ne soient pas moins
          avantageux pour vous.
        </p>
        <h3 className='h2-uppercase my-md'>2. Modification</h3>
        <p>
          GALAXICO se réserve le droit de modifier à tout moment la teneur des CGU et vous notifiera les modifications
          ainsi effectuées par e-mail ou par tout autre moyen adéquat avant leur entrée en vigueur.
        </p>
        <p>
          L’activation de votre Compte postérieure à la notification de ces modifications sera considérée comme valant
          reconnaissance et acceptation de votre part des nouvelles CGU.
        </p>
        <h3 className='h2-uppercase my-md'>3. Divisibilité</h3>
        <p>
          Si l'une quelconque des stipulations des CGU était déclarée nulle ou inapplicable en application d'une loi,
          d'un règlement ou à la suite d'une décision de justice devenu définitive, elle serait réputée non écrite et
          les autres stipulations resteraient en vigueur.
        </p>
        <h3 className='h2-uppercase my-md'>4. Non-renonciation</h3>
        <p>
          Le fait que GALAXICO ne se prévale pas, à un moment ou à un autre, d’une des stipulations des CGU ne pourra
          être interprété comme valant renonciation à s’en prévaloir.
        </p>
      </div>
    </section>
  );
}

export default Cgu;
