import React, { useContext, useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import Info from '../../../../atoms/icons/general/info/Info';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import {
  numberValidation,
  decimalValidation,
  max2500Validation,
} from '../../../../../utils/formValidation/FormValidation';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import Input from '../../../../molecules/formComponents/input/Input';
import Trash from '../../../../atoms/icons/general/trash/Trash';
import './RatioClefs.css';
import FilledButton from '../../../../molecules/buttons/filledButton/FilledButton';
import Add from '../../../../atoms/icons/general/add/Add';
import { useWindowSize } from '@uidotdev/usehooks';
import { formatDate, getFormattedDate, getSelectValue, removeSpaces } from '../../../../../utils/Utils';
import Card from '../../../../molecules/cards/card/Card';
import { deleteRhApi } from '../../../../../api/EstimationApi';

function RatioClefs({ id, index }) {
  const {
    register,
    watch,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [deletedRh, setDeletedRh] = useState([]);
  const Form = { register, watch, setValue };
  const {
    showTooltip,
    typesCommerce,
    moveToNextStep,
    step,
    getStepToGo,
    setGoToStep,
    setStep,
    submitFormsFdc,
    postValues,
    fillSelectsFromEstimationValues,
    fillInputsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const franchise = watch('franchise');
  const infosComplementaires = watch('infos_complementaires_12');
  const [nbRh, setNbRh] = useState([0]);
  const detail_rh = watch('detail_rh');
  const { width } = useWindowSize();

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillSelectsFromEstimationValues(
      'ratios',
      ['anciennete_exploitation_affaire', 'client_dependance'],
      setValue,
      estimationValues,
    );
    fillInputsFromEstimationValues(
      'ratios',
      ['jours_ouverture', 'potentiel_ca_3_ans', 'pourcentage_liquides_bar', 'salaries'],
      setValue,
      estimationValues,
    );
    fillInputsFromEstimationValues(
      'hotellerie',
      [
        'couverts_jour',
        'hectolitres_biere_an',
        'kg_cafe_semaine',
        'prix_moyen_chambre_ht',
        'taux_occupation_moyen',
        'ticket_moyen_client_ht',
      ],

      setValue,
      estimationValues?.ratios,
    );

    setValue('note_step_12', estimationValues?.notes?.note_step_12);

    if (estimationValues?.step >= 12)
      if (estimationValues?.ratios?.franchise?.montant) {
        setValue('franchise', '2');
        setValue('montant', estimationValues?.ratios?.franchise.montant);
      } else setValue('franchise', '1');

    if (estimationValues?.ratios?.infos_complementaires?.date_debut_exploitation) {
      setValue('infos_complementaires_12', true);
      fillInputsFromEstimationValues(
        'infos_complementaires',
        ['amplitude_horaire', 'fermeture_hebdomadaire', 'conges_annuels', 'motif'],
        setValue,
        estimationValues?.ratios,
      );
      setValue(
        'date_debut_exploitation',
        formatDate(estimationValues?.ratios?.infos_complementaires?.date_debut_exploitation),
      );
    }

    if (estimationValues?.ratios?.detail_rh.length) {
      setValue('detail_rh', true);
      setDeletedRh([]);
      setNbRh(
        estimationValues?.ratios?.detail_rh.map((rh, key) => {
          setValue('nom' + key, rh.nom);
          setValue('poste' + key, rh.poste);
          setValue('salaire_brut_annuel' + key, rh.salaire_brut_annuel);
          setValue('annee_entree' + key, rh.annee_entree);
          setValue('type_contrat' + key, rh.type_contrat);
          return key;
        }),
      );
    }
  }, [estimationValues]);

  useEffect(() => {
    if (franchise === 1) register('franchiseMontant');
    else unregister('franchiseMontant');
  }, [franchise]);

  useEffect(() => {
    setValue('deleted_rh', deletedRh.join(','));
  }, [deletedRh]);

  useEffect(() => {
    setValue('rh_number', nbRh.length);
  }, [nbRh]);

  async function formSubmit(values) {
    postValues({
      ratios: {
        anciennete_exploitation_affaire: {
          id: getSelectValue(values.anciennete_exploitation_affaire),
        },
        client_dependance: { id: getSelectValue(values.client_dependance) },
        detail_rh: await getRessourcesHumaines(values),
        franchise: {
          montant: values.franchise == 2 ? removeSpaces(values.montant, 1) : null,
        },
        hotellerie: {
          couverts_jour: removeSpaces(values.couverts_jour, 1),
          hectolitres_biere_an: removeSpaces(values.hectolitres_biere_an, 1),
          kg_cafe_semaine: removeSpaces(values.kg_cafe_semaine, 1),
          prix_moyen_chambre_ht: removeSpaces(values.prix_moyen_chambre_ht, 1),
          taux_occupation_moyen: removeSpaces(values.taux_occupation_moyen, 1),
          ticket_moyen_client_ht: removeSpaces(values.ticket_moyen_client_ht, 1),
        },
        infos_complementaires: {
          date_debut_exploitation: values.infos_complementaires_12
            ? getFormattedDate(values.date_debut_exploitation)
            : null,
          amplitude_horaire: values.infos_complementaires_12 ? values.amplitude_horaire : null,
          fermeture_hebdomadaire: values.infos_complementaires_12 ? values.fermeture_hebdomadaire : null,
          conges_annuels: values.infos_complementaires_12 ? removeSpaces(values.conges_annuels, 1) : null,
          motif: values.infos_complementaires_12 ? values.motif : null,
        },
        jours_ouverture: removeSpaces(values.jours_ouverture, 1),
        potentiel_ca_3_ans: removeSpaces(values.potentiel_ca_3_ans, 1),
        pourcentage_liquides_bar: removeSpaces(values.pourcentage_liquides_bar, 1),
        salaries: removeSpaces(values.salaries, 1),
      },
      notes: {
        note_step_12: values.note_step_12,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function getRessourcesHumaines(values) {
    const exisitingRh = estimationValues?.ratios?.detail_rh;

    if (!values.detail_rh) return exisitingRh?.forEach(rh => deleteRhApi(rh.id));

    let result = [];
    let existingIds = exisitingRh ? exisitingRh.map(rh => rh.id) : [];
    const deletedRh = values.deleted_rh.split(',');

    for (let i = 0; i < values.rh_number; i++) {
      if (deletedRh.includes(i + '')) continue;

      result.push({
        nom: values['nom' + i],
        poste: values['poste' + i],
        salaire_brut_annuel: removeSpaces(values['salaire_brut_annuel' + i], 1),
        annee_entree: values['annee_entree' + i],
        type_contrat: values['type_contrat' + i],
      });

      if (existingIds.length) result[result.length - 1].id = existingIds.shift();
    }

    for (let i = 0; i < existingIds.length; i++) {
      await deleteRhApi(existingIds[i]);
    }

    return result;
  }

  function addRhRow() {
    setNbRh([...nbRh, nbRh.length]);
  }

  function deleteRhRow(index) {
    setDeletedRh([...deletedRh, index]);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <input type='hidden' name='deleted_rh' {...register('deleted_rh')} />
        <input type='hidden' name='rh_number' {...register('rh_number')} />
        <h2>ratios clefs & informations complémentaires</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            label={
              <>
                nombre de salariés travaillant dans l’entreprise, hormis le dirigeant
                <Info onClick={() => showTooltip('11_Nb_salaries_hors_dirigeant.webp')} />
              </>
            }
            error={errors?.salaries && errors.salaries.message}
            name='salaries'
            validation={numberValidation}
          />
          <NumberInput
            useForm={Form}
            className={typesCommerce.includes(3) ? '' : 'w-0'}
            visible={typesCommerce.includes(3)}
            label={
              <>
                pourcentage de liquides (ou limonade) par rapport aux solides
                <Info onClick={() => showTooltip('12Pourcentageliquides.webp')} />
              </>
            }
            error={errors?.pourcentage_liquides_bar && errors.pourcentage_liquides_bar.message}
            name='pourcentage_liquides_bar'
            icon='percent'
            validation={decimalValidation}
          />
          <NumberInput
            useForm={Form}
            label={
              <>
                potentiel de développement du chiffre d’affaires à 3 ans
                <Info onClick={() => showTooltip('11bis_Potentiel_devlpt_CA.webp')} />
              </>
            }
            error={errors?.potentiel_ca_3_ans && errors.potentiel_ca_3_ans.message}
            name='potentiel_ca_3_ans'
            icon='percent'
            validation={decimalValidation}
          />
        </div>
        <Checkbox label='je souhaite détailler les ressources humaines' name='detail_rh' register={register} />
        <div className={detail_rh ? 'rh-inputs-list-container' : 'closed-rh-inputs-list-container'}>
          {nbRh.map((row, key) => {
            if (deletedRh.includes(key)) return;
            return (
              <Card
                key={key}
                bgColor='var(--pale-blue)'
                className={'rh-card ' + (detail_rh ? '' : 'invisible-rh-card')}>
                <div className='rh-input-container' key={key}>
                  <Input register={register} name={'nom' + key} visible={detail_rh} label='Nom' />
                  <Input register={register} name={'poste' + key} visible={detail_rh} label='poste' />
                  <NumberInput
                    type='number'
                    useForm={{ register, setValue, watch }}
                    name={'salaire_brut_annuel' + key}
                    icon='euro'
                    visible={detail_rh}
                    label='salaire brut annuel'
                  />
                  <Input register={register} name={'annee_entree' + key} visible={detail_rh} label="année d'entrée" />
                  <Input register={register} name={'type_contrat' + key} visible={detail_rh} label='type de contrat' />
                  {detail_rh && (
                    <div className='trash-container'>
                      <Trash onClick={() => deleteRhRow(key)} />
                    </div>
                  )}
                </div>
              </Card>
            );
          })}
        </div>
        {detail_rh && (
          <FilledButton onClick={addRhRow} className='ml-auto'>
            <Add width={width > 576 ? '30px' : '20px'} /> Ajouter une ligne
          </FilledButton>
        )}
        <Radio
          label="anciennete d’exploitation de l'affaire"
          register={register}
          error={errors?.anciennete_exploitation_affaire && errors.anciennete_exploitation_affaire.message}
          name='anciennete_exploitation_affaire'
          content={[
            {
              value: 1,
              label: '< 1 an',
            },
            {
              value: 2,
              label: '1 à 2 ans',
            },
            {
              value: 3,
              label: '2 à 3 ans',
            },
            {
              value: 4,
              label: '3 à 4 ans',
            },
            {
              value: 5,
              label: '4 à 7 ans',
            },
            {
              value: 6,
              label: '> 7 ans',
            },
          ]}
        />
        <div className='row-1000'>
          <NumberInput
            visible={typesCommerce.includes(8)} //Hôtels
            useForm={Form}
            icon='percent'
            label="Taux d'occupation moyen"
            error={errors?.taux_occupation_moyen && errors.taux_occupation_moyen.message}
            name='taux_occupation_moyen'
            validation={decimalValidation}
          />
          <NumberInput
            visible={typesCommerce.includes(8)} //Hôtels
            useForm={Form}
            icon='euro'
            label='Prix moyen chambre HT'
            error={errors?.prix_moyen_chambre_ht && errors.prix_moyen_chambre_ht.message}
            name='prix_moyen_chambre_ht'
            validation={decimalValidation}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            visible={typesCommerce.includes(9)} //CHR sauf hôtels
            useForm={Form}
            icon='euro'
            label='Ticket moyen par client (ht)'
            error={errors?.ticket_moyen_client_ht && errors.ticket_moyen_client_ht.message}
            name='ticket_moyen_client_ht'
            validation={decimalValidation}
          />
          <NumberInput
            visible={typesCommerce.includes(9)} //CHR sauf hôtels
            useForm={Form}
            label='Nombre moyen de couverts / jour'
            error={errors?.couverts_jour && errors.couverts_jour.message}
            name='couverts_jour'
            validation={numberValidation}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            visible={typesCommerce.includes(9)} //CHR sauf hôtels
            useForm={Form}
            label='nombre de kg de café / semaine'
            error={errors?.kg_cafe_semaine && errors.kg_cafe_semaine.message}
            name='kg_cafe_semaine'
            validation={decimalValidation}
          />
          <NumberInput
            visible={typesCommerce.includes(9)} //CHR sauf hôtels
            useForm={Form}
            label="nombre moyen d'hectolitres de bière / an"
            error={errors?.hectolitres_biere_an && errors.hectolitres_biere_an.message}
            name='hectolitres_biere_an'
            validation={decimalValidation}
          />
        </div>
        <div className='row-1000'>
          <Input
            useForm={Form}
            label={
              <>
                nombre de jours d’ouverture dans l’année
                <Info onClick={() => showTooltip('13_Nb_jours_fermeture_annuels.webp')} />
              </>
            }
            error={errors?.jours_ouverture && errors.jours_ouverture.message}
            name='jours_ouverture'
            validation={numberValidation}
          />
          <Radio
            label={
              <>
                50% ou plus du ca sont ils réalisés par 1 à 3 clients ?
                <Info onClick={() => showTooltip('13bis_CA_50_1_a_3_clients.webp')} />
              </>
            }
            register={register}
            error={errors?.client_dependance && errors.client_dependance.message}
            name='client_dependance'
            content={[
              {
                value: 1,
                label: 'Non',
              },
              {
                value: 2,
                label: 'Oui',
              },
            ]}
          />
        </div>
        <div className='row-1000'>
          <Radio
            label={<>l'exploitant est-il franchisé / adhérent / concessionnaire / licencié d’une enseigne&nbsp;?</>}
            register={register}
            error={errors?.franchise && errors.franchise.message}
            name='franchise'
            content={[
              {
                value: 1,
                label: 'Non',
              },
              {
                value: 2,
                label: 'Oui',
              },
            ]}
          />
          <div className='w-100' />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            visible={franchise == 2}
            label="montant de la franchise (droit d'entrée)"
            error={errors?.montant && errors.montant.message}
            name='montant'
            validation={numberValidation}
            icon='euro'
          />
          <div className='w-100' />
        </div>
        <Checkbox
          label="je souhaite renseigner des informations complémentaires sur le fonctionnement de l'affaire"
          name='infos_complementaires_12'
          register={register}
        />
        <div className='row-1000'>
          <Input
            visible={infosComplementaires}
            label="Le vendeur exploite l'affaire depuis le"
            type='date'
            name='date_debut_exploitation'
            error={errors?.date_debut_exploitation && errors.date_debut_exploitation.message}
            register={register}
          />
          <Input
            visible={infosComplementaires}
            label='Amplitude horaire'
            name='amplitude_horaire'
            error={errors?.amplitude_horaire && errors.amplitude_horaire.message}
            register={register}
          />
        </div>
        <div className='row-1000'>
          <Input
            visible={infosComplementaires}
            label='Fermeture hebdomadaire'
            name='fermeture_hebdomadaire'
            error={errors?.fermeture_hebdomadaire && errors.fermeture_hebdomadaire.message}
            register={register}
          />
          <Input
            visible={infosComplementaires}
            label='Congés annuels'
            name='conges_annuels'
            error={errors?.conges_annuels && errors.conges_annuels.message}
            register={register}
          />
        </div>
        <Textarea
          className='small-textarea'
          visible={infosComplementaires}
          register={register}
          name='motif'
          error={errors?.motif && errors.motif.message}
          label='motif de la vente'
        />
        <Textarea
          register={register}
          name='note_step_12'
          label='notes et remarques'
          className='mt-md'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          error={errors?.note_step_12 && errors.note_step_12.message}
          validation={max2500Validation}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default RatioClefs;
