import React, { useEffect, useContext, useState } from 'react';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import { numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import Select from '../../../../molecules/formComponents/select/Select';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { fetchDropdownValuesApi } from '../../../../../api/ParcoursApi.js';
import { getSelectValue, removeSpaces } from '../../../../../utils/Utils.js';

function CaracteristiquesLocal({ index }) {
  const {
    register,
    handleSubmit,

    setValue,
    formState: { errors },
    watch,
  } = useForm();
  const Form = { watch, setValue, register };
  const {
    step,
    moveToNextStep,
    setStep,
    submitFormsMc,
    postValues,
    setGoToStep,
    getStepToGo,
    fillSelectsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const [dropdownsValues, setDropdownsValues] = useState({});

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
    fillDropdownsValues();
  }, [step, estimationValues]);

  useEffect(() => {
    fillSelectsFromEstimationValues(
      'caracteristiques_local',
      ['local_angle', 'local_amenage', 'reserve', 'extraction'],
      setValue,
      estimationValues,
    );
    setValue('largeur_facade', estimationValues?.caracteristiques_local?.largeur_facade);
    setValue('etat_local', estimationValues?.caracteristiques_local?.etat_local.id);
    setValue('note_step_8', estimationValues?.notes?.note_step_8);
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      caracteristiques_local: {
        local_angle: { id: values.local_angle },
        local_amenage: { id: values.local_amenage },
        reserve: { id: values.reserve },
        extraction: { id: values.extraction },
        etat_local: { id: getSelectValue(values.etat_local) },
        largeur_facade: removeSpaces(values.largeur_facade),
      },
      notes: {
        note_step_8: values.note_step_8,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    const values = {};
    try {
      values.etat_local = (await fetchDropdownValuesApi('murs_etat_local')).data;
    } catch (error) {
      console.error(error);
    }
    setDropdownsValues(values);
  }

  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
        <fieldset className='borderless-fieldset'>
          <h2>caractéristiques du local</h2>
          <div className='row-1000 mb-sm'>
            <Radio
              label='local d’angle'
              direction='column'
              register={register}
              error={errors?.local_angle && errors.local_angle.message}
              name='local_angle'
              content={[
                {
                  label: 'Sans objet',
                  value: 1,
                },
                {
                  label: 'Non',
                  value: 2,
                },
                {
                  label: 'Oui',
                  value: 3,
                },
              ]}
            />
            <Radio
              label='le local est-il aménagé ?'
              direction='column'
              register={register}
              error={errors?.local_amenage && errors.local_amenage.message}
              name='local_amenage'
              content={[
                {
                  label: 'Non',
                  value: 1,
                },
                {
                  label: 'Oui',
                  value: 2,
                },
              ]}
            />
          </div>
          <div className='row-1000 mb-sm'>
            <Radio
              direction='column'
              label="le local dispose-t-il d'une réserve (espace de stockage) ?"
              register={register}
              error={errors?.reserve && errors.reserve.message}
              name='reserve'
              content={[
                {
                  label: 'Non',
                  value: 1,
                },
                {
                  label: 'Oui',
                  value: 2,
                },
              ]}
            />
            <Radio
              direction='column'
              label="le local dispose-t-il d'un conduit d'extraction des fumées ?"
              register={register}
              error={errors?.extraction && errors.extraction.message}
              name='extraction'
              content={[
                {
                  label: 'Non',
                  value: 1,
                },
                {
                  label: 'Oui',
                  value: 2,
                },
              ]}
            />
          </div>
          <div className='row-1000 mb-sm'>
            <Select
              label="quel est l'état général du local ?"
              error={errors?.etat_local && errors.etat_local.message}
              name='etat_local'
              register={register}>
              {dropdownsValues?.etat_local?.map(value => (
                <option key={value.id} value={value.id}>
                  {value.description}
                </option>
              ))}
              <option value={1}>Neuf</option>
              <option value={2}>Bon état</option>
              <option value={3}>A rafaîchir</option>
              <option value={4}>A rénover</option>
            </Select>
            <NumberInput
              useForm={Form}
              label='largeur de façade (en mètres linéaires) si local avec pignon sur rue'
              validation={numberValidation}
              error={errors?.largeur_facade && errors.largeur_facade.message}
              name='largeur_facade'
            />
          </div>
          <Textarea
            register={register}
            name='note_step_8'
            className='mt-md'
            label='notes et remarques'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        </fieldset>
      </form>
    </>
  );
}

export default CaracteristiquesLocal;
