import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import './GuidesAgentsImmosArticle.css';
import '../conseilsProsArticle/ConseilsProsArticle.css';
import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import ReactDOMServer from 'react-dom/server';
import HtmlToReact, { Parser } from 'html-to-react';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';
import { useWindowScroll } from '@uidotdev/usehooks';
import PageTitle from '../../../components/molecules/pageTitle/PageTitle';
import ArrowSm from '../../../components/atoms/icons/general/arrowSm/ArrowSm';
import AppContext from '../../../context/AppContext';
import { getPrettyText } from '../../../utils/Utils';
import { fetchGuideAgentsImmosByUrlApi } from '../../../api/OutilsApi';
import GuidesAgentsImmosForm from '../../../components/forms/compte/guidesAgentsImmoForm/GuidesAgentsImmosForm';

function GuidesAgentsImmosArticle() {
  const [article, setArticle] = useState({
    id: '',
    title: '',
    content: '',
    imagePath: '',
    imageAlt: '',
  });
  const [articleAnchors, setArticleAnchors] = useState([]);
  const [activeLink, setActiveLink] = useState(null);
  const [isScrollPrevent, setIsScrollPrevent] = useState(false);

  const [windowScroll] = useWindowScroll();
  const summaryRef = useRef(null);
  const articleRef = useRef(null);
  const { setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();
  const { articleUrl } = useParams();
  const { setModalContent, setModalVisible } = useContext(AppContext);
  const [modalTriggered, setModalTriggered] = useState(false);
  const [hiddenArticleContent, setHiddenArticleContent] = useState([]);

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchArticle();
  }, []);

  useEffect(() => {
    setAnchorsFromArticle();
    hideArticleContent();
  }, [article]);

  useEffect(() => {
    const tmp = document.querySelector('.article-aside li.active');
    if (tmp && activeLink !== tmp) setActiveLink(tmp);
  }, [windowScroll]);

  useEffect(() => {
    handleSummaryScroll();
  }, [activeLink]);

  useEffect(() => {
    const trigger_element = document.getElementById('disclaimer_container_article');
    if (!trigger_element || trigger_element.style.height === '0px') return;

    const observer = new IntersectionObserver(modalObserverListener, {
      threshold: 0.05,
    });

    observer.observe(trigger_element);

    return () => observer.disconnect();
  }, [article, setModalContent, setModalVisible, modalTriggered, hiddenArticleContent]);

  function renderArticleContent() {
    const content = DOMPurify.sanitize(article.content);

    return <div className='article-container' ref={articleRef} dangerouslySetInnerHTML={{ __html: content }} />;
  }

  function modalObserverListener(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting && !modalTriggered) {
        setModalContent({
          title: 'Accédez gratuitement à nos guides pour agents immos',
          content: <GuidesAgentsImmosForm showArticleContent={showArticleContent} />,
        });
        setModalVisible(true);
        observer.unobserve(entry.target);
      }
    });
  }

  function hideArticleContent() {
    if (!articleRef.current) return;

    const containerFullHeight = articleRef.current.getBoundingClientRect().height;
    const childrenToRemove = [];

    while (
      articleRef.current.getBoundingClientRect().height > containerFullHeight / 5 &&
      articleRef.current.children.length > 1
    ) {
      childrenToRemove.push(articleRef.current.lastChild);
      articleRef.current.removeChild(articleRef.current.lastChild);
    }

    setHiddenArticleContent(childrenToRemove);

    const containerNewHeight = articleRef.current.getBoundingClientRect().height;
    const whiteSpaceDiv = document.createElement('div');
    whiteSpaceDiv.setAttribute('id', 'disclaimer_container_article');
    whiteSpaceDiv.style.height = `${containerFullHeight - containerNewHeight}px`;
    articleRef.current.appendChild(whiteSpaceDiv);
  }

  function showArticleContent() {
    const whiteSpace = document.getElementById('disclaimer_container_article');
    if (whiteSpace) {
      articleRef.current.removeChild(whiteSpace);
    }
    const trigger_element = document.getElementById('trigger_element');
    if (trigger_element) {
      articleRef.current.removeChild(trigger_element);
    }
    hiddenArticleContent.forEach(child => {
      articleRef.current.appendChild(child);
    });
    setHiddenArticleContent([]);
    setModalVisible(false);
  }

  function handleSummaryScroll() {
    if (!activeLink || !summaryRef.current) return;
    const childRect = activeLink.getBoundingClientRect();
    const containerRect = summaryRef.current.getBoundingClientRect();

    if (childRect.top < containerRect.top) summaryRef.current.scrollTop += childRect.top - containerRect.top - 30;
    if (childRect.bottom > containerRect.bottom)
      summaryRef.current.scrollTop += childRect.bottom - containerRect.bottom + 20;
  }

  function getPrettyArticle(article) {
    const processNodeDefinitions = HtmlToReact.ProcessNodeDefinitions();
    const processingInstructions = [
      {
        shouldProcessNode(node) {
          return node.name && node.name === 'img';
        },
        processNode(node) {
          return React.createElement('img', {
            id: node.attribs.id,
            src: `${process.env.REACT_APP_API_URL}/assets/GuidesAgentsImmos/${articleUrl}/${
              node.attribs.src.split('/').reverse()[0]
            }`,
            className: node.attribs.class,
            alt: node.attribs.alt,
            onError: ({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
              currentTarget.alt = 'Image non disponible';
            },
          });
        },
      },
      {
        shouldProcessNode(node) {
          return node.name && node.name === 'button';
        },
        processNode(node) {
          return React.createElement(
            'a',
            { target: '_blank', className: 'button filled-button article-button', href: '/debut-parcours' },
            node.children[0].data,
          );
        },
      },
      {
        shouldProcessNode() {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ];
    const isValidNode = function () {
      return true;
    };

    const htmlToReactParser = new Parser();
    const reactComponent = htmlToReactParser.parseWithInstructions(
      article.content,
      isValidNode,
      processingInstructions,
    );
    article.content = ReactDOMServer.renderToStaticMarkup(reactComponent);

    return article;
  }

  async function fetchArticle() {
    try {
      const article = (await fetchGuideAgentsImmosByUrlApi(articleUrl)).data[0];
      if (!article) return navigate('/404');

      article.imageAlt = article.image_alt;
      article.imagePath = article.image_path;

      const { content } = article;

      article.content = content;

      const articleWithTrigger = getPrettyArticle(article);

      setArticle(articleWithTrigger);

      setAppLoaderVisible(false);
    } catch (error) {
      navigate('/404');
    }
  }

  function setAnchorsFromArticle() {
    if (!articleRef.current) return;

    const children = Array.from(articleRef.current.children);
    const titles = children.filter(child => child.localName === 'h2');
    setArticleAnchors(
      titles.map((title, index) => {
        title.setAttribute('id', getTitleIdFromName(title.innerHTML));
        const top = title.offsetTop - 50;

        return {
          name: getPrettyText(title.innerHTML),
          link: getTitleIdFromName(title.innerHTML),
          top,
          bottom: titles.length > index + 1 ? titles[index + 1].offsetTop - 50 : top + 1000000,
        };
      }),
    );
  }

  function getTitleIdFromName(name) {
    return name
      .split(' ')
      .join('-')
      .replaceAll(/[^a-zA-Z0-9- ]/g, '');
  }

  function preventAutoScroll() {
    setIsScrollPrevent(true);
    setTimeout(() => {
      setIsScrollPrevent(false);
    }, 1000);
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <section className='container page-container'>
      <Breadcrumbs
        routes={[
          {
            name: 'Accueil',
            to: '/',
          },
          {
            name: 'Conseils: Nos guides pour agents immos',
            to: '/guides-agents-immos',
          },
          {
            name: article.title,
            to: '/TODO',
          },
        ]}
      />
      <Banner
        title='Nos guides pour agents immos'
        subtitle={
          <Link className='link-with-arrow' to='/guides-agents-immos'>
            <ArrowSm /> Retour à la liste des guides
          </Link>
        }
      />
      <PageTitle title={article.title} />
      <img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
          currentTarget.alt = 'Image non disponible';
        }}
        className='article-main-picture mb-xl'
        src={`${process.env.REACT_APP_API_URL}/assets/GuidesAgentsImmos/${articleUrl}/miniature/${article.picture}`}
        alt={article.imageAlt}
      />
      <div className='flex space-between'>
        <aside className='article-aside' ref={summaryRef}>
          <h4 className='outfit-bold'>Dans cet article</h4>
          <ul className='article-nav f-column gap-md flex'>
            {articleAnchors.map((anchor, key) => (
              <li
                key={key}
                custom-title={anchor.name}
                className={
                  !isScrollPrevent && windowScroll.y >= anchor.top && windowScroll.y < anchor.bottom ? 'active' : ''
                }>
                <HashLink onClick={preventAutoScroll} to={`#${anchor.link}`}>
                  {anchor.name}
                </HashLink>
              </li>
            ))}
          </ul>
        </aside>
        <div className='conseils-pros-container mb-sm'>{renderArticleContent()}</div>
        <div className='aside-container'>
          <aside className='article-aside'>
            <h4 className='outfit-bold'>Découvrez nos outils</h4>
            <p className='mb-md'>
              Des outils pratiques pour l'estimation, la cession et l'acquisition de fonds, titres et murs commerciaux
            </p>
            <Link className='link link-with-arrow mb-md' to='/outils-pratiques'>
              Je découvre les outils pratiques&nbsp;
              <ArrowSm rotation='180deg' />
            </Link>
            <Link className='link link-with-arrow' to='/outils-pratiques'>
              J'estime une affaire ou des murs&nbsp;
              <ArrowSm rotation='180deg' />
            </Link>
          </aside>
          <div className='top-scroller-container'>
            <div className='top-scroller' onClick={scrollToTop} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default GuidesAgentsImmosArticle;
