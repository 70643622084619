import React, { useEffect, useRef } from 'react';
import './CaseInput.css';
import { formatNumberWithSpaces, removeSpacesFromEvent, toggleCollapseElement } from '../../../../utils/Utils.js';
import Warning from '../../../atoms/icons/general/warning/Warning.js';

// useForm = {register: register, watch: watch, setValue: setValue}
// icon : euro / percent / search
function CaseInput({
  label,
  name,
  validation = '',
  icon = 'euro',
  visible,
  onChange,
  zeroByDefault = true,
  onFocus,
  disabled,
  onKeyDown,
  useForm,
  value,
  color = 'var(--dark-blue)',
  tip,
  error,
  className = '',
}) {
  const inputRegister =
    useForm.register && (visible || visible === undefined) ? useForm.register(name, validation) : null;
  const containerRef = useRef(null);
  const listener = useForm.watch ? useForm.watch(name) : null;

  useEffect(() => {
    if (listener) useForm.setValue(name, formatNumberWithSpaces(listener));
  }, [listener]);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseElement(containerRef.current, visible);
  }, [visible]);

  function setZero(e) {
    if (e.target.value === '' && useForm.setValue && zeroByDefault) useForm.setValue(name, 0);
  }

  function resetValue(e) {
    if (e.target.value === '0' && useForm.setValue) useForm.setValue(name, '');
  }

  return (
    <div className={`w-100 d-flex ${className}`} ref={containerRef}>
      <div className={`case-input-container ${error ? 'with-error' : ''}`}>
        <label className='case-input-label' style={{ '--color': color }} htmlFor={name}>
          {label} <p className={`label-tip ${tip ? '' : ' d-none'}`}>{tip}</p>
        </label>
        <span className={`before-${icon}`}>
          <input
            disabled={(visible !== undefined && !visible) || disabled}
            id={name}
            value={value !== undefined ? value : undefined}
            {...inputRegister}
            onChange={e => {
              useForm.setValue && useForm.setValue(name, formatNumberWithSpaces(e.target.value));
              inputRegister && inputRegister.onChange(removeSpacesFromEvent(e));
              onChange && onChange(removeSpacesFromEvent(e));
            }}
            onFocus={e => {
              resetValue(e);
              onFocus && onFocus(e);
            }}
            onBlur={setZero}
            onKeyDown={onKeyDown}
          />
          {error && (
            <div className='form-error'>
              <Warning /> <p>{error}</p>
            </div>
          )}
        </span>
      </div>
    </div>
  );
}

export default CaseInput;
