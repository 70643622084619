import React, { useEffect, useRef, useState } from 'react';
import { numberValidation } from '../../../../utils/formValidation/FormValidation.js';
import '../input/Input.css';
import Search from '../../../atoms/icons/general/search/Search';
import Warning from '../../../atoms/icons/general/warning/Warning';
import { formatNumberWithSpaces, removeSpacesFromEvent, toggleCollapseInput } from '../../../../utils/Utils.js';

// useForm = {register: register, watch: watch, setValue: setValue}
// icon : euro / percent / search
function NumberInput({
  label,
  name,
  defaultValue,
  validation = numberValidation,
  error,
  icon,
  visible,
  bgColor,
  onChange,
  zeroByDefault = true,
  onFocus,
  disabled,
  onKeyDown,
  useForm,
  value,
  tip,
  className = '',
}) {
  const inputRegister =
    useForm.register && (visible || visible === undefined) ? useForm.register(name, validation) : null;
  const containerRef = useRef(null);
  const listener = useForm.watch ? useForm.watch(name) : null;

  useEffect(() => {
    if (listener) useForm.setValue(name, formatNumberWithSpaces(listener));
  }, [listener]);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseInput(containerRef.current, visible);
  }, [visible]);

  function setZero(e) {
    if (e.target.value === '' && useForm.setValue && zeroByDefault) useForm.setValue(name, 0);
  }

  function resetValue(e) {
    if (e.target.value === '0' && useForm.setValue) useForm.setValue(name, '');
  }

  return (
    <div className={`w-100 d-flex ${className}`} ref={containerRef}>
      <div style={{ '--bg-color': bgColor }} className={`field-container ${bgColor ? 'filled-input' : ''}`}>
        <label className='form-label' htmlFor={name}>
          {label}
        </label>
        <p className={`label-tip ${tip ? '' : ' d-none'}`}>{tip}</p>
        <span className={`input-container ${icon ? `before-${icon}` : ''} ${error ? ' with-error' : ''}`}>
          {icon === 'search' && <Search className='before-search-content' />}
          <input
            disabled={(visible !== undefined && !visible) || disabled}
            className={`before-${icon}`}
            id={name}
            value={value !== undefined ? value : undefined}
            {...inputRegister}
            onChange={e => {
              useForm.setValue && useForm.setValue(name, formatNumberWithSpaces(e.target.value));
              inputRegister && inputRegister.onChange(removeSpacesFromEvent(e));
              onChange && onChange(removeSpacesFromEvent(e));
            }}
            onFocus={e => {
              resetValue(e);
              onFocus && onFocus(e);
            }}
            defaultValue={defaultValue}
            onBlur={setZero}
            onKeyDown={onKeyDown}
          />
          {error && (
            <div className='form-error'>
              <Warning /> <p>{error}</p>
            </div>
          )}
        </span>
      </div>
    </div>
  );
}

export default NumberInput;
