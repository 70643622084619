import { Link } from 'react-router-dom';
import GoldStar from '../../atoms/homepage/icons/glodStar/GoldStar';
import GreyStar from '../../atoms/homepage/icons/greyStar/GreyStar';
import HalfStar from '../../atoms/homepage/icons/halfStar/HalfStar';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import Card from '../../molecules/cards/card/Card';
import './ReviewsRow.css';
import React, { useEffect, useRef, useState } from 'react';

function ReviewsRow({ reviews, className = '' }) {
  const [slidePosition, setSlidePosition] = useState(1);
  const [sliderDimensions, setSliderDimensions] = useState({
    width: 0,
    childrenCount: 0,
    childWidth: 0,
    padding: 0,
  });

  const sliderRef = useRef(null);

  useEffect(() => {
    calcSliderDimensions();
  }, []);

  useEffect(() => {
    if (sliderDimensions.childWidth && reviews.length) {
      sliderRef.current.style.setProperty('transition', 'none');
      setSlidePosition(sliderDimensions.childWidth * Math.ceil(reviews.length / 2));
      setTimeout(() => {
        if (sliderRef.current) sliderRef.current.style.setProperty('transition', 'left 0.3s');
      }, 200);
    }
  }, [reviews, sliderDimensions]);

  function calcSliderDimensions() {
    setTimeout(() => {
      if (sliderRef.current) {
        const childWidth =
          sliderRef.current.children[0] &&
          sliderRef.current.children[0].getBoundingClientRect().width +
            Number(window.getComputedStyle(sliderRef.current.children[0]).marginLeft.slice(0, -2)) * 2;
        setSliderDimensions({
          width: sliderRef.current.offsetWidth,
          childrenCount: sliderRef.current.children.length,
          childWidth,
          padding: 0,
        });
      }
    }, 100);
  }

  function toNextArticle(upper) {
    let value = 0;

    if (upper) {
      value = Math.min(
        Math.ceil((slidePosition + 10) / sliderDimensions.childWidth) * sliderDimensions.childWidth +
          sliderDimensions.childWidth * 2,
        sliderDimensions.width - sliderDimensions.childWidth * 3,
      );
    } else {
      value = Math.max(
        Math.floor((slidePosition - 10) / sliderDimensions.childWidth) * sliderDimensions.childWidth -
          sliderDimensions.childWidth * 2,
        0,
      );
    }

    setSlidePosition(value);
  }

  function isAtFirstPosition() {
    return slidePosition === 0;
  }

  function isAtLastPosition() {
    return slidePosition === sliderDimensions.childWidth * (sliderDimensions.childrenCount - 1);
  }

  return (
    <div className='slider-hide'>
      <section className={`components-row-container w-100 reviews-row ${className}`}>
        <div className='components-row '>
          <div
            className='components-slider formations-slider'
            style={{ '--slide': `${slidePosition}px`, '--padding': `${sliderDimensions.padding}px` }}
            ref={sliderRef}>
            {reviews.map((review, index) => (
              <Card key={index} className='review-container' bgColor='var(--pale-blue)' padding='30px'>
                <p className='outfit-bold'>{review.username}</p>
                <div>
                  {review.rate >= 1 ? <GoldStar /> : review.rate > 0 ? <HalfStar /> : <GreyStar />}
                  {review.rate >= 2 ? <GoldStar /> : review.rate > 1 ? <HalfStar /> : <GreyStar />}
                  {review.rate >= 3 ? <GoldStar /> : review.rate > 2 ? <HalfStar /> : <GreyStar />}
                  {review.rate >= 4 ? <GoldStar /> : review.rate > 3 ? <HalfStar /> : <GreyStar />}
                  {review.rate >= 5 ? <GoldStar /> : review.rate > 4 ? <HalfStar /> : <GreyStar />}
                </div>
                <p>
                  {review.review.length > 200 ? (
                    <>
                      {review.review.slice(0, 120)}
                      ...{' '}
                      <Link
                        className='review-link'
                        target='_blank'
                        href='https://www.google.com/search?q=EstimerMonCommerce.fr&stick=H4sIAAAAAAAA_-NgU1I1qDAyMzNPMTUxMzNIskxMTTW0MqhIsTCwSEs2S00ySzE1SjFLWsQq6lpckpmbWuSbn-ecnwtkJKfqpRUBAJ7ytONBAAAA&hl=fr&mat=CT4ypzhfQhnOElYB8pgkaK2f6By5zRYj0J3HlPWsMg7y7N8Q3Dmf4LKvMUIjGSGriEd3L8m9B2idLk7rK631UgyJGmZ3m2iGew-MB7maoPiLm9rIeGgWnNwbNoCRREjVxg&authuser=0#lrd=0x2667d54660b9aee1:0xd808fc6eb6d52d6b,1,,,'>
                        Voir plus
                      </Link>
                    </>
                  ) : (
                    review.review
                  )}
                </p>
              </Card>
            ))}
          </div>
          <div className='slider-height' />
        </div>
        <div className='slider-mask' />
        <div className='slider-mask' />
        <FilledButton
          className={`formations-row-bracket ${isAtFirstPosition() ? 'd-none' : ''}`}
          onClick={() => toNextArticle()}
          padding='12px 10px'>
          <Bracket rotation='270deg' marginLeft='0' />
        </FilledButton>
        <FilledButton
          className={`formations-row-bracket ${isAtLastPosition() ? 'd-none' : ''}`}
          onClick={() => toNextArticle(true)}
          padding='12px 10px'>
          <Bracket rotation='90deg' marginLeft='0' />
        </FilledButton>
      </section>
    </div>
  );
}

export default ReviewsRow;
