import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';
import Banner from '../../../components/molecules/banner/Banner';
import PageTitle from '../../../components/molecules/pageTitle/PageTitle';
import FiltersGroup from '../../../components/organisms/filtersGroup/FiltersGroup';
import './ConseilsPros.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ThumbnailsGroup from '../../../components/organisms/thumbnailsGroup/ThumbnailsGroup';
import Bracket from '../../../components/atoms/icons/general/bracket/Bracket';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import ThumbnailsRow from '../../../components/organisms/thumbnailsRow/ThumbnailsRow';
import TextHr from '../../../components/molecules/textHr/TextHr';
import { Link, useNavigate } from 'react-router-dom';
import StickySearchbar from '../../../components/molecules/stickySearchbar/StickySearchbar';
import { fetchAllConseilsProsApi } from '../../../api/OutilsApi';
import AppContext from '../../../context/AppContext';
import { getPrettyText } from '../../../utils/Utils';
import Paginator from '../../../components/molecules/paginator/Paginator';

function ConseilsPros() {
  const [articles, setArticles] = useState([]);
  const [paginationData, setPaginationData] = useState({
    articlesPerPage: 20,
    totalPages: 1,
    activePage: 0,
  });
  const [tags, setTags] = useState([]);
  const [tagsFilters, setTagsFilters] = useState([]);
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [searching, setSearching] = useState(false);
  const [uneArticle, setUneArticle] = useState({
    title: '',
    tag_name: '',
    picture: '',
    article_url: '',
    linkText: "Lire l'article",
  });
  const [resetSearchValueTrigger, setSearchResetValueTrigger] = useState(false);
  const [activeTagId, setActiveTagId] = useState(-1);

  const { width } = useWindowSize();
  const [windowScroll] = useWindowScroll();
  const { setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    setAppLoaderVisible(true);
  }, []);

  useEffect(() => {
    if (width > 1200) {
      if (articles[0] === uneArticle) articles.splice(0, 1);
    } else if (articles[0] !== uneArticle && paginationData.activePage === 0) articles.unshift(uneArticle);
  }, [width, articles]);

  useEffect(() => {
    fetchArticles();
  }, [paginationData.activePage]);

  useEffect(() => {
    setTagsFromArticles(articles);
  }, [displayedArticles, width]);

  useEffect(() => {
    setTagsFiltersFromTags(tags);
  }, [tags]);

  async function fetchArticles() {
    try {
      const data = width
        ? 576(
            await fetchAllConseilsProsApi(
              paginationData.activePage * paginationData.articlesPerPage,
              paginationData.activePage > 0 ? paginationData.articlesPerPage : paginationData.articlesPerPage + 1,
            ),
          ).data
        : (await fetchAllConseilsProsApi(0, 1000)).data;

      setPaginationData(value => ({ ...value, totalPages: Math.ceil(data.total / paginationData.articlesPerPage) }));
      if (!paginationData.activePage) {
        setUneArticle({
          title: getPrettyText(data.conseils_pros[0].title),
          tag_name: getPrettyText(data.conseils_pros[0].tag),
          picture: `${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${data.conseils_pros[0].url}/miniature/${data.conseils_pros[0].picture}`,
          article_url: data.conseils_pros[0].url,
          linkText: "Lire l'article",
        });
        data.conseils_pros.splice(0, 1);
      }

      data.conseils_pros.map((article, index) => {
        data.conseils_pros[index] = {
          title: getPrettyText(article.title),
          tag_name: getPrettyText(article.tag),
          picture: `${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${article.url}/miniature/${article.picture}`,
          article_url: article.url,
          linkText: "Lire l'article",
        };
      });

      setArticles(data.conseils_pros);
      setDisplayedArticles(data.conseils_pros);

      setAppLoaderVisible(false);
    } catch (error) {
      navigate('/404');
    }
  }

  function setTagsFromArticles(articles) {
    const data = [];

    articles.forEach(article => {
      if (!data.includes(article.tag_name)) data.push(article.tag_name);
    });

    data.map((tag, index) => {
      data[index] = {
        name: tag,
        id: tag.toLowerCase().split(' ').join('-'),
      };
    });

    if (width > 576) {
      data.unshift({
        name: 'Tous',
        id: 'tous',
      });
    } else {
      data.unshift({
        name: 'À la une',
        id: 'à-la-une',
      });
    }

    setTags(data);
  }

  function setTagsFiltersFromTags(tags) {
    const data = [];

    tags.forEach(tag => {
      data.push({
        name: tag.name,
        to: width < 576 && `#${tag.id}`,
        onClick: filterArticles,
        params: [tag.id],
      });
    });

    setTagsFilters(data);
  }

  function filterArticles(tagId) {
    if (tagId !== activeTagId) setSearchResetValueTrigger(value => !value);
    setActiveTagId(tagId);

    if (tagId === 'tous') {
      setDisplayedArticles(articles.slice(1));
      return;
    }
    const tag = tags.filter(tag => tag.id == tagId)[0];
    if (tag) setDisplayedArticles(articles.slice(1).filter(article => article.tag_name === tag.name));
    else setDisplayedArticles(articles.slice(1));
  }

  function handleSearchbarChange(value) {
    if (value.length < 1) {
      filterArticles(activeTagId);
      setSearching(false);
    } else {
      setDisplayedArticles(
        articles
          .slice(1)
          .filter(
            article =>
              (activeTagId === -1 || article.tag_name === tags.filter(tag => tag.id == activeTagId)[0].name) &&
              (article.title.toLowerCase().includes(value.toLowerCase()) ||
                article.tag_name.toLowerCase().includes(value.toLowerCase())),
          ),
      );
      setSearching(true);
    }

    if (windowScroll.y > 1200 && width > 1200) window.scrollTo(0, 1000);
    else if (windowScroll.y > 500 && width <= 1200) window.scrollTo(0, 400);
  }

  function updatePagination(page) {
    window.scrollTo({ top: 0, behavior: 'instant' });
    setPaginationData(value => ({ ...value, activePage: page }));
  }

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            {
              name: 'Accueil',
              to: '/',
            },
            {
              name: 'Conseils: Conseils de pros',
              to: '/conseils-pros',
            },
          ]}
        />
        <Banner
          title='Conseils de pros'
          subtitle='Cession, acquisition, optimisation: astuces, pièges, points essentiels'
        />
      </section>
      {width < 992 ? <FiltersGroup data={tagsFilters} /> : ''}
      {width > 992 ? (
        <StickySearchbar
          showFilterButton
          resetValueTrigger={resetSearchValueTrigger}
          margin='0 auto'
          onChange={handleSearchbarChange}
        />
      ) : (
        <StickySearchbar resetValueTrigger={resetSearchValueTrigger} margin='0 auto' onChange={handleSearchbarChange} />
      )}
      <div>
        {width > 576 && articles.length ? (
          <section className='container conseils-pros-container'>
            <ThumbnailsGroup data={displayedArticles} hasFeaturedArticle />
            {!displayedArticles.length && (
              <p className='text-xl text-center outfit-semibold'>Aucun résultat ne correspond à votre recherche</p>
            )}
            <Paginator
              setActivePage={updatePagination}
              activePage={paginationData.activePage}
              totalPages={paginationData.totalPages}
            />
          </section>
        ) : (
          <>
            {!searching && <ThumbnailsRow title={<TextHr id='à-la-une'>À LA UNE</TextHr>} data={[uneArticle]} />}
            {searching && <ThumbnailsRow title={<TextHr id={0}>Résultats</TextHr>} data={displayedArticles} />}
            {tags.map((tag, key) => {
              if (tag.id !== -1 && displayedArticles.filter(article => article.tag_name === tag.name).length) {
                return (
                  <ThumbnailsRow
                    key={key}
                    title={<TextHr id={tag.id}>{tag.name}</TextHr>}
                    data={displayedArticles.filter(article => article.tag_name === tag.name)}
                  />
                );
              }
            })}

            <Paginator
              setActivePage={updatePagination}
              activePage={paginationData.activePage}
              totalPages={paginationData.totalPages}
            />
            {!displayedArticles.length && (
              <p className='text-xl text-center outfit-semibold'>Aucun résultat ne correspond à votre recherche</p>
            )}
          </>
        )}
        <FilledButton to='debut-parcours' className='mid-page-button'>
          Démarrer le parcours d'estimation
        </FilledButton>
      </div>
    </div>
  );
}

export default ConseilsPros;
