import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { max2500Validation, numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput.js';
import Info from '../../../../atoms/icons/general/info/Info.js';
import { ParcoursContext } from '../../../../../context/ParcoursContext.js';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { removeSpaces } from '../../../../../utils/Utils.js';

function ChargesRedevance({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const Form = { watch, register, setValue };
  const {
    showTooltip,
    getStepToGo,
    setGoToStep,
    moveToNextStep,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues('redevance', ['redevance'], setValue, estimationValues);
    fillInputsFromEstimationValues('resultat_net', ['resultat_net_comptable'], setValue, estimationValues);
    if (secondaryParcours) setValue('note_step_6', estimationValues?.notes?.note_step_6);
  }, [estimationValues]);

  async function formSubmit(values) {
    const tmp = {
      redevance: {
        redevance: removeSpaces(values.redevance, 1),
      },
      resultat_net: {
        resultat_net_comptable: removeSpaces(values.resultat_net_comptable, 1),
      },
    };
    if (!secondaryParcours) tmp.notes = { note_step_6: values.note_step_6 };

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2 className='flex gap-sm'>
          autres charges et redevances
          <Info width='23px' margin='0 0 -3px 10px' onClick={() => showTooltip('6Autreschargesetredevances.webp')} />
        </h2>
        <NumberInput
          useForm={Form}
          name='redevance'
          error={errors?.redevance && errors.redevance.message}
          validation={numberValidation}
          icon='euro'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='flex gap-sm'>
          résultat net <Info width='23px' margin='0 0 -3px 10px' onClick={() => showTooltip('7Resultatnet.webp')} />
        </h2>
        <NumberInput
          useForm={Form}
          name='resultat_net_comptable'
          error={errors?.resultat_net_comptable && errors.resultat_net_comptable.message}
          validation={numberValidation}
          icon='euro'
          onFocus={() => {
            if (step == index + 1 && secondaryParcours) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && secondaryParcours) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        {!secondaryParcours && (
          <Textarea
            register={register}
            name='note_step_6'
            error={errors?.note_step_6 && errors.note_step_6.message}
            validation={max2500Validation}
            label='notes et remarques'
            className='mt-md'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        )}
      </fieldset>
    </form>
  );
}

export default ChargesRedevance;
