import React from 'react';
import { useForm } from 'react-hook-form';
import Checkbox from '../../../molecules/formComponents/checkbox/Checkbox';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';
import UnfilledButton from '../../../molecules/buttons/unfilledButton/UnfilledButton';
import Input from '../../../molecules/formComponents/input/Input';
import { defaultValidation, emailValidation } from '../../../../utils/formValidation/FormValidation';

function GuidesAgentsImmosForm({ showArticleContent }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmit(values) {
    showArticleContent.showArticleContent();
    //TODO
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        Pour terminer la lecture de ce guide, inscrivez-vous gratuitement à nos guides pour agents immos du mois en
        renseignant votre adresse email pro ci-dessous
      </p>
      <div className='mt-lg'>
        <Input
          register={register}
          validation={emailValidation}
          error={errors?.email && errors.email.message}
          name='email'
          label='Adresse mail *'
        />
        <Checkbox
          label={
            <p>En cochant cette case j'accepte de m'inscrire aux guides pour agents immos de EstimerMonCommerce.fr</p>
          }
          name='inscription_guides_agents_immos'
          register={register}
          validation={defaultValidation}
          error={errors?.inscription_guides_agents_immos && errors.inscription_guides_agents_immos.message}
        />
      </div>
      <div className='modal-buttons-row mt-lg'>
        <UnfilledButton padding='10px 25px'>Annuler</UnfilledButton>
        <FilledButton type='submit' padding='10px 25px'>
          Valider
        </FilledButton>
      </div>
    </form>
  );
}

export default GuidesAgentsImmosForm;
