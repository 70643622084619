import React, { useContext, useEffect, useRef, useState } from 'react';
import './DetailParcours.css';
import ParcoursMc from '../../../../components/parcours/parcoursMc/ParcoursMc';
import ParcoursTdsFdc from '../../../../components/parcours/parcoursTdsFdc/ParcoursTdsFdc';
import FilledButton from '../../../../components/molecules/buttons/filledButton/FilledButton';
import Bracket from '../../../../components/atoms/icons/general/bracket/Bracket';
import TooltipModal from '../../../../components/organisms/tooltipModal/TooltipModal';
import { ParcoursContext } from '../../../../context/ParcoursContext';
import Breadcrumbs from '../../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../../components/molecules/banner/Banner';
import { fetchAllActivitesByIdApi, fetchTypesCommerceApi } from '../../../../api/ParcoursApi';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAccessToken, fetchSirenData, fetchToken } from '../../../../api/InseeApi';
import { formatNumberWithSpaces } from '../../../../utils/Utils';
import StepsRow from '../../../../components/organisms/stepsRow/StepsRow';
import AppContext from '../../../../context/AppContext';
import SecondaryParcours from '../../../../components/parcours/parcoursTdsFdc/SecondaryParcours';
import { fetchEstimationApi, updateEstimationAPI, validateEstimationApi } from '../../../../api/EstimationApi';
import { Link } from 'react-router-dom';
import ArrowSm from '../../../../components/atoms/icons/general/arrowSm/ArrowSm';

function DetailParcours() {
  const [toolTipVisible, setToolTipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [step, setStep] = useState(1);
  const [estimationValues, setEstimationValues] = useState({});
  const [goToStep, setGoToStep] = useState(0);
  const [typesCommerce, setTypesCommerce] = useState([]);
  // 1: affaires concernées par le chiffre d’affaire hébergement + restauration
  // 2: affaires concernées par l’extraction
  // 3: affaires concernées par le % liquide
  // 4: affaires concernées par la réputation en ligne de Booking (càd : faire apparaître la case des com° nettes sur l'étape 4 du parcours)
  // 5: affaires concernées par le total des commissions nettes
  // 6: affaires pour lesquelles la fourchette CA n'est pas comptabilisée et n'apparaît pas dans l'estimation en fin de rapport PDF
  // 7: affaires pour lesquelles la fourchette EBE sur CA n'est pas comptabilisée et n'apparaît pas dans l'estimation en fin de rapport PDF
  // 8: hôtels
  // 9: CHR (SAUF Hôtel bureau ET Hôtel meublé - Appart'hôtel ET Résidence hôtelière)
  const [activites, setActivites] = useState([]);
  const [parcoursType, setParcoursType] = useState(1); // fdc = 1, tds = 2, mc = 3
  const [displayedRaisonsSociales, setDisplayedRaisonsSociales] = useState([]);
  const [inputsNames, setInputsNames] = useState([]);
  const [raisonsSociales, setRaisonsSociales] = useState([
    {
      id: 6,
      nom: 'AAB',
      siret: '790 680 250 000 12',
      naf: '43.99C',
      rue: '37 RTE DE BESSEGES',
      code_postal: '30530',
      ville: 'CHAMBORIGAUD',
    },
    {
      id: 6,
      nom: 'BAA',
      siret: '790 680 250 000 12',
      naf: '43.99C',
      rue: '37 RTE DE BESSEGES',
      code_postal: '30530',
      ville: 'CHAMBORIGAUD',
    },
  ]);
  const [raisonSocialeWatcher, setRaisonSocialeWatcher] = useState('');
  const [stepsSummary, setStepsSummary] = useState([]);
  const [sirenAccessToken, setSirenAccessToken] = useState('');
  const [dependanceLocalCommercial, setDependanceLocalCommercial] = useState(false);
  const submitFormsFdc = useRef(new Array(14).fill(null));
  const submitFormsMc = useRef(new Array(9).fill(null));
  const parcoursTopRef = useRef(null);
  const tooltipModalRef = useRef(null);
  const { parcoursTypeParam, estimationIdParam } = useParams();
  const { createNotification, setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchActivites();
    // setAccessToken();

    switch (parcoursTypeParam) {
      case 'fonds-de-commerce':
        setParcoursType(1);
        break;
      case 'titres-de-societe':
        setParcoursType(2);
        break;
      case 'murs-commerciaux':
        setParcoursType(3);
        break;
      case 'secondaire':
        setParcoursType(4);
    }
  }, [parcoursTypeParam]);

  useEffect(() => {
    console.log(step);
  }, [step]);

  useEffect(() => {
    if (!estimationIdParam || estimationIdParam === 'null') return;
    setAppLoaderVisible(true);

    fetchEstimation(estimationIdParam);

    if (estimationValues?.infos?.activite?.id) getTypesCommerce(estimationValues?.infos?.activite?.id);
  }, [estimationIdParam]);

  useEffect(() => {
    focusInput(inputsNames[step - 1]);
  }, [step, parcoursType]);

  useEffect(() => {
    console.log('estimation', estimationValues);
  }, [estimationValues]);

  useEffect(() => {
    filterDisplayedRaisonsSociales();
  }, [raisonsSociales, raisonSocialeWatcher]);

  function focusInput(idInput) {
    parcoursTopRef.current && parcoursTopRef.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      document.getElementById(idInput)?.focus([{ preventScroll: true }]);
    }, 400);
  }

  async function setAccessToken() {
    try {
      const token = (await fetchToken()).data;
      const accessToken = (await fetchAccessToken(token)).data.access_token;
      console.log('siren access token', accessToken);
      setSirenAccessToken(accessToken);
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchEstimation(estimationId) {
    try {
      const response = await fetchEstimationApi(estimationId);
      setEstimationValues(response.data.estimation);

      if (parcoursType === 4) setStep(1);
      if (response.data.estimation.type.id === 3) {
        if (response.data.estimation.step < 9) setStep(Number(response.data.estimation.step) + 1);
      } else if (response.data.estimation.step < 13) setStep(Number(response.data.estimation.step) + 1);
    } catch (e) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de votre estimation.{' '}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        'var(--red)',
        'var(--dark-blue)',
      );
    }
    setAppLoaderVisible(false);
  }

  async function fetchActivites() {
    try {
      setActivites((await fetchAllActivitesByIdApi()).data);
    } catch (e) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de données importantes.{' '}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        'var(--red)',
        'var(--dark-blue)',
      );
    }
  }

  async function getTypesCommerce(commerceId) {
    try {
      const res = (await fetchTypesCommerceApi(commerceId)).data;
      if (!res.affaires) return setTypesCommerce([]);

      setTypesCommerce(res.affaires.map(affaire => affaire.id));
    } catch (e) {
      console.log(e);
    }
  }

  function previousStep() {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  function fillInputsFromEstimationValues(parent, fields, setValue, estimation = estimationValues) {
    if (!estimation) return;

    fields.forEach(field => {
      if (estimation[parent] && estimation[parent][field] !== null) setValue(field, estimation[parent][field]);
    });
  }

  function fillSelectsFromEstimationValues(parent, fields, setValue, estimation = estimationValues) {
    fields.forEach(field => {
      if (estimation?.[parent]?.[field]?.id) {
        setValue(field, estimation[parent][field].id + '');
      }
    });
  }

  function isLastStep() {
    if (parcoursType === 4) return step >= 4;
    if (parcoursType === 3) return step >= 9;
    return step >= 13;
  }

  function showTooltip(name) {
    if (tooltipModalRef.current) tooltipModalRef.current.scrollTo({ top: 0, behavior: 'instant' });

    setTooltipContent(name);
    setToolTipVisible(true);
  }

  async function filterDisplayedRaisonsSociales() {
    if (raisonSocialeWatcher === undefined || !sirenAccessToken) return;

    if (!raisonSocialeWatcher.length) return setDisplayedRaisonsSociales([]);

    try {
      const response = (await fetchSirenData(raisonSocialeWatcher, sirenAccessToken)).data.etablissements;
      console.log('response', response);
      setDisplayedRaisonsSociales(
        response.filter((element, key) => {
          return {
            id: key,
            nom: element.uniteLegale.denominationUniteLegale,
            siret: formatNumberWithSpaces(element.siret, true),
            naf: element.uniteLegale.activitePrincipaleUniteLegale,
            adresse:
              element.adresseEtablissement.numeroVoieEtablissement +
              ' ' +
              element.adresseEtablissement.typeVoieEtablissement +
              ' ' +
              element.adresseEtablissement.libelleVoieEtablissement +
              element.adresseEtablissement.complementAdresseEtablissement,
            code_postal: element.adresseEtablissement.codePostalEtablissement,
            ville: element.adresseEtablissement.libelleCommuneEtablissement,
          };
        }),
      );
    } catch (e) {
      console.log(e);
    }
  }

  function selectRaisonSociale(id) {
    const raisonSociale = raisonsSociales.find(element => element.id === id);

    setEstimationValues(prevState => {
      return {
        ...prevState,
        infos: {
          raison_sociale: raisonSociale.nom,
          enseigne: raisonSociale.nom,
          siret: raisonSociale.siret,
          ville: raisonSociale.ville,
          rue: raisonSociale.rue,
          code_postal: raisonSociale.code_postal,
        },
      };
    });

    setDisplayedRaisonsSociales([]);
    moveToNextStep(2);
  }

  function getEstimationId() {
    if (estimationIdParam) return estimationIdParam;
    return estimationValues?.id;
  }

  async function postValues(values) {
    try {
      const payload = {
        ...{
          id: getEstimationId(),
          step: Math.max(step, estimationValues.step != undefined ? estimationValues.step : 0),
          type: {
            id: parcoursType,
          },
          ...values,
        },
      };
      if (!getEstimationId()) delete payload.id;

      console.log('payload', payload);
      const response = await updateEstimationAPI(payload);

      if (!estimationIdParam)
        window.history.replaceState(null, null, `/detail-parcours/${parcoursTypeParam}/${response.data.estimation.id}`);
      setEstimationValues(response.data.estimation);

      if (isLastStep()) {
        await validateEstimationApi(response.data.estimation.id);
        navigate(`/synthese/${estimationIdParam}`);
        createNotification('Votre estimation a été sauvegardée avec succès');
      }
    } catch (e) {
      createNotification(
        <>
          Une erreur est survenue lors de la sauvegarde de l'étape {step}.{' '}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => setStep(step)}>
            Veuillez réessayer
          </span>
        </>,
        'var(--red)',
        'var(--dark-blue)',
      );
    }
  }

  function moveToNextStep(index) {
    if (isLastStep()) return;

    setStep(index + 1);
    if (inputsNames[index + 1] && document.getElementById(inputsNames[index + 1]))
      document.getElementById(inputsNames[index + 1]).focus({ preventScroll: true });
  }

  async function submitStep() {
    console.log(submitFormsFdc, step);
    if (parcoursType === 3) await submitFormsMc?.current[step]();
    else await submitFormsFdc?.current[step]();
  }

  function changeStepFromSummary(goToStep) {
    if (goToStep < step) return setStep(goToStep);
    submitStep();
    setGoToStep(Math.min(goToStep, estimationValues?.step != undefined ? Number(estimationValues.step) + 1 : 0));
    setTimeout(() => {
      setGoToStep(0);
    }, 500);
  }

  function getStepToGo() {
    return document.getElementById('goToStep').value;
  }

  function isStepChecked(actualStep) {
    return (actualStep <= estimationValues?.step || actualStep < step) && actualStep != step;
  }

  return (
    <>
      <TooltipModal
        isVisible={toolTipVisible}
        tooltip={tooltipContent}
        setIsVisible={setToolTipVisible}
        ref={tooltipModalRef}
      />
      <ParcoursContext.Provider
        value={{
          step,
          parcoursTopRef,
          parcoursType,
          getStepToGo,
          setStepsSummary,
          showTooltip,
          estimationValues,
          fillInputsFromEstimationValues,
          setRaisonSocialeWatcher,
          inputsNames,
          setInputsNames,
          typesCommerce,
          setTypesCommerce,
          raisonsSociales,
          setRaisonsSociales,
          activites,
          setStep,
          moveToNextStep,
          postValues,
          submitStep,
          submitFormsFdc,
          submitFormsMc,
          parcoursType,
          dependanceLocalCommercial,
          setDependanceLocalCommercial,
          fillSelectsFromEstimationValues,
          goToStep,
          setGoToStep,
        }}>
        <div className='page-container'>
          <section className='container'>
            <Breadcrumbs
              routes={[
                {
                  name: 'Accueil',
                  to: '/',
                },
                {
                  name: "Estimer: Parcours d'estimation",
                  to: '/detail-parcours',
                },
              ]}
            />
            <Banner
              title='Evaluation en ligne'
              subtitle={
                parcoursType === 4 ? (
                  <Link className='link-with-arrow' to={`/synthese/${estimationIdParam}`}>
                    <ArrowSm /> Retour à la synthèse
                  </Link>
                ) : (
                  "Bienvenue sur notre parcours d'estimation"
                )
              }
            />
          </section>
          <input type='hidden' value={goToStep} id='goToStep' />
          <div ref={parcoursTopRef} />
          <StepsRow
            stepsGroup={stepsSummary}
            actualStep={step}
            onStepClick={changeStepFromSummary}
            isStepChecked={isStepChecked}
          />
          <section className='container relative'>
            {parcoursType === 4 ? <SecondaryParcours /> : parcoursType === 3 ? <ParcoursMc /> : <ParcoursTdsFdc />}
            <div
              className={
                'carousel-buttons ' + (!parcoursType !== 4 && step == 2 && parcoursType !== 3 ? ' step-2-buttons' : '')
              }>
              {step == 1 ? (
                <div />
              ) : (
                <FilledButton height='50px' onClick={previousStep}>
                  <Bracket rotation='270deg' width='18px' />
                  Etape précédente
                </FilledButton>
              )}
              <FilledButton height='50px' bgColor='var(--gold)' color='var(--dark-blue)' onClick={submitStep}>
                {isLastStep() ? (
                  'Valider'
                ) : (
                  <>
                    Valider et continuer
                    <Bracket color='var(--dark-blue)' rotation='90deg' width='18px' />
                  </>
                )}
              </FilledButton>
            </div>
            <div className='raisons-sociales-dropdown'>
              <ul
                className={
                  'raisons-sociales-container' + (displayedRaisonsSociales?.length && step == 2 ? '' : ' h-none')
                }>
                {displayedRaisonsSociales.map((raisonSociale, key) => (
                  <li
                    className='raison-sociale-container'
                    key={key}
                    onClick={() => selectRaisonSociale(raisonSociale.id)}>
                    <p className='raison-sociale-title'>{raisonSociale.nom}</p>
                    <div className='row'>
                      <p>
                        <span className='raison-sociale-label'>SIRET :</span>{' '}
                        <span className='raison-sociale-value'>{raisonSociale.siret}</span>
                      </p>
                      <p>
                        <span className='raison-sociale-label'>NAF :</span>{' '}
                        <span className='raison-sociale-value'>{raisonSociale.naf}</span>
                      </p>
                    </div>
                    <p className='raison-sociale-label'>ADRESSE</p>
                    <p className='raison-sociale-value'>{raisonSociale.rue}</p>
                    <p className='raison-sociale-value'>{raisonSociale.code_postal + ' ' + raisonSociale.ville}</p>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </ParcoursContext.Provider>
    </>
  );
}

export default DetailParcours;
