import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/organisms/header/Header';
import Footer from '../../../components/organisms/footer/Footer';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import TextHr from '../../../components/molecules/textHr/TextHr';

function Cgv() {
  return (
    <section className='container page-container'>
      <Breadcrumbs
        routes={[
          { name: 'Accueil', route: '/' },
          { name: 'Liens légaux: Conditions générales de vente', route: '/cgv' },
        ]}
      />
      <Banner title='Conditions générales de vente' subtitle='Applicables au 05 juillet 2022' />
      <div className='big-text-container mt-xl'>
        <p>
          Le site Internet accessible à l’adresse{' '}
          <Link className='link' to='/'>
            www.estimermoncommerce.fr
          </Link>{' '}
          est édité et exploité par la société GALAXICO, SAS au capital social de 10 000 €, immatriculée au RCS de PARIS
          sous le numéro 878 387 885, ayant son siège social situé 41 rue de Cronstadt 75015 PARIS (FRANCE), et dont les
          coordonnées sont les suivantes :
        </p>
        <ul>
          <li>
            Téléphone : 06 63 84 52 56, du lundi au vendredi, hors jours fériés et congés annuels, de 10h à 12h et de
            14h à 17h ;
          </li>
          <li>
            Email :{' '}
            <Link className='link' to='mailto:contact@estimermoncommerce.fr'>
              contact@estimermoncommerce.fr
            </Link>
            .
          </li>
        </ul>
        <p>
          Les présentes conditions générales de vente (ci-après « les CGV ») constituent le contrat conclu entre vous et
          GALAXICO et encadre les conditions dans lesquelles GALAXICO vous fournit en ligne sur le site{' '}
          <Link className='link' to='/'>
            www.estimermoncommerce.fr
          </Link>{' '}
          un service d’estimation de commerce, d’entreprise et de murs commerciaux, ainsi que des informations, des
          conseils, des actualités et des outils pratiques relatifs à l’estimation, l’optimisation et la transmission de
          commerce et d’entreprise.
        </p>
        <p className='outfit-bold'>
          Toute souscription d’une offre de service proposée par la société GALAXICO sur le présent site étant soumise
          aux CGV, nous attirons votre attention sur l’importance de les lire attentivement.
        </p>
        <p className='outfit-bold'>
          Toute souscription d’une offre de service proposée par la société GALAXICO sur le présent site implique que
          votre acceptation des CGV dans leur intégralité, la reconnaissance d’en avoir parfaitement connaissance et la
          renonciation à vous prévaloir de vos propres conditions d’achat.
        </p>
        <TextHr className='my-xl'>Article 1. Définitions</TextHr>
        <p>Dans les présentes CGV, les termes ci-après mentionnés auront la signification suivante :</p>
        <ul className='dots-list'>
          <li>
            Commande : désigne tout bon de commande ou contrat conclu avec GALAXICO en relation avec votre Offre de
            Service, lequel peut définir notamment les fonctionnalités et options comprises dans votre Offre de Service
            ;
          </li>
          <li>
            Compte : désigne tout compte créé par vous ou pour votre compte sur le Site permettant d’utiliser le Service
            ;
          </li>
          <li>
            GALAXICO : désigne la société éditant et exploitant le Site et proposant le Service, telle que décrite en
            préambule des CGV ;
          </li>
          <li>
            Offre : désigne la formule de souscription au Service à laquelle vous avez souscrite et le Service et les
            fonctionnalités et options qui y sont associés, qu’il s’agisse d’une Offre gratuite, d’une Offre payante
            unique ou d’une Offre payante sous forme de crédits et quelle qu’en soit la durée ;
          </li>
          <li>Partie : désigne toute partie aux CGV, incluant vous et GALAXICO ;</li>
          <li>
            Rapport : désigne le document qui vous est transmis par GALAXICO lors de votre utilisation du Service en vue
            de l’estimation de fonds de commerce, de titres de société ou de murs commerciaux, réalisé sur la base des
            informations, éléments et documents que vous avez fournis à GALAXICO ;
          </li>
          <li>
            Service : désigne tous services mis à votre disposition par GALAXICO sur le Site, ou tout autre support à
            venir, qu’il soit mis à votre disposition dans le cadre d’une Offre payante ou non, à savoir notamment un
            service d’estimation de fonds de commerce, de titres de société ou de murs commerciaux, ainsi que des
            informations, des conseils, des actualités et des outils pratiques relatifs à l’estimation, l’optimisation
            et la transmission de commerce et d’entreprise ;
          </li>
          <li>
            Site : désigne le site Internet édité et exploité par GALAXICO accessible depuis l’URL
            www.estimermoncommerce.fr ;
          </li>
          <li>
            Vous ou Utilisateur : désigne toute personne physique majeure ou toute personne morale agissant en qualité
            de consommateur, de non-professionnel ou de professionnel ayant souscrit une Offre de Service.
          </li>
        </ul>
        <TextHr className='my-xl'>Article 2. Objet</TextHr>
        <p>
          Les CGV sont conclues entre GALAXICO et vous et ont pour objet de définir les conditions dans lesquelles
          GALAXICO vous fournit le Service en ligne par l’intermédiaire du Site.
        </p>
        <p>
          Elles s’appliquent sans restriction ni réserve à l’ensemble des prestations de services fournies sur le Site
          par GALAXICO dans le cadre du Service.
        </p>
        <TextHr className='my-xl'>Article 3. Description et fonctionnement du Service</TextHr>
        <h3 className='h2-uppercase'>1. Caractéristiques essentielles</h3>
        <p>
          Les caractéristiques essentielles du Service sont décrites et présentées sur le Site avec la plus grande
          exactitude possible.
        </p>
        <p>
          Le choix et la souscription d’une Offre de Service relevant de votre seule responsabilité, il vous appartient,
          avant de passer votre Commande, de vous reporter au descriptif de chaque Offre sur le Site afin d’en connaître
          les caractéristiques essentielles.
        </p>
        <p>Le Service et les Offres sont disponibles tant qu’ils sont visibles sur le Site.</p>
        <p>
          GALAXICO se réserve la possibilité de modifier à tout moment ses Offres de Service proposées à la vente sur le
          Site.
        </p>
        <h3 className='h2-uppercase'>2. Performance</h3>
        <p>
          GALAXICO s’engage à faire ses meilleurs efforts pour vous assurer un temps de réponse du Service satisfaisant
          et vous informe toutefois des aléas inhérents au réseau Internet et aux interruptions d’accès qui peuvent en
          résulter.
        </p>
        <p>
          GALAXICO ne garantit pas la fiabilité ou les performances de vos connexions au réseau permettant d’accéder au
          Service, et notamment au réseau Internet.
        </p>
        <p>
          GALAXICO ne peut garantir que l’accès au Site et au Service pourra se faire sans ralentissement et ne saurait
          être tenue responsable des conséquences pouvant découler d’un ralentissement de l’accès au Site et au Service.
        </p>
        <h3 className='h2-uppercase'>3. Disponibilité</h3>
        <p>
          Le Site et le Service sont disponibles à tout moment, 24 heures sur 24 et 7 jours sur 7, y compris les
          dimanches et jours fériés.
        </p>
        <p>
          En cas de suspension ou d’interruption du Site ou du Service prévisible par GALAXICO, notamment pour des
          raisons de maintenance ou de mises à jour, et si vous avez souscrit une Offre de Service payante, GALAXICO
          s’engage à vous en avertir préalablement par e-mail ou par affichage sur le Site.
        </p>
        <p>
          Dans l’hypothèse visée au paragraphe précédent, GALAXICO ne saurait en aucun cas être tenue responsable des
          conséquences pouvant découler d’une suspension ou d’une interruption du Site ou du Service.
        </p>
        <h3 className='h2-uppercase'>4. Evolution du Service</h3>
        <p>
          Les fonctionnalités constituant le Site et le Service sont susceptibles d’être modifiées, complétées et
          enrichies à tout moment par GALAXICO.
        </p>
        <p>
          GALAXICO se réserve la faculté de modifier le contenu et le fonctionnement du Site ou du Service pendant la
          durée de votre Offre ; le cas échéant, vous serez informé desdites évolutions par publication sur le Site ou
          par tout autre moyen adéquat.
        </p>
        <h3 className='h2-uppercase'>5. Utilisation de votre Compte</h3>
        <p>
          Vous demeurez seul responsable de l’ensemble des opérations effectuées depuis votre Compte et vous engagez à :
        </p>
        <ul>
          <li>
            Informer immédiatement GALAXICO en cas de vol, perte ou utilisation non autorisée de votre Compte, login
            et/ou mot de passe ou toute autre violation de sécurité connue ou suspectée ;
          </li>
          <li>
            Ne pas usurper l’identité d’un autre utilisateur du Site ou fournir de fausses informations afin d’accéder
            ou utiliser le Service ;
          </li>
          <li>
            Préserver la confidentialité de votre mot de passe d’accès à votre Compte et au Service et à prendre les
            mesures nécessaires pour qu'aucun tiers, en quelque qualité que ce soit, n'ait accès à votre mot de passe et
            puisse ainsi accéder illicitement à votre Compte.
          </li>
        </ul>
        <h3 className='h2-uppercase'>6. Assistance</h3>
        <p>
          GALAXICO vous propose une assistance technique en cas d’interrogation concernant le Site ou le Service,
          disponible par formulaire de contact en ligne à l’adresse{' '}
          <Link className='link' to='mailto:contact@estimermoncommerce.fr'>
            contact@estimermoncommerce.fr
          </Link>{' '}
          ou par téléphone au 06 63 84 52 56, du lundi au vendredi, hors jours fériés et congés annuels, de 10h à 12h et
          de 14h à 17h.
        </p>
        <TextHr className='my-xl'>Article 4. Modalités de souscription d’une Offre sur le Site</TextHr>
        <h3 className='h2-uppercase'>1. Création d’un Compte et identification préalable</h3>
        <p>
          Pour toute première souscription en ligne d’une Offre sur le Site, vous devez procéder à la création d’un
          Compte avant le début de votre Commande ou au plus tard pendant le processus de validation de Commande.
        </p>
        <p>
          Pour les souscriptions en ligne suivantes, vous devez vous connecter à votre Compte en renseignant votre
          adresse e-mail et votre mot de passe.
        </p>
        <h3 className='h2-uppercase'>2. Sélection de l’Offre</h3>
        <p>
          En naviguant sur le Site, vous pouvez accéder à l’Offre de votre choix par l’un des trois moyens suivants :
        </p>
        <ul>
          <li>
            En cliquant sur la rubrique « Tarifs » situé en haut de page du Site, puis en sélectionnant l’une des Offres
            qui vous sont proposées.
          </li>
          <li>
            En cliquant sur le bouton « Obtenir mon rapport détaillé » à la fin de la synthèse à l’issue du parcours
            d’estimation, puis en sélectionnant l’une des Offres qui vous sont proposées.
          </li>
          <li>
            En cliquant sur la rubrique « Mon compte {'>'} Mon Offre » situé en haut de page du Site, puis en cliquant
            sur le bouton « Ajouter des estimations », puis en sélectionnant l’une des Offres qui vous sont proposées.
          </li>
        </ul>
        <p>Vous avez la possibilité de sélectionner l’Offre de votre choix en cliquant sur « Activer»</p>
        <p>
          Cas particulier : dans le cadre d’accords commerciaux spécifiques, un lien peut vous être fourni, vous
          permettant d’accéder directement à la page des Offres.
        </p>
        <h3 className='h2-uppercase'>3. Validation de la Commande de souscription à l’Offre</h3>
        <p>
          Un bouton intitulé « Mon panier » affichant le contenu de votre panier apparait et vous permet soit de
          supprimer l’Offre sélectionnée et de retourner sur la page de sélection des différentes Offres ou sur la page
          d’accueil, soit de poursuivre la commande en cliquant sur le lien « Commander ». Vous pouvez ainsi consulter
          le contenu de votre panier et :
        </p>
        <ul>
          <li>Prendre connaissance de la nature et de la quantité de l’Offre (ou des Offres) qui s’y trouve(nt) ;</li>
          <li>Modifier la quantité de l’Offre (ou des Offres) sélectionnée(s) ;</li>
          <li>Supprimer l’Offre (ou les Offres) sélectionnée(s) ;</li>
          <li>Connaître le montant total de la TVA sur l’ensemble de votre Commande ;</li>
          <li>Connaître le montant TTC total de votre Commande.</li>
          <li>Insérer un coupon de gratuité si vous en avez reçu un par nos soins</li>
        </ul>
        <p>
          Après avoir pu vérifier que le contenu de votre panier répond à vos attentes et corriger les éventuelles
          erreurs qui auraient pu être commises, vous pouvez cliquer sur le bouton « Commander ».
        </p>
        <p>
          Vous pouvez à tout moment quitter le panier et y revenir plus tard en cliquant sur l’icône « Panier » située
          en haut de page à côté du bouton « Connexion ». En y revenant plus tard, vous pourrez poursuivre votre
          commande ou annuler ou ajouter des offres.
        </p>
        <p>Vous accédez ensuite à une page intitulée « Ma commande » vous permettant de :</p>
        <ul>
          <li>
            Avoir un rappel du montant total de la TVA sur l’ensemble de votre Commande, du montant TTC total de votre
            Commande ;
          </li>
          <li>Sélectionner le mode de paiement de votre choix (carte bancaire).</li>
        </ul>
        <p>
          A la fin de cette deuxième étape, après avoir confirmé avoir pris connaissance et accepté les CGV, ainsi que
          les Conditions Générales d’Utilisation et la Politique de Confidentialité du Site en cochant la case prévue à
          cet effet, il vous est proposé de vous inscrire à notre newsletter. Puis vous pouvez confirmer définitivement
          votre Commande et procéder à son paiement en cliquant sur le bouton « Commander avec obligation de paiement ».
        </p>
        <p>
          Cas particulier dans le cadre d’accords commerciaux spécifiques, bénéficiant d’un lien d’accès direct à la
          page des Offres : la sélection de l’Offre désirée (en cliquant sur le bouton « Activer») vous conduit
          directement à la page « Ma commande ».
        </p>
        <h3 className='h2-uppercase'>4. Paiement de la Commande</h3>
        <p>
          GALAXICO vous rappelle que la validation définitive de votre Commande implique l’obligation à votre charge de
          payer le prix indiqué.
        </p>
        <p>
          Le montant total de votre Commande correspond au prix d’achat de la ou des Offres sélectionnées. Il est
          payable comptant et en totalité lors de la validation de votre Commande.
        </p>
        <p>
          Le règlement de votre Commande s'effectue par carte bancaire au moyen de la solution (Stripe) de paiement
          sécurisé du partenaire financier de GALAXICO.
        </p>
        <p>
          Le paiement par carte bancaire s’effectue sur la base des coordonnées bancaires que vous fournissez durant le
          processus de paiement au moyen de la solution Stripe.
        </p>
        <p>
          Le détenteur de la carte bancaire est soumis à un contrôle de validation et d’autorisation effectué par
          l’organisme émetteur ; si cet organisme refuse l’autorisation de paiement, GALAXICO n’acceptera pas votre
          Commande.{' '}
        </p>
        <p>Votre compte bancaire est débité du montant de votre ou vos Offres dès la validation de votre Commande.</p>
        <p>
          GALAXICO n’est pas responsable des frais qui pourraient être imputés par votre organisme émetteur ou votre
          banque à l’occasion du paiement par carte bancaire de votre Commande.
        </p>
        <h3 className='h2-uppercase'>5. Validation définitive de la Commande</h3>
        <p>La vente est définitivement conclue entre GALAXICO et vous lorsque le paiement est effectué et accepté.</p>
        <p>
          Une fois votre Commande définitivement validée et réglée, GALAXICO en accuse réception en vous adressant un
          e-mail de confirmation récapitulant le détail de votre ou vos Offres et comprenant un lien vers les CGV.
        </p>
        <p>
          La facture correspondante à votre commande payée est mise à votre disposition instantanément sur le Site. Vous
          pouvez y accéder et la télécharger en cliquant sur la rubrique « Mon compte {'>'} Mon Offre », dans laquelle
          se trouve la sous-rubrique « Mes factures ».
        </p>
        <p>
          Pour toute question relative au suivi de votre souscription à une Offre, vous pouvez contacter GALAXICO aux
          coordonnées indiquées en préambule.
        </p>
        <TextHr className='my-xl'>Article 5. Conditions financières</TextHr>
        <h3 className='h2-uppercase'>1. Prix du Service</h3>
        <p>
          Les prix du Service sont indiqués sur le Site en euros, hors taxes, sur les pages vous permettant de
          sélectionner l’Offre de votre choix, telles que précisées à l’Article 4 – paragraphe 2.
        </p>
        <p>
          Les prix du Service sont indiqués toutes taxes comprises sur les pages « Mon Panier » et « Ma Commande »,
          accessibles lors du processus de commande et toutes taxes comprises lors du processus de paiement. Ils
          tiennent compte et détaillent la TVA et les éventuelles réductions applicables au jour de la Commande ; tout
          changement du taux de TVA applicable sera automatiquement répercuté sur le prix du Service proposé à la vente
          sur le Site.
        </p>
        <p>Les prix dépendent de l’Offre que vous décidez de souscrire.</p>
        <p>
          La souscription à l’Offre de Service est consentie moyennant le règlement du tarif forfaitaire ou le tarif de
          l’abonnement indiqué sur le Site.
        </p>
        <p>
          Vous êtes informé que le tarif forfaitaire de l’Offre ne comprend pas les coûts d’accès à Internet permettant
          d’accéder au Site, qui demeurent entièrement à votre charge.
        </p>
        <h3 className='h2-uppercase'>2. Conditions de paiement et engagement</h3>
        <p>
          Le prix de l’Offre de Service « ATELIER » est payable en une seule fois, le jour de la souscription, par carte
          bancaire. Lorsque vous cliquez sur « Activer » sur l’Offre « ATELIER », l’équipe de GALAXICO vous contacte
          pour un entretien préalable, visant à qualifier la pertinence de votre demande, afin de nous assurer de
          pouvoir vous apporter un service cohérent. Cet entretien vise également à définir la façon dont nous allons
          procéder pour vous délivrer le Service. A l’issue de cet entretien, si la mission d’évaluation est acceptée
          par les Parties, un lien de paiement intégral et immédiat vous est envoyé par email. Le paiement de l’Offre
          déclenche le début du Service.
        </p>
        <p>
          Le prix de l’Offre de Service « LIBERTY » est en paiement mensuel par abonnement, par carte bancaire, sans
          engagement. Le paiement est à effectuer lors de la souscription. La souscription à l’Offre de Service «
          LIBERTY » sans engagement vous permet d’interrompre votre abonnement à tout moment. Le paiement mensuel par
          abonnement sans engagement de l’Offre « LIBERTY » vous donne accès à un nombre illimité d’estimations durant 1
          (un) mois à compter de la date du paiement. Il vous donne également accès à l’intégralité des ressources du
          Site, notamment les Outils Pratiques, durant la même période.
        </p>
        <p>
          Le prix de l’Offre de Service « MAESTRO » est en paiement mensuel par abonnement, par carte bancaire, avec
          engagement de 12 mois. Le paiement est à effectuer lors de la souscription. Le paiement mensuel avec
          engagement sur 12 mois donne lieu à une réduction par rapport à l’Offre « LIBERTY ». La souscription à l’Offre
          de Service « MAESTRO » avec engagement vous engage sur une durée de 12 (douze) mois. Le paiement mensuel par
          abonnement sans engagement de l’Offre « MAESTRO » vous donne accès à un nombre illimité d’estimations durant
          12 (douze) mois à compter de la date du paiement du premier mois. Il vous donne également accès à
          l’intégralité des ressources du Site, notamment les Outils Pratiques, durant la même période.
        </p>
        <p>
          Le prix de l’Offre de Service « OPTIMUM » est en paiement annuel en une seule fois, le jour de la
          souscription, par carte bancaire. Le paiement annuel en une seule fois donne lieu à une réduction par rapport
          aux Offres « LIBERTY » et « MAESTRO ». Le paiement annuel en une seule fois de l’Offre « OPTIMUM » vous donne
          accès à un nombre illimité d’estimations durant 12 (douze) mois à compter de la date du paiement. Il vous
          donne également accès à l’intégralité des ressources du Site, notamment les Outils Pratiques.
        </p>
        <p>
          Pour les Offres « LIBERTY » et « MAESTRO » en paiement mensuel, le prélèvement sur la carte bancaire utilisée
          lors du paiement est réalisé automatiquement chaque mois au même jour que le jour du premier mois de paiement.
          Si la carte bancaire utilisée vient à expiration au cours de la durée de l’Offre souscrite, les données de
          paiement d’une nouvelle carte bancaire valide doivent être renseignées par le Client en remplacement des
          données de paiement de la carte bancaire venue à échéance, afin de pouvoir continuer à bénéficier de l’Offre
          en cours. Les données de paiement de la nouvelle carte bancaire doivent être renseignées et validées au plus
          tard au jour habituel de paiement (même jour que le jour du premier mois de paiement) le mois suivant le
          dernier prélèvement. Dans le cas où de nouvelles données de paiement d’une carte bancaire valide ne seraient
          pas renseignées par le Client au plus tard au jour habituel de paiement (même jour que le jour du premier mois
          de paiement) le mois suivant le dernier prélèvement, la Sas Galaxico se réserve le droit d’interrompre l’Offre
          de Service en cours, jusqu’à ce que le Client renseigne des nouvelles données de paiement d’une carte bancaire
          valide, au plus tard. Sans action de la part du Client pour renseigner de nouvelles données de paiement d’une
          carte bancaire valide au plus tard au jour susmentionné, l’offre de service sera définitivement perdue pour le
          Client.
        </p>
        <p>
          Le prix de l’offre « Consultation téléphonique ou en visio (durée 1h30) » est payable en une seule fois, le
          jour de la souscription, par carte bancaire. Lorsque vous cliquez sur « expliquez-nous ici en quelques mots
          votre besoin » au-dessus de l’Offre de consultation, l’équipe de GALAXICO vous contacte pour un entretien
          préalable, visant à qualifier la pertinence de votre demande, afin de nous assurer de pouvoir vous apporter un
          service cohérent. Cet entretien vise également à définir la façon dont nous allons procéder pour vous délivrer
          le Service. A l’issue de cet entretien, si la mission d’évaluation est acceptée par les Parties, un lien de
          paiement intégral et immédiat vous est envoyé par email. Le paiement de l’Offre déclenche le début du Service.
        </p>
        <p>
          Le prix de l’offre « Accompagnement personnalisé après étude de vos besoins » et ses modalités de paiement
          sont définis sur devis uniquement, à l’issue d’un entretien initial avec Vous. Si vous acceptez le devis qui
          vous sera fourni, son paiement déclenche le début du Service.
        </p>
        <h3 className='h2-uppercase'>3. Période d'essai</h3>
        <p>
          Dans le cas où une période d’essai est consentie par la Sas Galaxico à l’Utilisateur, la durée de cette
          période est expressément stipulée lors de la souscription sur la page « Tarifs ».
        </p>
        <p>
          Lors de la souscription, l’Utilisateur doit obligatoirement renseigner les coordonnées d’une carte bancaire en
          cours de validité, mais ne doit régler aucune somme jusqu’à la fin de la période d’essai, qui est totalement
          gratuite.
        </p>
        <p>
          A l’issue de la période d’essai, sauf résiliation de sa part, l’Utilisateur est automatiquement facturé du
          montant correspondant à l’Offre de son choix (cf. « Conditions de paiement et engagement » de l’article «
          Conditions financières », sans qu’aucune action de sa part ne soit nécessaire. Sa carte bancaire est alors
          automatiquement débitée du montant correspondant au prix indiqué sur l’Offre souscrite sur la page « Tarifs ».
        </p>
        <p>
          Si l’Utilisateur ne souhaite pas poursuivre l’Offre de service à l’issue de sa période d’essai, il peut la
          résilier à tout moment jusqu’au dernier jour (inclus) de la période d’essai, en cliquant sur l’option « Ne pas
          renouveler mon abonnement », située dans la section « Mes Offres » sur « Mon compte {'>'} Mon Offre » .
        </p>
        <p>
          Si l’Utilisateur n’a pas résilié son Offre de service avant la fin de période d’essai, il est engagé durant la
          période correspondant à l’Offre souscrite (cf. Article « Durée de l’Offre »).
        </p>
        <h3 className='h2-uppercase'>4. Délais de paiement</h3>
        <p>
          Le prix de toute autre Offre de Service payable autrement que dans les conditions prévues au paragraphe
          ci-dessus est payable dans son intégralité dans un délai de trente (30) jours courant à compter de la date
          d’émission par GALAXICO de la facture correspondante.
        </p>
        <p>
          Tout retard de paiement des sommes dues à GALAXICO au-delà du délai de paiement figurant sur sa facture
          entraînera de plein droit, sans aucune formalité ni mise en demeure préalable, et sans préjudice de toute
          autre voie d'action que GALAXICO serait en droit d'engager, l’exigibilité d'une indemnité forfaitaire pour
          frais de recouvrement d'un montant de 40 €, GALAXICO se réservant le droit de vous demander une indemnisation
          complémentaire si les frais de recouvrement effectivement engagés dépassaient ce montant, sur présentation des
          justificatifs ainsi que l'application de pénalités de retard au taux annuel de 12 % sur les sommes restant
          dues.
        </p>
        <p>
          Par ailleurs, en cas de défaut de paiement persistant huit jours après l’envoi d’une mise en demeure de payer
          par lettre recommandée avec accusé de réception, GALAXICO se réserve le droit de suspendre votre accès au
          Service et à votre Compte, sans préjudice de sa faculté de résilier votre Offre dans les conditions de
          l'article « Résiliation anticipée » ci-après ; dans cette hypothèse, le rétablissement de l'accès au Service
          et à votre Compte ne sera effectif qu'après parfait paiement et pour la durée de l’Offre restant à courir.
        </p>
        <h3 className='h2-uppercase'>5. Modification des prix par GALAXICO</h3>
        <p>
          GALAXICO se réserve le droit de modifier à tout moment et sans préavis le prix du Service, ainsi que les
          fonctionnalités et options correspondant aux Offres proposées sur le Site.
        </p>
        <p>
          Toutefois, GALAXICO s’engage à appliquer à votre Offre les tarifs tels qu’ils vous auront été indiqués au
          moment de la Commande de votre Offre.
        </p>
        <TextHr className='my-xl'>Article 6. Durée de l’Offre</TextHr>
        <p>
          Votre Offre entre en vigueur au jour de la validation définitive de votre Commande sur le Site et est
          souscrite pour la durée mentionnée sur le Site à la page « Tarifs ».
        </p>
        <TextHr className='my-xl'>
          Article 7. Résiliation et renouvellement de l’Offre « LIBERTY » sans engagement par abonnement avec paiement
          mensuel
        </TextHr>
        <p>
          L’Offre de Service « LIBERTY » sans engagement par abonnement et paiement mensuel est valable pour une durée
          de 1 (un) mois. Cette durée est renouvelable tacitement en fin de période de 1 (un) mois, dans les mêmes
          conditions. Sans action de votre part, le renouvellement tacite sera effectué automatiquement à l’issue de la
          durée de 1 (un) mois.{' '}
        </p>
        <p>
          Vous pouvez refuser le renouvellement tacite de l’Offre d’abonnement « LIBERTY » à tout moment, sans frais de
          dossier, en cliquant sur l’option « Ne pas renouveler mon abonnement », présente dans « Mon compte {'>'} Mon
          Offre » .
        </p>
        <p>
          En cas de résiliation ou non-renouvellement de votre Offre d’abonnement « LIBERTY » à votre initiative, ou de
          désactivation ou de suppression de votre Compte à votre initiative, le montant de la mensualité que vous avez
          réglée à GALAXICO restera intégralement acquis à GALAXICO et ne fera l’objet d’aucun remboursement total ou
          partiel au Client.
        </p>
        <p>
          Si votre moyen de paiement vient à échéance au cours des renouvellements tacites, vous serez dans l’obligation
          de renseigner un nouveau moyen de paiement valide. Dans le cas où vous ne le feriez pas, votre Offre de
          Service sera automatiquement interrompue à partir du mois suivant le dernier mois dûment payé.
        </p>
        <p>
          GALAXICO est libre de faire évoluer les prix à chaque renouvellement. GALAXICO vous en notifiera toutefois par
          écrit au plus tard un (1) mois avant l’entrée en vigueur des nouveaux prix. Le renouvellement tacite sera
          alors désactivé et l’Offre d’abonnement ne sera renouvelée qu’en cas d’accord express de votre part.
        </p>
        <TextHr className='my-xl'>
          Article 8. Résiliation et renouvellement de l’Offre « MAESTRO » avec engagement de 12 mois par abonnement avec
          paiement mensuel
        </TextHr>
        <p>
          l’Offre de Service « MAESTRO » avec engagement de 12 mois par abonnement et paiement mensuel vous engage au
          paiement de toutes les mensualités sur une durée minimum de 12 (douze) mois. Cette durée est renouvelable
          tacitement en fin d’engagement, dans les mêmes conditions. Sans action de votre part, le renouvellement tacite
          sera effectué automatiquement à l’issue de la durée d’engagement minimum de 12 (douze) mois.
        </p>
        <p>
          Vous pouvez refuser le renouvellement tacite de l’Offre d’abonnement « MAESTRO » avec engagement de 12 mois
          avec paiement mensuel dans le mois précédant sa date de fin d’engagement, sans frais de dossier, en cliquant
          sur l’option « Ne pas renouveler mon abonnement», qui apparaîtra dans « Mon compte {'>'} Mon Offre » dans le
          mois précédant la date de votre fin d’engagement.
        </p>
        <p>
          En cas de résiliation de votre Offre d’abonnement « MAESTRO » avec engagement de 12 mois avec paiement mensuel
          à votre initiative, ou de désactivation ou de suppression de votre Compte à votre initiative, la dernière
          mensualité à venir (clôturant la fin des 12 mois d’engagement) sera due à GALAXICO et sera prélevée au dernier
          mois d’abonnement sur votre moyen de paiement.{' '}
        </p>
        <p>
          Toute Offre en cours d’abonnement « MAESTRO » avec engagement de 12 mois avec paiement mensuel restera en
          cours jusqu’à son terme d’engagement, et ne sera pas renouvelée en cas de résiliation, de désactivation ou de
          suppression de votre Compte. Si votre moyen de paiement vient à échéance avant la fin de durée d’engagement de
          12 (douze) mois, vous serez dans l’obligation de renseigner un nouveau moyen de paiement valide. Dans le cas
          où vous ne le feriez pas, GALAXICO sera autorisé à vous réclamer l’intégralité des sommes restant dues, que
          vous vous engagez à régler en une seule fois dans un délai de 30 (trente) jours à compter de la notification
          par GALAXICO.
        </p>
        <p>
          GALAXICO est libre de faire évoluer les prix à chaque renouvellement. GALAXICO vous en notifiera toutefois par
          écrit au plus tard un (1) mois avant la date de fin d’engagement. Le renouvellement tacite sera alors
          désactivé et l’Offre d’abonnement ne sera renouvelée qu’en cas d’accord express de votre part.
        </p>
        <TextHr className='my-xl'>
          Article 9. Résiliation et renouvellement de l’Offre « OPTIMUM » par abonnement avec paiement annuel
        </TextHr>
        <p>
          Le paiement annuel en une seule fois de l’Offre « OPTIMUM » est irréversible, définitif, et ne donne lieu à
          aucune possibilité de remboursement partiel ou total, à la suite de la souscription de l’Offre et du paiement
          de celle-ci.
        </p>
        <p>
          En cas de résiliation de votre Offre d’abonnement « OPTIMUM » avec paiement annuel à votre initiative, ou de
          désactivation ou de suppression de votre Compte à votre initiative, le montant que vous avez réglé à GALAXICO
          restera intégralement acquis à GALAXICO et ne fera l’objet d’aucun remboursement total ou partiel, en votre
          faveur.{' '}
        </p>
        <p>
          L’Offre d’abonnement « OPTIMUM » avec paiement annuel est renouvelable tacitement en fin d’engagement, dans
          les mêmes conditions. Sans action de votre part, le renouvellement tacite sera effectué automatiquement à
          l’issue de la durée d’engagement minimum de 12 (douze) mois.
        </p>
        <p>GALAXICO vous notifiera le renouvellement dans le mois précédant sa date de fin d’engagement.</p>
        <p>
          Vous pouvez refuser le renouvellement tacite de l’Offre d’abonnement « OPTIMUM » avec paiement annuel dans le
          mois précédant sa date de fin d’engagement, sans frais de dossier, en cliquant sur l’option « Ne pas
          renouveler mon abonnement », présente dans « Mon compte {'>'} Mon Offre » .
        </p>
        <TextHr className='my-xl'>Article 10. Conséquences de la cessation de l’Offre</TextHr>
        <p>
          Lors de la cessation de votre Offre, pour quelque cause que ce soit, GALAXICO se réserve la possibilité de
          supprimer l’intégralité des données figurant dans votre Compte, sans être tenue de vous en transmettre
          préalablement une copie, totale comme partielle.
        </p>
        <p>
          En conséquence, GALAXICO attire votre attention sur l’utilité de procéder régulièrement à des sauvegardes ou
          copies des Rapports et tout autre élément pouvant figurer dans votre Compte pendant la durée de votre Offre et
          en vue de sa cessation.
        </p>
        <TextHr className='my-xl'>Article 11. Suspension d’accès au Site ou au Service</TextHr>
        <p>
          GALAXICO se réserve la possibilité de suspendre temporairement ou définitivement votre accès au Site et/ou au
          Service, sans préavis dans les cas suivants&nbsp;:
        </p>
        <ul>
          <li>En cas de maintenance et de mise à jour du Site ou du Service ;</li>
          <li>En cas de force majeure, au sens de l’article 1218 du Code civil ;</li>
          <li>Si GALAXICO soupçonne ou détecte tout logiciel malveillant lié à votre Compte ;</li>
          <li>
            Si GALAXICO soupçonne ou constate tout manquement par vous ou toute personne utilisant votre Compte aux CGV,
            aux Conditions Générales d’Utilisation du Site ou aux législations et réglementations en vigueur ou aux
            droits des tiers ;
          </li>
          <li>
            Si GALAXICO soupçonne ou constate que vous usurpez l’identité d’un autre utilisateur du Site ou que vous
            fournissez de fausses informations afin d’accéder ou utiliser le Service ;
          </li>
          <li>
            En cas de défaut de paiement de votre part d’une facture à l’échéance, après mise en demeure de payer
            adressée par GALAXICO par lettre recommandée avec accusé de réception demeurée infructueuse pendant un délai
            de huit jours.
          </li>
          <li>
            En cas d’invalidité de la carte bancaire utilisée pour le paiement de l’Offre de Service « Maestro »
            (abonnement avec engagement de 12 mois avec paiement en 12 mensualités), si ladite carte bancaire vient à
            échéance au cours des 12 mois et n’est pas remplacée par un autre moyen de paiement valide par le Client.
          </li>
          <li>
            En cas d’invalidité de la carte bancaire utilisée pour le paiement mensuel des Offres de Service « LIBERTY »
            et « MAESTRO » (abonnement avec ou sans engagement de 12 mois et avec paiement mensuel), si ladite carte
            bancaire vient à échéance durant la période de validité de l’Offre et n’est pas remplacée par un autre moyen
            de paiement valide par le Client.
          </li>
        </ul>
        <TextHr className='my-xl'>Article 12. Responsabilité de GALAXICO</TextHr>
        <h3 className='h2-uppercase'>1. Responsabilité relative au Site</h3>
        <p>
          GALAXICO s’engage à mettre en œuvre les moyens nécessaires afin d’assurer au mieux l’accès, la sécurité et le
          bon fonctionnement du Site ainsi que la fourniture du Service.
        </p>
        <p>
          Le Site étant accessible par Internet, le Client reconnait et accepte les caractéristiques, risques et limites
          d’Internet, qui peuvent limiter voire empêcher de manière temporaire l’accès au Site ; la responsabilité de
          GALAXICO ne saurait en aucun cas engagée en cas d’indisponibilité du Site causée par les serveurs de
          l’hébergeur du Site ou par vos serveurs.
        </p>
        <p>
          En dépit des soins et efforts qu'elle apporte au Site, GALAXICO ne garantit pas qu’il sera exempt d’anomalies
          ou d’erreurs, que celles-ci pourront être corrigées, que le Site fonctionnera sans interruption ou panne, que
          des dysfonctionnements imputables à des logiciels de tiers surviennent, ni encore que le Site soit compatible
          avec votre configuration ou votre matériel spécifique.
        </p>
        <p>
          Vous reconnaissez être seul responsable de l'utilisation que vous faites du Site et que GALAXICO décline toute
          responsabilité en cas&nbsp;:
        </p>
        <ul>
          <li>
            De dommage causé par la suspension ou l'interruption de l'accès au Site en raison d’un sinistre, problème
            sur la ligne, coupure d’électricité, maintenance ou mise à jour, virus informatique, intrusion par un tiers,
            fichiers corrompus, violation de données ou accès non autorisé ;
          </li>
          <li>
            De dommage de tout type, prévisible ou imprévisible (incluant la perte de données, de bénéfices, de chiffres
            d'affaires, d'activité, d’opportunité, d’économies, de profits, etc.) découlant de l’utilisation ou de
            l’impossibilité d’accéder totalement ou partiellement au Site.
          </li>
        </ul>
        <p>
          Vous vous engagez à prendre toutes les mesures destinées à protéger votre système d’information ainsi que
          votre réseau local contre tout type de menace informatique, notamment de la contamination par virus
          informatique.
        </p>
        <p>
          La responsabilité de GALAXICO ne saurait en aucun cas être engagée au titre des informations ou documents
          renseignés et intégrés dans le Compte par vous-même ou toute personne agissant pour votre compte.
        </p>
        <h3 className='h2-uppercase'>2. Responsabilité relative au Service</h3>
        <p className='outfit-bold'>
          GALAXICO attire votre attention sur le fait que les Rapports fournis sur le Site ne constituent en aucun cas
          une étude financière, un rapport d’expertise-comptable ou un audit, de quelque nature que ce soit (financier,
          comptable, commercial ou autre), mais uniquement des <u className='outfit-bold'>estimations</u>.
        </p>
        <p className='outfit-bold'>
          GALAXICO attire votre attention sur le fait que compte tenu du nombre, de la diversité et de la complexité des
          facteurs qui peuvent influencer la valeur d’un fonds de commerce, de titres de société ou de murs commerciaux,
          il est impossible d’en déterminer la valeur de manière exacte.{' '}
        </p>
        <p>
          Aussi, GALAXICO ne peut donner aucune garantie d’exactitude aux valorisations indiquées dans les Rapports,
          lesquelles sont simplement des estimations, ni aucune garantie quant à la vente du fonds de commerce, des
          titres de société ou des murs commerciaux aux prix indiqués à l’issue des Rapports.
        </p>
        <p>
          Vous reconnaissez que GALAXICO ne peut garantir la qualité, le résultat, l’efficacité ni la pertinence de
          toute décision ou action que vous pourriez entreprendre sur la base des Rapports réalisés par GALAXICO.
        </p>
        <p>
          En outre, les Rapports sont réalisés sur la base des informations que vous fournissez à GALAXICO et sont, en
          conséquence, tributaires de l’exactitude de ces informations, que GALAXICO n’est pas en mesure de vérifier ;
          en conséquence, la responsabilité de GALAXICO ne saurait en aucun cas être engagée dans le cas où vous lui
          fourniriez des informations inexactes, erronées ou incomplètes dans le cadre de la réalisation des Rapports.
        </p>
        <p>
          Enfin, les obligations souscrites par GALAXICO au titre de la réalisation du Service constituent une
          obligation de moyens.
        </p>
        <h3 className='h2-uppercase'>3. Limitation de responsabilité</h3>
        <p>
          La responsabilité de GALAXICO ne peut être engagée qu’en cas de faute ou de négligence prouvée et est limitée
          aux préjudices directs subis par vous, à l’exclusion de tout préjudice indirect, de quelque nature que ce
          soit, tel que notamment toute perte de chance, de clientèle, de résultat, d’exploitation, préjudice commercial
          ou perte de données et/ou de fichiers.
        </p>
        <p>
          En tout état de cause, au cas où la responsabilité de GALAXICO serait retenue, elle est expressément limitée
          au prix forfaitaire total de votre Offre de Service en cours de validité lors de la survenance de l’évènement
          mettant en jeu sa responsabilité.
        </p>
        <p>
          Si vous utilisez le Service gratuitement, la responsabilité de GALAXICO est expressément limitée à un montant
          forfaitaire global de 1 000 €.
        </p>
        <TextHr className='my-xl'>Article 13. Absence de droit de rétractation</TextHr>
        <p>
          L’article L. 221-28, 13° du Code de la consommation prévoit que le droit de rétractation ne peut être exercé
          pour les contrats de fourniture d'un contenu numérique non fourni sur un support matériel dont l'exécution a
          commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation.
        </p>
        <p>
          En conséquence, vous acceptez expressément que l’exécution de votre Offre intervienne avant la fin du délai de
          rétractation et renoncez, en conséquence, à l’exercice de votre droit de rétraction.
        </p>
        <p>
          La confirmation de votre accord en ce sens vous est fournie par GALAXICO dans l’e-mail de confirmation qui
          vous est adressé suite à la souscription à votre Offre.
        </p>
        <TextHr className='my-xl'>Article 14. Propriété intellectuelle</TextHr>
        <p>
          L’ensemble des éléments composant le Site et le Service (éléments textuels, graphiques, sonores,
          vidéographiques, logiciels ou de toute autre nature) est la propriété exclusive de GALAXICO.
        </p>
        <p>
          En conséquence, vous vous engagez à ne pas porter atteinte aux droits de propriété intellectuelle détenus par
          GALAXICO ou par des tiers ayant publié sur le Site du contenu protégé par un droit de propriété
          intellectuelle.{' '}
        </p>
        <TextHr className='my-xl'>Article 15. Réclamation – Médiation</TextHr>
        <p>En cas de réclamation, vous pouvez contacter GALAXICO aux coordonnées mentionnées en préambule.</p>
        <p>
          Si vous agissez en qualité de consommateur, et conformément aux dispositions des articles L. 611-1 et suivants
          du Code de la consommation, GALAXICO vous assure le droit de recourir gratuitement à un médiateur de la
          consommation en vue de la résolution amiable d’un litige qui vous opposerait à GALAXICO et qui n’aurait pas pu
          être résolu amiablement entre vous.
        </p>
        <p>
          Pour ce faire, GALAXICO adhère à l’Association Nationale des Médiateurs, dont les coordonnées sont les
          suivantes : 2 Rue de Colmar, 94300 Vincennes -{' '}
          <Link className='link' to='http://www.anm-conso.com' target='_blank'>
            www.anm-conso.com
          </Link>
          .
        </p>
        <p>
          Après avoir adressé une réclamation écrite directement à GALAXICO afin de tenter de régler amiablement le
          litige, l’Association Nationale des Médiateurs peut être saisie pour tout litige de consommation dont le
          règlement n’aurait pas abouti.
        </p>
        <p>
          Pour connaître les modalités de saisine de l’Association Nationale des Médiateurs, vous pouvez consulter le
          lien suivant&nbsp;:{' '}
          <Link className='link' to='https://www.anm-conso.com/site/particulier.php' target='_blank'>
            consulter
          </Link>
        </p>
        <p>
          Vous pouvez également consulter, sur{' '}
          <Link
            className='link'
            to='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR'
            target='_blank'>
            ce lien
          </Link>
          , la plateforme de Règlement en Ligne des Litiges mise en place par la Commission Européenne, destinée à
          faciliter le règlement indépendant par voie extrajudiciaire des litiges en ligne entre consommateurs et
          professionnels de l’Union européenne.
        </p>
        <p>
          Enfin, le site Internet de la médiation de la consommation accessible depuis le lien suivant{' '}
          <Link className='link' to='https://www.economie.gouv.fr/mediation-conso' target='_blank'>
            www.economie.gouv.fr/mediation-conso
          </Link>{' '}
          comporte des informations utiles sur la médiation des litiges de consommation.
        </p>
        <TextHr className='my-xl'>Article 16. Loi applicable – Compétence juridictionnelle</TextHr>
        <p>Les CGV et tous litiges survenant entre vous et GALAXICO sont soumis à la loi française.</p>
        <p>
          En cas de litige auquel aurait pu donner lieu toute opération conclue en application des CGV et qui ne serait
          pas amiablement résolu, les tribunaux français sont exclusivement compétents en application des règles
          édictées par le Code de procédure civile français.
        </p>
        <p>
          Si vous agissez en qualité de commerçant, tout litige auquel aurait pu donner lieu toute opération conclue en
          application des CGV et qui ne serait pas amiablement résolu sera de la compétence exclusive du{' '}
          <span className='outfit-bold'>Tribunal de commerce de PARIS (FRANCE)</span>, tant en référé qu'au fond, et ce
          nonobstant pluralité de parties ou appel en garantie.
        </p>
        <TextHr className='my-xl'>Article 17. Données personnelles</TextHr>
        <h3 className='h2-uppercase'>1. Données personnelles vous concernant</h3>
        <p>
          GALAXICO est susceptible de collecter certaines informations, et notamment des données à caractère personnel,
          vous concernant lors de votre accès au Site et de votre utilisation du Service.
        </p>
        <p>
          Ces informations font l’objet d’un traitement automatisé par GALAXICO dans les conditions décrites dans la{' '}
          <Link className='link' to='/politique-confidentialite' target='_blank'>
            Politique de Confidentialité
          </Link>{' '}
          du Site, que nous vous invitons à consulter.
        </p>
        <h3 className='h2-uppercase'>2. Données personnelles concernant des tiers</h3>
        <p>
          Vous reconnaissez que vous êtes seul responsable des conditions du traitement des données personnelles
          concernant des tiers que vous auriez fournis lors de votre utilisation du Service.
        </p>
        <p>
          A ce titre, vous vous engagez à respecter et vous portez fort du respect par tout utilisateur de votre Compte
          de l’ensemble des législations et réglementations applicables en matière de traitement de données
          personnelles.
        </p>
        <p>
          Vous vous engagez à garantir et relever indemne GALAXICO de toutes les conséquences pouvant découler du
          traitement de données personnelles que vous pourriez effectuer dans le cadre de votre utilisation du Service.
        </p>
        <TextHr className='my-xl'>Article 18. Dispositions diverses</TextHr>
        <h3 className='h2-uppercase'>1. Droit de cession</h3>
        <p>
          GALAXICO se réserve le droit de céder à des tiers, en tout ou partie, les droits et obligations issus des CGV,
          à condition de les céder dans les mêmes termes ou, à tout le moins, dans des termes qui ne soient pas moins
          avantageux pour vous.
        </p>
        <p>
          Cette cessibilité n’est pas réciproque de sorte que vous ne pouvez en aucun cas céder ou transférer, à quelque
          titre que ce soit, votre Offre de Service à un tiers.
        </p>
        <h3 className='h2-uppercase'>2. Modification</h3>
        <p>
          GALAXICO se réserve le droit de modifier à tout moment la teneur des CGV et vous notifiera les modifications
          ainsi effectuées par e-mail ou par tout autre moyen adéquat avant leur entrée en vigueur.
        </p>
        <p>
          L’activation de votre Compte postérieure à la notification de ces modifications sera considérée comme valant
          reconnaissance et acceptation de votre part des nouvelles CGV.
        </p>
        <h3 className='h2-uppercase'>3. Divisibilité</h3>
        <p>
          Si l'une quelconque des stipulations des CGV était déclarée nulle ou inapplicable en application d'une loi,
          d'un règlement ou à la suite d'une décision de justice devenu définitive, elle serait réputée non écrite et
          les autres stipulations resteraient en vigueur.
        </p>
        <h3 className='h2-uppercase'>4. Non-renonciation</h3>
        <p>
          Le fait que GALAXICO ne se prévale pas, à un moment ou à un autre, d’une des stipulations des CGV ne pourra
          être interprété comme valant renonciation à s’en prévaloir.
        </p>
      </div>
    </section>
  );
}

export default Cgv;
