import './Modal.css';
import React, { useEffect, useState } from 'react';

function Modal({ title, children, visible, setVisible, canHideOnClickOutside = false }) {
  const [behind, setBehind] = useState(!visible);

  function closeModal() {
    if (canHideOnClickOutside) setVisible(!visible);
  }

  useEffect(() => {
    if (visible) setBehind(false);
    else {
      setTimeout(() => {
        setBehind(true);
      }, 300);
    }
  });

  return (
    <section className={`${(visible ? '' : 'modal-hidden ') + (behind ? 'behind ' : '')}modal-container`}>
      <div className='modal-background' onClick={closeModal} />
      <div className='modal'>
        <div className='modal-header'>
          <p className='modal-title'>{title}</p>
        </div>
        <div className='modal-content'>{children}</div>
      </div>
    </section>
  );
}

export default Modal;
