import AppContext from '../../../context/AppContext';
import '../Error.css';
import React, { useContext, useEffect, useState } from 'react';

function ErrorPage({ code }) {
  const [errorData, setErrorData] = useState({
    image: 'redirect.svg',
    message: '',
  });

  const { setAppLoaderVisible } = useContext(AppContext);

  useEffect(() => {
    setAppLoaderVisible(false);

    switch (code) {
      case 301:
      case 302:
      case 402:
        setErrorData({
          image: 'redirect.svg',
          message: 'une erreur est survenue',
        });
        break;
      case 401:
        setErrorData({
          image: 'notFound.svg',
          message: "il semble que vous n'êtes pas authentifié",
        });
        break;
      case 403:
        setErrorData({
          image: 'forbidden.svg',
          message: 'il semble que vous ne pouvez pas accéder à cette page',
        });
        break;
      case 404:
        setErrorData({
          image: 'notFound.svg',
          message: 'La page que vous cherchez semble introuvable',
        });
        break;
      case 500:
      case 503:
        setErrorData({
          image: 'server.svg',
          message: 'il semble que nous rencontrons un problème',
        });
        break;
      case 504:
        setErrorData({
          image: 'server.svg',
          message: 'le serveur a mis trop de temps à répondre',
        });
        break;
    }
  }, [code]);

  return (
    <section className='container page-container'>
      <div className='error-container'>
        <img src={require(`../../../assets/errors/${errorData.image}`)} alt='TODO' />
        <div className='error-description'>
          <h1>OUPS</h1>
          <p>{errorData.message}</p>
          <p className='text-sky-blue'>
            Erreur
            {code}
          </p>
          <p>Pas de panique, vous pouvez toujours revenir à la page d'accueil</p>
        </div>
      </div>
    </section>
  );
}

export default ErrorPage;
