import axiosInstance from './AxiosConfig';

export const updateEstimationAPI = async estimation => axiosInstance.post('/estimation', estimation);

export const fetchEstimationApi = async estimationId => axiosInstance.get(`/estimation/${estimationId}`);

export const fetchUserEstimationsApi = async (userId, getSecondaryYears) =>
  axiosInstance.get(`/estimation/user/${userId}${getSecondaryYears ? '?related' : ''}`, { withCredentials: true });

export const addSecondaryYearApi = async (estimationId, year) =>
  axiosInstance.post('/estimation/add-year', { year, estimation_id: estimationId });

export const deleteSecondaryYearApi = async secondaryYearId => axiosInstance.delete(`/estimation/${secondaryYearId}`);

export const archiveEstimationApi = async (estimationId, shouldArchive) =>
  axiosInstance.put('/estimation/archive', {
    estimation_id: estimationId,
    archive: shouldArchive,
  });

export const duplicateEstimationApi = async (estimationId, enseigne) =>
  axiosInstance.post('/estimation/duplicate', { estimation_id: estimationId, enseigne });

export const fetchEstimationWithSecondaryYearsApi = async estimationId =>
  axiosInstance.get(`/estimation/get-years/${estimationId}`);

export const validateEstimationApi = async estimationId =>
  axiosInstance.put('/estimation/validate', { estimation_id: estimationId });

// DELETE RH row
export const deleteRhApi = async id => axiosInstance.delete(`/estimation/detail-rh/${id}`);
