import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Info from '../../../../atoms/icons/general/info/Info';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Select from '../../../../molecules/formComponents/select/Select';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import { max2500Validation } from '../../../../../utils/formValidation/FormValidation';
import { fetchDropdownValuesApi } from '../../../../../api/ParcoursApi';
import { getSelectValue } from '../../../../../utils/Utils';

function NormesAccessibilite({ id, index }) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    showTooltip,
    typesCommerce,
    moveToNextStep,
    getStepToGo,
    step,
    setStep,
    setGoToStep,
    submitFormsFdc,
    postValues,
    dependanceLocalCommercial,
    fillSelectsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const [dropdownsValues, setDropdownsValues] = useState({});

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillSelectsFromEstimationValues(
      'normes_accessibilite',
      ['accessibilite_pmr', 'installations_electriques', 'systeme_encaissement', 'extraction'],
      setValue,
      estimationValues,
    );
    fillSelectsFromEstimationValues(
      'materiel_agencement',
      ['etat_locaux', 'etat_materiel'],
      setValue,
      estimationValues,
    );
    setValue('note_step_11', estimationValues?.notes?.note_step_11);
  }, [estimationValues, dropdownsValues]);

  async function formSubmit(values) {
    postValues({
      normes_accessibilite: {
        accessibilite_pmr: {
          id: !dependanceLocalCommercial ? getSelectValue(values.accessibilite_pmr) : null,
        },
        installations_electriques: {
          id: !dependanceLocalCommercial ? getSelectValue(values.installations_electriques) : null,
        },
        systeme_encaissement: {
          id: !dependanceLocalCommercial ? getSelectValue(values.systeme_encaissement) : null,
        },
        extraction: {
          id: !dependanceLocalCommercial ? getSelectValue(values.extraction) : null,
        },
      },
      materiel_agencement: {
        etat_locaux: {
          id: !dependanceLocalCommercial ? getSelectValue(values.etat_locaux) : null,
        },
        etat_materiel: {
          id: getSelectValue(values.etat_materiel),
        },
      },
      notes: {
        note_step_11: values.note_step_11,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    const values = {};
    try {
      values.accessibilite_pmr = (await fetchDropdownValuesApi('accessibilite_pmr')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.installations_electriques = (await fetchDropdownValuesApi('installations_electriques')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.systeme_encaissement = (await fetchDropdownValuesApi('systeme_encaissement')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.extraction = (await fetchDropdownValuesApi('extraction')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.etat_locaux = (await fetchDropdownValuesApi('etat_locaux')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.etat_materiel = (await fetchDropdownValuesApi('etat_materiel')).data;
    } catch (error) {
      console.error(error);
    }

    setDropdownsValues(values);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      {!dependanceLocalCommercial && (
        <fieldset className='borderless-fieldset'>
          <h2>normes & accessibilité</h2>
          <Select
            label={
              <>
                accessibilité pmr
                <Info onClick={() => showTooltip('9bis_Conformite_PMR.webp')} />
              </>
            }
            register={register}
            error={errors?.accessibilite_pmr && errors.accessibilite_pmr.message}
            name='accessibilite_pmr'>
            {dropdownsValues?.accessibilite_pmr?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
          <div className='row-1000 mb-sm'>
            <Select
              label={
                <>
                  conformité des installations électriques
                  <Info onClick={() => showTooltip('9Conformiteelectrique.webp')} />
                </>
              }
              register={register}
              error={errors?.installations_electriques && errors.installations_electriques.message}
              name='installations_electriques'>
              {dropdownsValues?.installations_electriques?.map(value => (
                <option key={value.id} value={value.id}>
                  {value.description}
                </option>
              ))}
            </Select>
            <Select
              label={
                <>
                  conformité du système d’encaissement
                  <Info onClick={() => showTooltip('10Conformitesystemedencaissement.webp')} />
                </>
              }
              register={register}
              error={errors?.systeme_encaissement && errors.systeme_encaissement.message}
              name='systeme_encaissement'>
              {dropdownsValues?.systeme_encaissement?.map(value => (
                <option key={value.id} value={value.id}>
                  {value.description}
                </option>
              ))}
            </Select>
          </div>
          <Select
            visible={typesCommerce.includes(3)}
            label='extraction'
            register={register}
            error={errors?.extraction && errors.extraction.message}
            name='extraction'>
            {dropdownsValues?.extraction?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </fieldset>
      )}

      <fieldset className='borderless-fieldset'>
        <h2>matériel & agencements</h2>
        <div className='row-1000 mb-sm'>
          <Select
            visible={!dependanceLocalCommercial}
            className={!dependanceLocalCommercial ? '' : 'w-0'}
            label={
              <>
                etat des locaux
                <Info onClick={() => showTooltip('10bis_Etat_des_locaux.webp')} />
              </>
            }
            register={register}
            error={errors?.etat_locaux && errors.etat_locaux.message}
            name='etat_locaux'>
            {dropdownsValues?.etat_locaux?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
          <Select
            label={
              <>
                etat du matériel
                <Info onClick={() => showTooltip('10ter_Etat_du_materiel.webp')} />
              </>
            }
            register={register}
            error={errors?.etat_materiel && errors.etat_materiel.message}
            name='etat_materiel'>
            {dropdownsValues?.etat_materiel?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </div>
        <Textarea
          register={register}
          error={errors?.note_step_11 && errors.note_step_11.message}
          validation={max2500Validation}
          name='note_step_11'
          label='notes et remarques'
          className='mt-md'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default NormesAccessibilite;
