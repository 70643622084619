import React, { useEffect, useContext } from 'react';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import { useForm } from 'react-hook-form';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';

function SituationLocative({ index }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { step, moveToNextStep, setStep, submitFormsMc, postValues, getStepToGo, setGoToStep, estimationValues } =
    useContext(ParcoursContext);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    setValue('occupation_local', estimationValues?.situation_locative?.occupation_local?.id + '');
    setValue('note_step_3', estimationValues?.notes?.note_step_3);
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      situation_locative: {
        occupation_local: { id: values.occupation_local },
      },
      notes: {
        note_step_3: values.note_step_3,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }
  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
        <fieldset className='borderless-fieldset'>
          <h2>situation locative</h2>
          <Radio
            label='le local est actuellement :'
            register={register}
            name='occupation_local'
            error={errors?.occupation_local && errors.occupation_local.message}
            content={[
              {
                label: 'Occupé',
                value: 1,
              },
              {
                label: 'Vide',
                value: 2,
              },
            ]}
          />
          <Textarea
            register={register}
            name='note_step_3'
            className='mt-md'
            label='notes et remarques'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        </fieldset>
      </form>
    </>
  );
}

export default SituationLocative;
