import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../components/molecules/formComponents/input/Input';
import FilledButton from '../../../../components/molecules/buttons/filledButton/FilledButton';

function DebutParcours() {
  const to = useNavigate();
  const [estimationId, setEstimationId] = useState(null);

  return (
    <>
      <div>DebutParcours</div>
      <button onClick={() => to('/detail-parcours/fonds-de-commerce')}>Commencer</button>
      <div>chercher une estimation</div>
      <Input value={estimationId} onChange={e => setEstimationId(e.target.value)} />
      <FilledButton onClick={() => to(`/detail-parcours/fonds-de-commerce/${estimationId}`)}>Chercher</FilledButton>
    </>
  );
}

export default DebutParcours;
