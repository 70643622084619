import React, { useContext, useState } from 'react';
import './Commande.css';
import { useWindowSize } from '@uidotdev/usehooks';
import { Link } from 'react-router-dom';
import { useCart } from '../../../context/CartContext';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import Card from '../../../components/molecules/cards/card/Card';
import Hr from '../../../components/molecules/hr/Hr';
import Check from '../../../components/atoms/icons/general/check/Check';
import AppContext from '../../../context/AppContext';
import CommandeForm from '../../../components/forms/compte/commandeForm/CommandeForm';

function Commande() {
  const { cart } = useCart();
  const [detailsVisibility, setDetailsVisibility] = useState({});
  const { width } = useWindowSize();
  const { setModalContent, setModalVisible } = useContext(AppContext);

  function toggleDetails(index) {
    setDetailsVisibility(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  }

  return (
    <section className='container page-container'>
      <Breadcrumbs
        routes={[
          { name: 'Accueil', to: '/' },
          { name: 'Commande', to: '/commande' },
        ]}
      />
      <Banner title='Ma commande' subtitle='Vérifiez votre commande avant de finaliser votre achat' />
      <div className='recap-container'>
        <div className='recap-card-container'>
          <Card bgColor='var(--light-blue)' className='abonnement-card'>
            <h2>Récapitulatif de ma&nbsp;commande</h2>
            <Hr />
            <table className='recap-table'>
              <thead className={width <= 576 ? 'd-none' : ''}>
                <tr>
                  <th className='nom-offre-thead'>
                    Nom de
                    {width <= 576 ? <br /> : ''} l'offre
                  </th>
                  <th className='inclus-offre-thead'>
                    Inclus dans
                    {width <= 576 ? <br /> : ''} votre offre
                  </th>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <Hr />
                  </td>
                </tr>
              </thead>
              <tbody>
                {cart.map((item, index) => (
                  <>
                    <tr key={`item-${index}`}>
                      <td className='selection-offre-recap'>
                        <h3>{item.name}</h3>
                        {item.name === 'maestro' || item.name === 'optimum' ? (
                          <p className='engagement-abonnement-recap'>
                            Abonnement avec engagement&nbsp;
                            <br />
                            de 12 mois
                          </p>
                        ) : null}
                        <ul className={`mt-sm recap-inclus-offre ${width > 576 ? 'd-none' : ''}`}>
                          <li className='flex details-1-recap'>
                            <Check width='15px' className='check-min-width' />
                            Nombre illimité d'estimations avec rapport détaillé
                          </li>
                          <li className='flex details-1-recap'>
                            <Check width='15px' className='check-min-width' />
                            Accès aux ressources suivantes :
                          </li>
                          <ul className='ml-md dots-list details-2-recap'>
                            <li>Outils pratiques à télécharger</li>
                            <li>Conseils de pros</li>
                            <li>Nos guides pour agents immos</li>
                            <li>Plan de financement</li>
                            <li>Simulateur de crédit</li>
                            <li>Calculateur de cotisations</li>
                          </ul>
                        </ul>
                      </td>
                      <td className='inclus-offre-recap'>
                        <ul className={width <= 576 ? 'd-none' : ''}>
                          <li className='flex details-1-recap'>
                            <Check width='15px' className='check-min-width' />
                            Nombre illimité d'estimations avec rapport détaillé
                          </li>
                          <li className='flex details-1-recap'>
                            <Check width='15px' className='check-min-width' />
                            Accès aux ressources suivantes :
                          </li>
                          <ul className='ml-md dots-list details-2-recap'>
                            <li>Outils pratiques à télécharger</li>
                            <li>Conseils de pros</li>
                            <li>Nos guides pour agents immos</li>
                            <li>Plan de financement</li>
                            <li>Simulateur de crédit</li>
                            <li>Calculateur de cotisations</li>
                          </ul>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan='2'>
                        <Hr />
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
            <div className='checkbox-commande-container'>
              <CommandeForm />
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
}

export default Commande;
