import React from 'react';
import { useForm } from 'react-hook-form';
import Checkbox from '../../../molecules/formComponents/checkbox/Checkbox';
import { Link } from 'react-router-dom';
import { defaultValidation } from '../../../../utils/formValidation/FormValidation';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';

function CommandeForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmit(values) {
    //TODO
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='commande-form'>
      <Checkbox
        label={
          <p>
            En cochant cette case, je reconnais avoir pris connaissance et accepté pleinement les{' '}
            <Link to='/cgv'>Conditions Générales de Vente</Link> de GALAXICO, les{' '}
            <Link to='/cgu'>Conditions Générales d'utilisation</Link> et la{' '}
            <Link to='/politique-confidentialite'>Politique de Confidentialité du Site</Link>.&nbsp;*
          </p>
        }
        name='conditions_generales'
        register={register}
        validation={defaultValidation}
        error={errors?.conditions_generales && errors.conditions_generales.message}
      />
      <Checkbox
        label={
          <p>
            En cochant cette case, j’accepte expressément que la fourniture du contenu numérique puisse commencer
            immédiatement après l’envoi de l’e-mail de confirmation de GALAXICO et{' '}
            <span className='text-underline'>renonce donc expressément à mon droit de rétractation.</span>&nbsp;*
          </p>
        }
        name='droit_retractation'
        register={register}
        validation={defaultValidation}
        error={errors?.droit_retractation && errors.droit_retractation.message}
      />
      <Checkbox
        label={<p>Je m'inscris à la newsletter de EstimerMonCommerce.fr</p>}
        name='inscription_newsletter'
        register={register}
      />
      <div className='obligatoire-checkbox-form'>
        <p>* Obligatoire</p>
      </div>
      <div className='button-suivant-recap'>
        <FilledButton padding='10px 20px' fontSize='1.2rem' to='/panier'>
          Retour
        </FilledButton>
        <FilledButton padding='10px 20px' fontSize='1.2rem' type='submit'>
          Commander
        </FilledButton>
      </div>
    </form>
  );
}

export default CommandeForm;
