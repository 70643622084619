import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import InscriptionForm from '../../../components/forms/compte/inscriptionForm/InscriptionForm';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import AppContext from '../../../context/AppContext';

function Inscription() {
  return (
    <section className='container page-container'>
      <Breadcrumbs
        routes={[
          { name: 'Accueil', to: '/' },
          { name: 'Inscription', to: '/inscription' },
        ]}
      />
      <Banner title='Inscription' subtitle='Pas encore de compte ? Rejoignez-nous en remplissant ce formulaire' />
      <h2 className='h2-uppercase text-center'>Vous avez déjà un compte ?</h2>
      <div className='centered'>
        <FilledButton padding='10px 25px' to='/connexion' className='mb-sm'>
          Se connecter
        </FilledButton>
      </div>
      <InscriptionForm />
      <div className='centered mt-md'>
        <p className='mt-lg text-xs text-left'>
          Les informations recueillies à partir de ce formulaire font l’objet d’un traitement informatique destiné à
          GALAXICO aux fins de traitement de vos commandes, de gestion de votre compte client, d’études marketing et
          statistiques dans le but de vous fournir les offres les plus adaptées, de suivi de qualité de ses services et
          de prospection commerciale. Les données relatives à vos commandes seront conservées pendant une durée de dix
          ans à compter de la conclusion de la vente. Les autres données de votre compte client seront conservées
          pendant la durée d’utilisation de votre compte client et jusqu’à trois ans à compter de sa dernière
          utilisation. Les champs identifiés par un astérisque portent sur des informations obligatoires ; à défaut de
          les renseigner, votre demande de création d’un compte client ne pourra pas être traitée. Vous disposez sur ces
          informations nominatives vous concernant d’un droit d’accès, de rectification, de portabilité, d’effacement de
          celles-ci, de limitation et d’opposition à leur traitement ainsi que du droit de définir des directives
          générales et particulières définissant la manière dont vous entendez que soient exercés ces droits après votre
          décès. Vous pouvez exercer vos droits en vous adressant à GALAXICO par e-mail :{' '}
          <Link className='gold-link span-inherit' to='mailto:contact@estimermoncommerce.fr'>
            contact@estimermoncommerce.fr
          </Link>{' '}
          ou par courrier : 41 rue de Cronstadt 75015 PARIS. Pour plus d’informations sur vos droits, veuillez consulter
          notre{' '}
          <Link to='/politique-confidentialite' className='gold-link span-inherit'>
            Politique de Confidentialité
          </Link>
          . Vous avez la possibilité d’introduire une réclamation auprès de la CNIL.
        </p>
      </div>
    </section>
  );
}

export default Inscription;
