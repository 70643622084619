import axios from 'axios';
import html2canvas from 'html2canvas';
import { Fragment } from 'react';
import Check from '../components/atoms/icons/general/check/Check';

export function toggleCollapseElement(element, visible, bonusHeight = 0) {
  element.style.overflowY = 'hidden';
  if (!visible) {
    element.style.maxHeight = 0;
  } else {
    element.style.maxHeight = `${element.scrollHeight + bonusHeight}px`;
  }
}

export function toggleCollapseInput(element, visible, bonusHeight = 0) {
  if (!visible) {
    element.style.overflowY = 'hidden';
    element.style.maxHeight = 0;
  } else {
    const clone = element.cloneNode(true);
    clone.style.maxHeight = 'none';
    clone.style.maxWidth = `${element.getBoundingClientRect().width}px`;
    clone.style.position = 'absolute';
    clone.style.top = '-9999px';
    clone.style.left = '-9999px';
    document.body.appendChild(clone);
    element.style.maxHeight = `${clone.offsetHeight + bonusHeight}px`;
    document.body.removeChild(clone);
  }
}

export function getScrollbarWidth() {
  const test = document.createElement('div');
  test.style.visibility = 'hidden';
  test.style.width = '100vw';
  document.body.appendChild(test);

  const result = test.offsetWidth - document.body.offsetWidth;

  test.parentNode.removeChild(test);

  return result;
}

export function toInt(str) {
  return str ? Number(str) : 0;
}

export function formatNumberWithSpaces(str, allowZero = false) {
  if (str == 0 && allowZero) return '0';
  if (!str) return '';
  str += '';
  str = str.replace(/\s/g, '');
  str = str.replace(/[^0-9.,-]/g, '');
  str = str.replace(/\./g, ',');
  str = str[0] + str.slice(1).replace(/-/g, '');
  if (str === 'undefined') return '';

  const parts = str.split(',');
  let integerPart = parts[0] ? parts[0] : '';
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  const decimalPart = parts.length > 1 ? `,${parts[1]}` : '';
  if (integerPart === '' && decimalPart) integerPart = '0';
  while (integerPart[0] === '0' && integerPart[1] !== ',' && integerPart.length > 1) integerPart = integerPart.slice(1);
  return integerPart + decimalPart;
}

export function removeSpaces(str, allowNull = false) {
  if (str === undefined) return allowNull ? null : '';
  str += '';
  return Number(str.replace(/\s/g, '').replace(',', '.'));
}

export function getSelectValue(value) {
  return value === '_null' ? null : value;
}

export function removeSpacesFromEvent(e) {
  if (!e.target) return '';
  e.target.value = e.target.value.replace(/\s/g, '').replace(',', '.');
  return e;
}

export function getRandomString(length) {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function uploadImage(div, token, route, extraData, callback, imageName) {
  try {
    html2canvas(div).then(e => {
      axios
        .post(
          process.env.REACT_APP_API_URL + route,
          {
            image: e.toDataURL('image/jpeg', 0.9),
            image_name: `${imageName}_${getRandomString(10)}.jpeg`,
            ...extraData,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(e => {
          callback();
        });
    });
  } catch (e) {}
}

export function isStickyElementPinned(elementRef, topTreshold) {
  if (elementRef.current) {
    const rect = elementRef.current.getBoundingClientRect();
    if (rect.top <= topTreshold) return true;
    return false;
  }
  return false;
}

export function checkImage(file, maxSize) {
  if (!file) return '';

  const fileSize = file.size / 1048576;
  let errorMsg = '';

  if (
    file.type !== 'image/jpeg' &&
    file.type !== 'image/png' &&
    file.type !== 'image/jpg' &&
    file.type !== 'image/svg' &&
    file.type !== 'image/svg+xml'
  ) {
    errorMsg = `Votre image doit \xeatre au format .jpg, .jpeg, .png ou .svg ${
      file.type !== '' ? ` et non au format ${file.type}` : ''
    }`;
  } else if (fileSize > maxSize) errorMsg = `Votre image est trop lourde: ${fileSize.toFixed(2)}/${maxSize} Mb`;

  return errorMsg;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getPrettyText(text) {
  return text.replaceAll('&nbsp;', '\u00A0');
}

export function getFormattedDate(date) {
  if (!date) return '';
  date = date.split('-');

  return `${date[2]}/${date[1]}/${date[0]}`;
}

export function formatDate(date) {
  if (!date) return '';
  date = date.split('/');

  return `${date[2]}-${date[1]}-${date[0]}`;
}

export function getObfuscedEmail(email) {
  if (!email) return '';

  const [username, domain] = email.split('@');
  const obfuscatedUsername = username.substring(0, 3) + '*'.repeat(username.length - 3);
  return `${obfuscatedUsername}@${domain}`;
}

export function DateToString(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getPasswordErrorMessage(password) {
  if (password === undefined) return;

  const passwordErrorMessage = [
    { content: 'Votre mot de passe doit contenir au moins :', visible: true },
    { content: '• 8 caractères', visible: true },
    { content: '• 1 majuscule', visible: true },
    { content: '• 1 minuscule', visible: true },
    { content: '• 1 chiffre', visible: true },
    { content: '• 1 caractère spécial (@, !, ", %)', visible: true },
  ];

  passwordErrorMessage[1].visible = password.length < 8;
  passwordErrorMessage[2].visible = password.toLowerCase() === password || !password.length;
  passwordErrorMessage[3].visible = password.toUpperCase() === password || !password.length;
  passwordErrorMessage[4].visible = !/.*[0-9].*/.test(password);
  passwordErrorMessage[5].visible = !/.*[^\w\s].*/.test(password);

  return (
    <div className='password-tooltip-content'>
      {passwordErrorMessage.map((row, index) => (
        <Fragment key={index}>
          {index > 0 && <br />}
          <>
            {row.content}
            {!row.visible && index > 0 && <Check color='green' />}{' '}
          </>
        </Fragment>
      ))}
    </div>
  );
}
