import axiosInstance from './AxiosConfig';

// FAQ
export const fetchFaqQuestionsApi = async () => axiosInstance.get('/outils-faq/content');

// Lexique commercial
export const fetchLexiqueCommercialApi = async () => axiosInstance.get('/lexique-commercial/content');

// Outils pratiques
export const fetchAllOutilsPratiquesApi = async () => axiosInstance.get('/outils-pratiques/content');

export const fetchOutilPratiqueByUrlApi = async url =>
  axiosInstance.get(`/outils-pratiques/content?article_url=${url}`);

// Conseils de pros
export const fetchAllConseilsProsApi = async (offset = '0', limit = '30') =>
  axiosInstance.get(`/conseils-pro?limit=${limit}&offset=${offset}`);

export const fetchConseilProByUrlApi = async url => axiosInstance.get(`/conseils-pro/details?article_url=${url}`);

// Guides pour agents immos
export const fetchAllGuideAgentsImmosApi = async () => axiosInstance.get('/newsletter');

export const fetchGuideAgentsImmosByUrlApi = async url => axiosInstance.get(`/newsletter?newsletter_url=${url}`);

// Liste des activités
export const fetchAllActivitiesApi = async () => axiosInstance.get('/liste-activite');
