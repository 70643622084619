import React, { useEffect, useContext, useState } from 'react';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import { useForm } from 'react-hook-form';
import { numberValidation } from '../../../../../utils/formValidation/FormValidation';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { removeSpaces } from '../../../../../utils/Utils.js';

function SituationLocative({ index }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const Form = { register, setValue, watch };
  const { step, moveToNextStep, setStep, submitFormsMc, postValues, getStepToGo, setGoToStep, estimationValues } =
    useContext(ParcoursContext);
  const [situationLocative, setSituationLocative] = useState(false);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    setValue('ca_dernier_occupant', estimationValues?.ca_dernier_occupant + '');
    setValue('note_step_4', estimationValues?.notes?.note_step_4);

    setSituationLocative(estimationValues?.situation_locative?.occupation_local?.id === 1 ? true : false);
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({
      ca_dernier_occupant: removeSpaces(values.ca_dernier_occupant),
      notes: {
        note_step_4: values.note_step_4,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
        <fieldset className='borderless-fieldset'>
          <h2>
            {situationLocative
              ? "chiffre d'affaires de l'occupant"
              : "chiffre d'affaires du dernier occupant (ou ca previsionnel)"}
          </h2>
          <NumberInput
            useForm={Form}
            label={
              situationLocative
                ? "veuillez renseigner le chiffre d'affaires annuel ht réalisé par l'exploitant"
                : "veuillez renseigner le chiffre d'affaires annuel ht du dernier occupant (le cas échéant un ca prévisionnel réaliste)"
            }
            name='ca_dernier_occupant'
            icon='euro'
            validation={numberValidation}
            error={errors?.ca_dernier_occupant && errors.ca_dernier_occupant.message}
          />
          <Textarea
            register={register}
            name='note_step_4'
            className='mt-md'
            label='notes et remarques'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        </fieldset>
      </form>
    </>
  );
}

export default SituationLocative;
