import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import worker from '../../../assets/errors/worker.svg';
import '../Error.css';
import React from 'react';

function DevelopmentPage() {
  return (
    <section className='container page-container'>
      <div className='error-container'>
        <img src={worker} alt='TODO' />
        <div className='error-description'>
          <h1>PATIENCE</h1>
          <p>Cette page est en cours de développement</p>
          <p>Pas de panique, vous pouvez toujours revenir à la page d'accueil</p>
        </div>
      </div>
    </section>
  );
}

export default DevelopmentPage;
