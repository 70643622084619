import React, { Fragment, useEffect, useRef, useState } from 'react';
import { getPasswordErrorMessage, toggleCollapseElement, toggleCollapseInput } from '../../../../utils/Utils';
import Eye from '../../../atoms/icons/general/eye/Eye';
import Warning from '../../../atoms/icons/general/warning/Warning';
import './PasswordInput.css';
import {
  confirmPasswordvalidation,
  defaultValidation,
  passwordValidation,
} from '../../../../utils/formValidation/FormValidation';
import HiddenEye from '../../../atoms/icons/general/hiddenEye/HiddenEye';

function PasswordInput({
  label,
  name,
  register,
  error,
  visible,
  bgColor,
  onChange,
  onFocus,
  onBlur,
  value,
  onKeyDown,
  disabled,
  className = '',
  isPasswordConfirm = false,
  showTooltip = false,
  watch,
}) {
  const inputRegister = register && (visible || visible === undefined) ? register(name, getValidation()) : null;
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: <></>,
  });
  const listener = watch ? watch(name) : null;
  const [inputType, setInputType] = useState(0); // 0 : password / 1 : text

  const containerRef = useRef(null);
  const passwordTooltipRef = useRef(null);

  useEffect(() => {
    showTooltip && toggleCollapseElement(passwordTooltipRef.current, false);
  }, []);

  useEffect(() => {
    fillTooltipContent();
  }, [listener, value]);

  useEffect(() => {
    showTooltip && passwordTooltipRef.current && toggleCollapseElement(passwordTooltipRef.current, tooltip.visible, 15);
  }, [tooltip]);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseInput(containerRef.current, visible);
  }, [visible]);

  function getValidation() {
    if (showTooltip) return passwordValidation;
    if (isPasswordConfirm) return confirmPasswordvalidation;
    return defaultValidation;
  }

  function fillTooltipContent() {
    if (listener === undefined && listener === null && value === undefined) return;

    showTooltip &&
      setTooltip({
        visible: tooltip.visible,
        content: getPasswordErrorMessage(listener !== undefined && listener !== null ? listener : value),
      });
  }

  function toggleInputType() {
    setInputType(!inputType);
  }

  return (
    <div className={`w-100 d-flex ${className}`} ref={containerRef}>
      <div style={{ '--bg-color': bgColor }} className={`field-container ${bgColor ? 'filled-input' : ''}`}>
        <label className='form-label' htmlFor={name}>
          {label}
        </label>
        <span className={`input-container ${error ? ' with-error' : ''}`}>
          {showTooltip && (
            <div className='password-tooltip-container' ref={passwordTooltipRef}>
              <div className='password-tooltip'>{tooltip.content}</div>
              <div className='password-tooltip-arrow' />
            </div>
          )}
          {inputType ? (
            <HiddenEye color='var(--dark-blue)' className='password-input-eye' onClick={toggleInputType} />
          ) : (
            <Eye color='var(--dark-blue)' className='password-input-eye' onClick={toggleInputType} />
          )}
          <input
            autoComplete='off'
            disabled={(visible !== undefined && !visible) || disabled}
            id={name}
            type={inputType ? 'text' : 'password'}
            {...inputRegister}
            onChange={e => {
              inputRegister && inputRegister.onChange(e);
              onChange && onChange(e);
            }}
            onFocus={e => {
              fillTooltipContent();
              setTooltip({ visible: true, content: tooltip.content });
              onFocus && onFocus(e);
            }}
            onBlur={e => {
              setTooltip({ visible: false, content: tooltip.content });
              onBlur && onBlur(e);
            }}
            onKeyDown={onKeyDown}
          />
          {error && (
            <div className='form-error'>
              <Warning /> <p>{error}</p>
            </div>
          )}
        </span>
      </div>
    </div>
  );
}

export default PasswordInput;
