import Check from '../../../components/atoms/icons/general/check/Check';
import './Home.css';
import './Guideline.css';
import React, { useEffect, useState } from 'react';
import RoundedDiv from '../../../components/atoms/icons/general/roundedDiv/RoundedDiv';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import hotelsCommerces from '../../../assets/homepage/hotelsCommerces.svg';
import conseilsAccompagnateurs from '../../../assets/homepage/conseilsAccompagnateurs.svg';
import partenairesExploitants from '../../../assets/homepage/partenairesExploitants.svg';
import DotsColumn from '../../../components/atoms/homepage/icons/dotsColumn/DotsColumn';
import GoldStar from '../../../components/atoms/homepage/icons/glodStar/GoldStar';
import GreyStar from '../../../components/atoms/homepage/icons/greyStar/GreyStar';
import Card from '../../../components/molecules/cards/card/Card';
import HalfStar from '../../../components/atoms/homepage/icons/halfStar/HalfStar';
import ReviewsRow from '../../../components/organisms/reviewsRow/ReviewsRow';
import HomepageTextImg from '../../../components/molecules/homepageTextImg/HomepageTextImg';
import valorisationAffaire from '../../../assets/homepage/valorisationAffaireRevert.svg';
import fonctionnementEstimations from '../../../assets/homepage/fonctionnementEstimations.svg';
import rapportDetaille from '../../../assets/methode/rapportDetaille.svg';
import confidentialite from '../../../assets/methode/confidentialite.svg';
import toolsWoman from '../../../assets/homepage/toolsWoman.svg';
import toolsComputer from '../../../assets/homepage/toolsComputer.svg';
import manIdea from '../../../assets/homepage/manIdea.svg';
import lights from '../../../assets/homepage/lights.svg';
import rocketWoman from '../../../assets/homepage/rocketWoman.svg';
import workingWoman from '../../../assets/homepage/workingWoman.svg';
import conseils from '../../../assets/homepage/conseils.svg';
import HomepageCard from '../../../components/molecules/homepageCard/HomepageCard';
import GuidelineTitle from '../../../components/molecules/guidelineTitle/GuidelineTitle';
import Stopwatch from '../../../components/atoms/homepage/icons/guideline/stopwatch/Stopwatch';
import HandStar from '../../../components/atoms/homepage/icons/guideline/handStar/HandStar';
import Suitcase from '../../../components/atoms/homepage/icons/guideline/suitcase/Suitcase';
import Balance from '../../../components/atoms/homepage/icons/guideline/balance/Balance';
import Light from '../../../components/atoms/homepage/icons/guideline/light/Light';
import Puzzle from '../../../components/atoms/homepage/icons/guideline/puzzle/Puzzle';
import InteractiveFinger from '../../../components/atoms/homepage/icons/guideline/interactiveFinger/InteractiveFinger';
import Rocket from '../../../components/atoms/homepage/icons/guideline/rocket/Rocket';
import Medal from '../../../components/atoms/homepage/icons/guideline/medal/Medal';
import Gear from '../../../components/atoms/homepage/icons/guideline/gear/Gear';
import Watcher from '../../../components/atoms/homepage/icons/guideline/watcher/Watcher';
import Cube from '../../../components/atoms/homepage/icons/guideline/cube/Cube';
import GuidelineStep from '../../../components/molecules/guidelineStep/GuidelineStep';
import RapportsRow from '../../../components/organisms/rapportsRow/RapportsRow';
import { Link } from 'react-router-dom';
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';

function Home() {
  const [usersReviews, setUsersReviews] = useState([]);
  const [globalRate, setGlobalRate] = useState(5.0);

  const { width } = useWindowSize();
  const [pageScroll] = useWindowScroll();

  useEffect(() => {
    fetchUsersReviews();
  }, []);

  async function fetchUsersReviews() {
    const reviews = [
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'utilise l'outil régulièrement pour ma profession, je le trouve fluide, ergonomique, efficace, le bilan est très pro, cela fait son effet à chaque rendu client. Incomparable avec ce qui existe sur le marché. Je recommande à 100%.",
      },
      {
        username: 'Frank G.',
        rate: 3,
        review: 'Je recommande',
      },
      {
        username: 'Frank G.',
        rate: 0,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 4,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'utilise l'outil régulièrement pour ma profession, je le trouve fluide, ergonomique, efficace, le bilan est très pro, cela fait son effet à chaque rendu client. Incomparable avec ce qui existe sur le marché. Je recommande à 100%.",
      },
      {
        username: 'Frank G.',
        rate: 3,
        review: 'Je recommande',
      },
      {
        username: 'Frank G.',
        rate: 0,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 4,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'utilise l'outil régulièrement pour ma profession, je le trouve fluide, ergonomique, efficace, le bilan est très pro, cela fait son effet à chaque rendu client. Incomparable avec ce qui existe sur le marché. Je recommande à 100%.",
      },
      {
        username: 'Frank G.',
        rate: 3,
        review: 'Je recommande',
      },
      {
        username: 'Frank G.',
        rate: 0,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 4,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
      {
        username: 'Frank G.',
        rate: 5,
        review:
          "J'ai déjà eu l'occasion de réaliser quelques estimation avec estimermoncommerce, les rapports sont clairs et permettent une argumentation très efficace auprès des vendeurs, mais aussi pour convaincre un repreneur. De plus le support est extrêmement efficace et réactif pour répondre à la moindre question. Pour moi c'est l'outil indispensable pour vendre un commerce.",
      },
    ];

    const globalRate = '4.5';

    setUsersReviews(reviews);
    setGlobalRate(globalRate);
  }

  return (
    <div className='homepage-container page-container'>
      <section className='landing-container'>
        <div className='container'>
          <img
            src={hotelsCommerces}
            alt='Hotels Commerces'
            className={`landing-image ${pageScroll.y > 250 ? 'd-none' : ''}`}
          />
          <div className='landing-page-fade' />
          <h1 className='homepage-h1'>
            Estimez en ligne
            <br />
            <span className='text-light-gold'> la valeur d'une affaire</span>
          </h1>
          <div className='landing-page-description'>
            FONDS DE COMMERCE
            <div />
            TITRES DE SOCIÉTÉ
            <div />
            MURS COMMERCIAUX
          </div>
          <FilledButton
            className='landing-button'
            bgColor='var(--light-gold)'
            fontSize={width > 1200 ? '1.5rem' : '1.3rem'}
            color='var(--dark-blue)'
            hoverColor='var(--light-gold)'
            padding={width > 1200 ? '10px 20px' : '8px 15px'}
            to='/debut-parcours'>
            Je démarre mon estimation
          </FilledButton>
          <div className='landing-page-description-2'>
            Simple, rapide et confidentiel
            <div />
            Plus de 300 activités commerciales
            <div />
            Rapport d'estimation professionnelle instantané
          </div>
          {width > 1200 ? (
            <></>
          ) : (
            <div className='landing-reviews-container'>
              <div>
                {globalRate >= 1 ? <GoldStar /> : globalRate > 0 ? <HalfStar /> : <GreyStar />}
                {globalRate >= 2 ? <GoldStar /> : globalRate > 1 ? <HalfStar /> : <GreyStar />}
                {globalRate >= 3 ? <GoldStar /> : globalRate > 2 ? <HalfStar /> : <GreyStar />}
                {globalRate >= 4 ? <GoldStar /> : globalRate > 3 ? <HalfStar /> : <GreyStar />}
                {globalRate >= 5 ? <GoldStar /> : globalRate > 4 ? <HalfStar /> : <GreyStar />}
              </div>
              <Link
                href='https://www.google.com/search?q=EstimerMonCommerce.fr&stick=H4sIAAAAAAAA_-NgU1I1qDAyMzNPMTUxMzNIskxMTTW0MqhIsTCwSEs2S00ySzE1SjFLWsQq6lpckpmbWuSbn-ecnwtkJKfqpRUBAJ7ytONBAAAA&hl=fr&mat=CT4ypzhfQhnOElYB8pgkaK2f6By5zRYj0J3HlPWsMg7y7N8Q3Dmf4LKvMUIjGSGriEd3L8m9B2idLk7rK631UgyJGmZ3m2iGew-MB7maoPiLm9rIeGgWnNwbNoCRREjVxg&authuser=0#lrd=0x2667d54660b9aee1:0xd808fc6eb6d52d6b,1,,,'
                target='_blank'
                rel='noreferrer'>
                Avis & support clients
              </Link>
            </div>
          )}
        </div>
      </section>
      <RoundedDiv
        color='var(--dark-blue)'
        rotation='180deg'
        height={width > 1200 ? '3.1vw' : '50px'}
        radius={width > 1200 ? '900vw' : '600vw'}
      />
      <section className='homepage-section-2 container'>
        <div>
          <div className='homepage-section-2-text-container'>
            <img src={conseilsAccompagnateurs} alt='TODO' />
            <p className='outfit-bold'>
              Conseils et accompagnateurs
              <br />
              de cédants & repreneurs
            </p>
            <ul>
              <DotsColumn className='homepage-dots-column' />
              <li>Agents & mandataires immobiliers</li>
              <li>Cabinets de cession</li>
              <li>Experts-comptables</li>
              <li>Conseillers en pilotage d’entreprise</li>
              <li>Juristes</li>
            </ul>
            <p className='outfit-semibold'>
              Evaluez les affaires de vos clients, gagnez en efficacité et <br />
              renforcez votre action
            </p>
          </div>
          <div className='homepage-section-2-text-container'>
            <img src={partenairesExploitants} alt='TODO' />
            <p className='outfit-bold'>
              Partenaires d’exploitants,
              <br />
              de cédants & repreneurs
            </p>
            <ul>
              <DotsColumn className='homepage-dots-column' />
              <li>Courtiers en financement</li>
              <li>Banquiers</li>
              <li>Franchiseurs</li>
              <li>Assureurs</li>
              <li>Fédérations</li>
            </ul>
            <p className='outfit-semibold'>
              Evaluez les affaires de vos interlocuteurs pour calibrer <br />
              votre intervention
            </p>
          </div>
        </div>
        <FilledButton className='mid-page-button' to='/debut-parcours'>
          Je démarre mon estimation
        </FilledButton>
      </section>
      <section className='homepage-section-3'>
        <h2>Ce que vous dites de nous</h2>
        <Card padding='20px 40px' bgColor='var(--beige)' className='global-rate-card'>
          <p>Avis & supports clients</p>
          <div className='global-rate'>{String(globalRate).replace('.', ',')}</div>
          <div>
            {globalRate >= 1 ? <GoldStar /> : globalRate > 0 ? <HalfStar /> : <GreyStar />}
            {globalRate >= 2 ? <GoldStar /> : globalRate > 1 ? <HalfStar /> : <GreyStar />}
            {globalRate >= 3 ? <GoldStar /> : globalRate > 2 ? <HalfStar /> : <GreyStar />}
            {globalRate >= 4 ? <GoldStar /> : globalRate > 3 ? <HalfStar /> : <GreyStar />}
            {globalRate >= 5 ? <GoldStar /> : globalRate > 4 ? <HalfStar /> : <GreyStar />}
          </div>
        </Card>
        <ReviewsRow reviews={usersReviews} />
        <FilledButton
          className='mid-page-button'
          target='_blank'
          rel='norefferer'
          to='https://www.google.com/search?q=EstimerMonCommerce.fr&stick=H4sIAAAAAAAA_-NgU1I1qDAyMzNPMTUxMzNIskxMTTW0MqhIsTCwSEs2S00ySzE1SjFLWsQq6lpckpmbWuSbn-ecnwtkJKfqpRUBAJ7ytONBAAAA&hl=fr&mat=CT4ypzhfQhnOElYB8pgkaK2f6By5zRYj0J3HlPWsMg7y7N8Q3Dmf4LKvMUIjGSGriEd3L8m9B2idLk7rK631UgyJGmZ3m2iGew-MB7maoPiLm9rIeGgWnNwbNoCRREjVxg&authuser=0#lrd=0x2667d54660b9aee1:0xd808fc6eb6d52d6b,1,,,'>
          Voir tous les avis
        </FilledButton>
      </section>
      <section className='homepage-section-4 container'>
        <h2>
          Pourquoi estimer en ligne
          <br /> avec nous ?
        </h2>
        <HomepageTextImg
          text1="La valorisation d'une affaire ne se résume pas à des données comptables"
          text2='Votre estimation intègre de nombreux facteurs essentiels de l’exploitation et appréhende ses spécificités'
          img={valorisationAffaire}
          imgAlt='TODO'
        />
        <HomepageTextImg
          text1='Vous avez besoin de comprendre tous les éléments de votre estimation'
          text2="Votre rapport d'estimation professionnelle est détaillé, facilement compréhensible et pleinement exploitable"
          img={rapportDetaille}
          imgAlt='TODO'
        />
        <HomepageTextImg
          text1='Vous avez une préoccupation centrale, la confidentialité de votre démarche'
          text2='Vous ne communiquez aucun document et votre accès personnel sécurisé offre une discrétion totale'
          img={confidentialite}
          imgAlt='TODO'
        />
        <HomepageTextImg
          text1='Vous voulez des conseils pour anticiper une cession, un achat ou optimiser une affaire'
          text2='Nous partageons avec vous le meilleur de 15 ans de pratique sous forme d’outils, méthodes et conseils de professionnels'
          img={conseils}
          imgAlt='TODO'
        />
        <FilledButton className='mid-page-button' to='/debut-parcours'>
          Démarrer mon estimation
        </FilledButton>
      </section>
      <section className='homepage-section-5 container'>
        <h2>Comment ça marche ?</h2>
        <img src={fonctionnementEstimations} alt='TODO' className='fonctionnement-estimation-img' />
        <div className='homepage-cards-container'>
          <HomepageCard
            number='1'
            title={
              <>
                J’effectue un rapide <br />
                parcours en ligne
              </>
            }
            text="En renseignant les données-clefs de l'exploitation. Pas besoin d’être un pro de la compta, je suis aidé(e) par de courts tutos en cas de doute."
          />
          <HomepageCard
            number='2'
            title={
              <>
                J’obtiens immédiatement
                <br /> mon rapport
              </>
            }
            text="Détaillé, il analyse forces et faiblesses de l'affaire, ratios, et spécifie différentes fourchettes d'évaluation me permettant d'ajuster ma valeur."
          />
        </div>
        <FilledButton className='mid-page-button' to='/debut-parcours'>
          Démarrer le parcours d'estimation
        </FilledButton>
      </section>
      <section className='homepage-section-6 container'>
        <h2 className='mb-lg'>Que vous apportons-nous ?</h2>
        <p className='text-lg text-center'>
          Une estimation professionnelle <span className='span-inherit outfit-bold'>fiable</span>, <br />
          basée sur l’analyse de <span className='outfit-bold span-inherit'>toutes les facettes</span> de
          l’exploitation.
          <br />
          Des <span className='outfit-bold span-inherit'>guides et outils</span> pour conduire vos cessions de A à Z.
        </p>
        <div className='guideline-container'>
          <div className='guideline-1 guideline-line'>
            <GuidelineTitle
              number='1'
              title="L'Estimation"
              style={{ width: 'fit-content', right: 'unset', left: width > 576 ? '-42px' : '-22px' }}
            />
            <GuidelineStep Icon={Stopwatch} text='Rapide' color='#2c1443' />
            <GuidelineStep Icon={HandStar} text='Fiable' color='#40283e' />
            <GuidelineStep Icon={Suitcase} text='Professionnelle' color='#543c3a' />
            <GuidelineStep Icon={Balance} text='Neutre' color='#695136' />
          </div>
          <div className='guideline-side ml-auto'>
            <img src={workingWoman} alt='TODO' className='guideline-img' />
            <div className='guideline-button-container'>
              <FilledButton className='mid-page-button' to='/debut-parcours'>
                Démarrer mon estimation
              </FilledButton>
            </div>
          </div>
          <div className='guideline-side mr-auto'>
            <img src={rapportDetaille} alt='TODO' className='guideline-img' />

            <div className='guideline-button-container align-left'>
              <FilledButton className='mid-page-button' to='/exemples-rapports'>
                Voir les exemples de rapports
              </FilledButton>
            </div>
          </div>
          <div className='guideline-2 guideline-line'>
            <GuidelineTitle number='2' title='Le Rapport Détaillé' color='var(--dark-gold)' />
            <GuidelineStep alignRight Icon={Light} text='Explicite' color='#695136' />
            <GuidelineStep alignRight Icon={Puzzle} text='Complet' color='#543c3a' />
            <GuidelineStep alignRight Icon={InteractiveFinger} text='Pratique' color='#40283e' />
            <GuidelineStep alignRight Icon={Rocket} text='Innovant' color='#2c1443' />
          </div>
          <div className='guideline-3 guideline-line'>
            <GuidelineTitle
              number='3'
              title='Les Outils & Conseils'
              style={{ right: 'unset', left: '0' }}
              color='var(--darker-blue)'
            />
            <GuidelineStep Icon={Medal} text='Spécialisés' color='#2c1443' />
            <GuidelineStep Icon={Gear} text='Fonctionnels' color='#40283e' />
            <GuidelineStep Icon={Watcher} text='Intuitifs' color='#543c3a' />
            <GuidelineStep Icon={Cube} text='Réalistes' color='#695136' />
          </div>
          <div className='guideline-side ml-auto'>
            <img src={toolsWoman} alt='TODO' className='guideline-img' />

            <div className='guideline-button-container'>
              <FilledButton className='mid-page-button' to='/TODO'>
                Explorer les ressources
              </FilledButton>
            </div>
          </div>
          <div className='guideline-4 guideline-line'>
            <GuidelineTitle title='OUTILS PRATIQUES' color='var(--dark-gold)' />
            <div className='guideline-text-container'>
              <div>
                <p>
                  Téléchargez nos outils exclusifs pour vous aider à{width > 992 && <br />} transmettre, acquérir ou
                  optimiser une affaire
                </p>

                <FilledButton className='mid-page-button' to='/outils-pratiques'>
                  Ouvrir la boîte à outils
                </FilledButton>
              </div>
              <img src={toolsComputer} alt='TODO' className='guideline-img' />
            </div>
          </div>
          <div className='guideline-5 guideline-line'>
            <GuidelineTitle title='CONSEILS DE PROS' color='var(--darker-blue)' style={{ right: 'unset', left: '0' }} />
            <div className='guideline-text-container'>
              <img src={manIdea} alt='TODO' className='guideline-img' />
              <div>
                <p className='text-right'>
                  Cession, acquisition, optimisation d'affaire : astuces,
                  {width > 992 && <br />} pièges à éviter, points essentiels, parcours et étapes à anticiper
                </p>
                <FilledButton className='mid-page-button ml-auto' to='/conseils-pros'>
                  Découvrir nos conseils de pros
                </FilledButton>
              </div>
            </div>
            <div className='guideline-end' />
          </div>
        </div>
        <img src={lights} alt='TODO' className='lights-img' />
      </section>
      <section className='homepage-section-7'>
        <h2 className='mb-lg'>Exemples de rapports</h2>
        <p className='text-lg text-center'>
          Découvrez le contenu de nos rapports d'estimation détaillés
          <br />
          Pensés pour une exploitabilité maximum
        </p>
        <RapportsRow />
      </section>
      <section className='homepage-section-8 container'>
        <h2>L'innovation à votre service</h2>
        <div className='homepage-bottom-div-container'>
          <img src={rocketWoman} alt='TODO' className='innovation-img' />
          <p>
            <span className='span-inherit'>
              1er site d'estimation d'affaire doté d'une analyse opérationnelle globale.{' '}
            </span>
            <span className='text-orange span-inherit outfit-bold'>
              EstimerMonCommerce.fr permet d'évaluer commerces, entreprises & murs grâce à une méthode rapide, fiable et
              professionnelle.
            </span>
            <span className='span-inherit'>
              EstimerMonCommerce.fr vient du terrain, pour les pros qui accompagnent les exploitants, cédants et
              repreneurs d’affaires.
            </span>
          </p>
        </div>
        <FilledButton className='mid-page-button' to='/debut-parcours'>
          Démarrer mon estimation
        </FilledButton>
      </section>
    </div>
  );
}

export default Home;
