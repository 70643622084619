import { useNavigate, useParams, Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import './OutilPratiqueDetail.css';
import React, { useContext, useEffect, useState } from 'react';
import PageTitle from '../../../components/molecules/pageTitle/PageTitle';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import Download from '../../../components/atoms/icons/general/download/Download';
import InfiniteThumbnailsRow from '../../../components/organisms/infiniteThumbnailsRow/InfiniteThumbnailsRow';
import AppContext from '../../../context/AppContext';
import { useWindowSize } from '@uidotdev/usehooks';
import ArrowSm from '../../../components/atoms/icons/general/arrowSm/ArrowSm';
import Banner from '../../../components/molecules/banner/Banner';
import { fetchAllConseilsProsApi, fetchOutilPratiqueByUrlApi } from '../../../api/OutilsApi';
import { getPrettyText } from '../../../utils/Utils';

function OutilPratiqueDetail() {
  const [outil, setOutil] = useState({});
  const [suggestions, setSuggestions] = useState([]);

  const { setModalContent, setModalVisible } = useContext(AppContext);
  const { width } = useWindowSize();
  const { outilUrl } = useParams();
  const { setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();

  const userSub = false; // TODO

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchAllData();
  }, []);

  async function fetchAllData() {
    await fetchOutil();
    await fetchSuggestions();

    setAppLoaderVisible(false);
  }

  async function fetchOutil() {
    try {
      const outil = (await fetchOutilPratiqueByUrlApi(outilUrl.toLowerCase())).data[0];

      if (!outil) return navigate('/404');

      outil.file_name = `${process.env.REACT_APP_API_URL}/assets/outilsPratiques/${outil.article_url}/file/${outil.file_name}`;
      outil.img_ex = `${process.env.REACT_APP_API_URL}/assets/outilsPratiques/${outil.article_url}/preview/${outil.img_ex}`;
      outil.img_alt = 'TODO';

      setOutil(outil);
    } catch (error) {
      navigate('/404');
    }
  }

  async function fetchSuggestions() {
    try {
      let suggestions = (await fetchAllConseilsProsApi()).data;

      suggestions = suggestions
        .sort(() => Math.random() - 0.5)
        .slice(0, 10)
        .map(suggestion => ({
          title: getPrettyText(suggestion.title),
          tag: getPrettyText(suggestion.tag),
          picture: `process.env.REACT_APP_API_URL/assets/ConseilsPros/${suggestion.url}/miniature/${suggestion.picture}`,
          link: `/conseils-pros/${suggestion.url}`,
          linkText: "Lire l'article",
        }));

      setSuggestions(suggestions);
    } catch (error) {
      console.log(error);
    }
  }

  function showSubModal() {
    setModalContent({
      title: outil.name,
      content: <p>Profitez de tous nos outils pratiques avec l'offre la mieux adaptée à vos besoins</p>,
      buttons: (
        <a className='button filled-button' href='/tarifs'>
          Voir les offres
        </a>
      ),
    });
    setModalVisible(true);
  }

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            {
              name: 'Accueil',
              to: '/',
            },
            {
              name: 'Outils: Outils pratiques',
              to: '/outils-pratiques',
            },
            {
              name: outil.name,
              to: outil.url,
            },
          ]}
        />
        <Banner
          title='Outils pratiques'
          subtitle={
            <Link className='link-with-arrow' to='/outils-pratiques'>
              <ArrowSm /> Retour à la liste des outils pratiques
            </Link>
          }
        />
        <PageTitle title={outil.name} />
        <div className='outil-pratique-container'>
          <div>
            <p dangerouslySetInnerHTML={{ __html: outil.content }} />
          </div>
          <div className='outil-preview-container'>
            {userSub ? (
              <a
                className='button filled-button default-button-settings'
                download={outil.file_name}
                href={outil.file_name}>
                Télécharger
                <Download className='clickable-icon' />
              </a>
            ) : (
              <FilledButton padding={width > 1200 ? '20px' : '5px 20px'} onClick={showSubModal}>
                Télécharger
                <Download className='clickable-icon' />
              </FilledButton>
            )}
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
                currentTarget.alt = 'Image non disponible';
              }}
              src={outil.img_ex}
              alt={outil.previewAlt}
            />
          </div>
        </div>
      </section>
      <InfiniteThumbnailsRow
        title={
          <h2 className={`mb-md ${width <= 992 ? 'text-center' : ''}`}>
            Découvrez nos conseils de pros sur cette thématique :
          </h2>
        }
        data={suggestions}
      />
      <FilledButton className={`mid-page-button ${width <= 1200 ? 'mt-md' : 'mt-xl'}`} to='/conseils-pros'>
        Voir tous nos conseils de pros
      </FilledButton>
    </div>
  );
}

export default OutilPratiqueDetail;
