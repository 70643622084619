import './PlanFinancement.css';
import React from 'react';
import Header from '../../../components/organisms/header/Header';
import Footer from '../../../components/organisms/footer/Footer';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import PlanFinancementForm from '../../../components/forms/planFinancementForm/PlanFinancementForm';

function PlanFinancement() {
  return (
    <section className='container page-container'>
      <Breadcrumbs
        routes={[
          {
            to: '/',
            name: 'Accueil',
          },
          {
            to: '/plan-financement',
            name: 'Plan de financement',
          },
        ]}
      />
      <Banner
        title='Plan de financement'
        subtitle='Réalisez et sauvegardez vos plans de financement en quelques clics'
      />
      <PlanFinancementForm />
    </section>
  );
}

export default PlanFinancement;
