import React, { useContext, useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import Info from '../../../../atoms/icons/general/info/Info';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import Select from '../../../../molecules/formComponents/select/Select';
import Input from '../../../../molecules/formComponents/input/Input';
import { max2500Validation, numberValidation } from '../../../../../utils/formValidation/FormValidation';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import { formatDate, getFormattedDate, getSelectValue, removeSpaces } from '../../../../../utils/Utils';
import { fetchDropdownValuesApi } from '../../../../../api/ParcoursApi';

function LoyerBail({ id, index }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const Form = { watch, register, setValue };
  const {
    showTooltip,
    moveToNextStep,
    step,
    setStep,
    getStepToGo,
    setGoToStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
    fillSelectsFromEstimationValues,
  } = useContext(ParcoursContext);
  const soumission_bail = watch('soumission_bail');
  const infosComplementaires = watch('infos_complementaires');
  const [dropdownsValues, setDropdownsValues] = useState([]);

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    if (estimationValues?.step >= index && !estimationValues?.loyer_bail?.montant_loyer_annuel)
      return setValue('soumission_bail', true);

    fillInputsFromEstimationValues(
      'loyer_bail',
      ['montant_loyer_annuel', 'montant_charges_locatives_annuelles', 'montant_taxe_fonciere'],
      setValue,
      estimationValues,
    );
    fillSelectsFromEstimationValues(
      'loyer_bail',
      ['destination_bail_commercial', 'logement_inclus', 'soumission_baux_multiples'],
      setValue,
      estimationValues,
    );
    setValue('date_echeance_bail_commercial', formatDate(estimationValues?.loyer_bail?.date_echeance_bail_commercial));
    setValue('note_step_10', estimationValues?.notes?.note_step_10);
    if (estimationValues?.loyer_bail?.infos_complementaires?.bailleur) {
      setValue('infos_complementaires', true);
      fillInputsFromEstimationValues(
        'infos_complementaires',
        ['bailleur', 'designation_locaux', 'destination_lieux', 'depot_garantie'],
        setValue,
        estimationValues?.loyer_bail,
      );
      fillSelectsFromEstimationValues(
        'infos_complementaires',
        ['duree_bail', 'revision_sur_les_loyers'],
        setValue,
        estimationValues?.loyer_bail,
      );
    }
  }, [estimationValues]);

  async function formSubmit(values) {
    console.log(values);
    postValues({
      loyer_bail: {
        soumission_baux_multiples: { id: !values.soumission_bail ? values.soumission_baux_multiples : null },
        montant_loyer_annuel: !values.soumission_bail ? removeSpaces(values.montant_loyer_annuel, 1) : null,
        montant_charges_locatives_annuelles: !values.soumission_bail
          ? removeSpaces(values.montant_charges_locatives_annuelles, 1)
          : null,
        montant_taxe_fonciere: !values.soumission_bail ? removeSpaces(values.montant_taxe_fonciere, 1) : null,
        date_echeance_bail_commercial: !values.soumission_bail
          ? getFormattedDate(values.date_echeance_bail_commercial)
          : null,
        destination_bail_commercial: {
          id: !values.soumission_bail ? getSelectValue(values.destination_bail_commercial) : null,
        },
        logement_inclus: { id: !values.soumission_bail ? values.logement_inclus : null },
        infos_complementaires: {
          bailleur: !values.soumission_bail && values.infos_complementaires ? values.bailleur : null,
          duree_bail: { id: !values.soumission_bail && values.infos_complementaires ? values.duree_bail : null },
          designation_locaux:
            !values.soumission_bail && values.infos_complementaires ? values.designation_locaux : null,
          destination_lieux: !values.soumission_bail && values.infos_complementaires ? values.destination_lieux : null,
          depot_garantie:
            !values.soumission_bail && values.infos_complementaires ? removeSpaces(values.depot_garantie, 1) : null,
          revision_sur_les_loyers: {
            id: !values.soumission_bail && values.infos_complementaires ? values.revision_sur_les_loyers : null,
          },
        },
      },
      notes: {
        note_step_10: !values.soumission_bail ? values.note_step_10 : null,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    const values = {};
    try {
      values.destination_bail_commercial = (await fetchDropdownValuesApi('destination_bail_commercial')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.duree_bail = (await fetchDropdownValuesApi('duree_bail')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.revision_sur_les_loyers = (await fetchDropdownValuesApi('revision_sur_les_loyers')).data;
    } catch (error) {
      console.error(error);
    }

    setDropdownsValues(values);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>loyer & bail</h2>
        <Checkbox // visible
          register={register}
          name='soumission_bail'
          label="l'activité n’est pas soumise à un bail commercial, ou l'exploitant détient les murs et ne se verse pas de loyer"
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && e.target.checked) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        <div className='row-1000'>
          <NumberInput // visible if !soumission_bail
            useForm={Form}
            visible={!soumission_bail}
            type='number'
            label={
              <>
                <div>
                  loyer <span className='outfit-black'>annuel</span> (hors charges)
                </div>
                <Info onClick={() => showTooltip('3_Loyer_annuel.webp')} />
              </>
            }
            name='montant_loyer_annuel'
            error={errors?.montant_loyer_annuel && errors.montant_loyer_annuel.message}
            validation={numberValidation}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            visible={!soumission_bail}
            type='number'
            label={
              <>
                <div>
                  charges locatives <span className='outfit-black'>annuel</span>
                </div>
                <Info onClick={() => showTooltip('4_Charges_locatives_annuelles.webp')} />
              </>
            }
            name='montant_charges_locatives_annuelles'
            error={errors?.montant_charges_locatives_annuelles && errors.montant_charges_locatives_annuelles.message}
            validation={numberValidation}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            visible={!soumission_bail}
            type='number'
            label={
              <>
                <div>taxe foncière</div>
                <Info onClick={() => showTooltip('4_Charges_locatives_annuelles.webp')} />
              </>
            }
            name='montant_taxe_fonciere'
            error={errors?.montant_taxe_fonciere && errors.montant_taxe_fonciere.message}
            validation={numberValidation}
            icon='euro'
          />
        </div>
        <Input
          setValue={setValue}
          visible={!soumission_bail}
          type='date'
          label={
            <>
              Date d’échéance du bail commercial
              <Info onClick={() => showTooltip('7_Date_echeance_bail.webp')} />
            </>
          }
          name='date_echeance_bail_commercial'
          error={errors?.date_echeance_bail_commercial && errors.date_echeance_bail_commercial.message}
          register={register}
        />
        <Select
          visible={!soumission_bail}
          label={
            <>
              destination du bail commercial
              <Info onClick={() => showTooltip('7Destination_du_bail.webp')} />
            </>
          }
          register={register}
          error={errors?.destination_bail_commercial && errors.destination_bail_commercial.message}
          name='destination_bail_commercial'>
          {dropdownsValues?.destination_bail_commercial?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <div className='row-1000'>
          <Radio
            visible={!soumission_bail}
            label={
              <>
                un logement habitable est-il inclus dans le bail commercial ?
                <Info onClick={() => showTooltip('8Logementinclus.webp')} />
              </>
            }
            name='logement_inclus'
            register={register}
            error={errors?.logement_inclus && errors.logement_inclus.message}
            content={[
              { value: 2, label: 'Non' },
              { value: 1, label: 'Oui' },
            ]}
          />
          <Radio
            visible={!soumission_bail}
            label="l'activité est-elle soumise à plusieurs baux commerciaux ?"
            name='soumission_baux_multiples'
            register={register}
            error={errors?.soumission_baux_multiples && errors.soumission_baux_multiples.message}
            content={[
              { value: 2, label: 'Non' },
              { value: 1, label: 'Oui' },
            ]}
          />
        </div>
        <Checkbox
          visible={!soumission_bail}
          label='je souhaite renseigner des informations complémentaires sur le bail commercial'
          name='infos_complementaires'
          register={register}
        />
        <div className='row-1000'>
          <Input
            visible={infosComplementaires && !soumission_bail}
            label='Identité du bailleur'
            name='bailleur'
            error={errors?.bailleur && errors.bailleur.message}
            register={register}
          />
          <Select
            visible={infosComplementaires && !soumission_bail}
            label='Durée du bail'
            register={register}
            error={errors?.duree_bail && errors.duree_bail.message}
            name='duree_bail'>
            {dropdownsValues?.duree_bail?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </div>
        <div className='row-1000'>
          <Input
            visible={infosComplementaires && !soumission_bail}
            label='Désignation des locaux'
            name='designation_locaux'
            error={errors?.designation_locaux && errors.designation_locaux.message}
            register={register}
          />
          <Input
            visible={infosComplementaires && !soumission_bail}
            label='Destination des locaux'
            name='destination_lieux'
            error={errors?.destination_lieux && errors.destination_lieux.message}
            register={register}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            icon='euro'
            visible={infosComplementaires && !soumission_bail}
            error={errors?.depot_garantie && errors.depot_garantie.message}
            label='Dépôt de garantie'
            name='depot_garantie'
            useForm={Form}
          />
          <Select
            visible={infosComplementaires && !soumission_bail}
            label='Révision du loyer'
            register={register}
            error={errors?.revision_sur_les_loyers && errors.revision_sur_les_loyers.message}
            name='revision_sur_les_loyers'>
            {dropdownsValues?.revision_sur_les_loyers?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </div>
        <Textarea
          visible={!soumission_bail}
          error={errors?.note_step_10 && errors.note_step_10.message}
          validation={max2500Validation}
          register={register}
          name='note_step_10'
          label='notes et remarques'
          className='mt-md'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default LoyerBail;
