import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FiltersGroup from '../../../components/organisms/filtersGroup/FiltersGroup';
import './MonCompte.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MesEstimations from './tabs/mesEstimations/MesEstimations';
import MesAbosFactures from './tabs/mesAbosFactures/MesAbosFactures';
import MonProfil from './tabs/monProfil/MonProfil';
import MesFormations from './tabs/mesFormations/MesFormations';
import AppContext from '../../../context/AppContext';
import { useNavigate, useParams } from 'react-router-dom';

function MonCompte() {
  const [currentTab, setCurrentTab] = useState(0);
  const { getUserId } = useContext(AppContext);
  const [activeTabIndexChanger, setActiveTabIndexChanger] = useState(undefined);
  const navbarRef = useRef();
  const navigate = useNavigate();
  const { tabParam } = useParams();

  useEffect(() => {
    if (!getUserId()) navigate('/connexion');
  }, []);

  useEffect(() => {
    let tab = 0;
    switch (tabParam) {
      case 'mes-formations':
        tab = 1;
        break;
      case 'mon-profil':
        tab = 2;
        break;
      case 'mes-abonnements-factures':
        tab = 3;
        break;
    }

    setCurrentTab(tab);
    setActiveTabIndexChanger(tab);
  }, [tabParam]);

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            { name: 'Accueil', to: '/' },
            { name: 'Mon compte', to: '/mon-compte/mes-estimations' },
          ]}
        />
        <Banner title='Mon compte' subtitle='Rapports, factures, offres... Retrouvez toutes vos informations' />
      </section>
      <div className='sticky-container' ref={navbarRef}>
        <FiltersGroup
          changeActiveTabIndex={activeTabIndexChanger}
          data={[
            {
              name: 'Mes estimations',
              onClick: setCurrentTab,
              params: [0],
            },
            {
              name: 'Mes formations',
              onClick: setCurrentTab,
              params: [1],
            },
            {
              name: 'Mon profil',
              onClick: setCurrentTab,
              params: [2],
            },
            {
              name: 'Mes abonnements & factures',
              onClick: setCurrentTab,
              params: [3],
            },
          ]}
        />
      </div>
      {currentTab === 0 && <MesEstimations />}
      {currentTab === 1 && <MesFormations />}
      {currentTab === 2 && <MonProfil navbarRef={navbarRef} />}
      {currentTab === 3 && <MesAbosFactures />}
    </div>
  );
}

export default MonCompte;
