import './MonProfil.css';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import Card from '../../../../../components/molecules/cards/card/Card';
import FilledButton from '../../../../../components/molecules/buttons/filledButton/FilledButton';
import ExpendableGroup from '../../../../../components/organisms/expendableGroup/ExpendableGroup';
import VisitingCardForm from '../../../../../components/forms/compte/visitingCardForm/VisitingCardForm';
import FileInput from '../../../../../components/molecules/formComponents/fileInput/FileInput';
import RapportLogo from '../../../../../components/atoms/icons/monCompte/rapportLogo/RapportLogo';
import { checkImage } from '../../../../../utils/Utils';
import Lock from '../../../../../components/atoms/icons/monCompte/lock/Lock';
import ArrowSm from '../../../../../components/atoms/icons/general/arrowSm/ArrowSm';
import ProfilForm from '../../../../../components/forms/compte/profilForm/ProfilForm';
import AppContext from '../../../../../context/AppContext';
import { fetchUserDataApi } from '../../../../../api/CompteApi';
import ForgotPasswordModal from '../../../../../components/forms/modals/compte/forgotPasswordModal/ForgotPasswordModal';

function MonProfil({ navbarRef }) {
  const [visitingCards, setVisitingCards] = useState([]);
  const [importCardError, setImportCardError] = useState('');
  const [userData, setUserData] = useState({});
  const [defaultVisitingCard, setDefaultVisitingCard] = useState(2);

  const { width } = useWindowSize();
  const { createNotification, getUserId, setModalContent, setModalVisible } = useContext(AppContext);

  useEffect(() => {
    fetchCartesVisites();
    getUserData();
  }, []);

  async function fetchCartesVisites() {
    const cartes = [
      {
        id: '1',
        cardName: 'Carte 1',
        lastname: 'Guinard',
        firstname: 'Pablito',
        position: 'Alternant',
        slogan: 'Vive moi',
        company: 'EMC.fr',
        address1: '4 bis Rue Elie Rochette',
        address2: 'nan rien',
        zipCode: '69007',
        city: 'LYON',
        email: 'guinardpablo@gmail.com',
        website: 'estimermoncommerce.fr',
        fixedPhone: '04 74 56 44 29',
        mobilePhone: '06 68 39 32 21',
        siret: '7000',
        rsac: '',
        idType: 1,
        isDefault: false,
        avatar: 'avatar.jpg',
        logo: 'company.jpeg',
        mentions: '',
      },
      {
        id: '2',
        cardName: 'Carte 12',
        lastname: 'Guinard',
        firstname: 'Pablito0',
        position: 'sous-fifre',
        slogan: "Vive moi j'suis bô",
        company: 'EMC',
        address1: '4 bis Rue Elie Rochette',
        address2: 'nan rien',
        zipCode: '69007',
        city: 'LYON',
        email: 'guinardpablo@gmail.com',
        website: 'estimermoncommerce.fr',
        fixedPhone: '04 74 56 44 29',
        mobilePhone: '06 68 39 32 21',
        siret: '',
        rsac: '80',
        idType: 1,
        isDefault: false,
        mentions: '',
      },
      {
        id: '3',
        cardName: 'Carte 3',
        lastname: 'Guinard',
        firstname: 'Pablito',
        position: '',
        slogan: 'Vive moi',
        company: 'EMC.fr',
        address1: '4 bis Rue Elie Rochette',
        address2: 'nan rien',
        zipCode: '69007',
        city: 'LYON',
        email: 'guinardpablo@gmail.com',
        website: 'estimermoncommerce.fr',
        fixedPhone: '04 74 56 44 29',
        mobilePhone: '06 68 39 32 21',
        siret: '7000',
        rsac: '',
        idType: 1,
        isDefault: false,
        avatar: 'avatar.jpg',
        logo: 'company.jpeg',
        mentions: 'mentions légales',
      },
      {
        id: '4',
        cardName: 'Carte 4 (importée)',
        idType: 2,
        picture: 'carte.jpg',
        mentions: 'mentions légales',
        isDefault: false,
      },
    ];

    setVisitingCards(cartes);
  }

  function importVisitingCard(e) {
    const file = e.target.files[0];
    if (!file) return;

    const errorMsg = checkImage(file, 20);
    if (errorMsg === '') {
      // TODO Ajax image
    }
    setImportCardError(errorMsg);
  }

  async function getUserData() {
    try {
      const user = (await fetchUserDataApi(getUserId())).data;
      setUserData(user);
    } catch (error) {
      createNotification(
        'Une erreur est survenue lors de la récupération de vos données. Veuillez réessayer plus tard.',
        'var(--red)',
        'var(--dark-blue',
      );
    }
  }

  function createVisitingCard() {
    // TODO
  }

  function showForgotPasswordModal() {
    setModalContent({ title: 'Mot de passe oublié', content: <ForgotPasswordModal email={userData.email} /> });
    setModalVisible(true);
  }

  return (
    <section className='container'>
      <div className='mon-profil-container'>
        <div>
          <Card>
            <h2>MES CARTES DE VISITE ET MENTIONS LÉGALES</h2>
            <div className='visiting-card-buttons'>
              <FileInput
                onChange={importVisitingCard}
                error={importCardError}
                name='import-visiting-card'
                removeButton={false}
                padding='10px 20px'
                bgColor='var(--sky-blue)'>
                Importer une carte numérique
              </FileInput>
              <FilledButton onClick={createVisitingCard} padding='10px 20px'>
                Créer une carte{' '}
              </FilledButton>
            </div>
            <ExpendableGroup
              defaultVisitingCard={defaultVisitingCard}
              setDefaultVisitingCard={setDefaultVisitingCard}
              id='visiting-cards-expendable-group'
              data={visitingCards.map((card, key) => ({
                id: card.id,
                title: card.cardName,
                content: <VisitingCardForm navbarRef={navbarRef} visitingCardData={card} id={key} />,
              }))}
            />
          </Card>
          {width <= 992 && (
            <div className='mon-profil-responsive-buttons'>
              <FilledButton bgColor='var(--light-blue)' padding='20px' color='var(--dark-blue)'>
                <RapportLogo />
                Modifier mon logo sur l'en-tête de mes rapports d'estimation
              </FilledButton>
              <FilledButton bgColor='var(--light-blue)' padding='20px' color='var(--dark-blue)'>
                <RapportLogo rotate='180deg' />
                Modifier mon logo en pieds de pages de mes rapports d'estimation
              </FilledButton>
            </div>
          )}
          <Card className='mt-lg mon-profil' bgColor='var(--light-blue)'>
            <h2 className='mb-lg'>MON PROFIL</h2>
            <ProfilForm userData={userData} />
          </Card>
          {width <= 992 && (
            <>
              <div className='mon-profil-responsive-buttons'>
                <FilledButton
                  bgColor='var(--light-gold)'
                  padding='20px'
                  color='var(--dark-blue)'
                  onClick={showForgotPasswordModal}>
                  <Lock />
                  Modifier mon mot de passe
                </FilledButton>
              </div>
              <div className='mon-profil-links'>
                <Link className='text-grey link-with-arrow' to='TODO'>
                  Désactiver mon compte
                  <ArrowSm color='var(--grey)' rotation='180deg' />
                </Link>
                <Link className='text-grey link-with-arrow' to='TODO'>
                  Supprimer mon compte
                  <ArrowSm color='var(--grey)' rotation='180deg' />
                </Link>
              </div>
            </>
          )}
        </div>
        {width > 992 && (
          <div className='flex f-column gap-lg'>
            <FilledButton bgColor='var(--light-blue)' padding='20px' color='var(--dark-blue)'>
              <RapportLogo />
              Modifier mon logo sur l'en-tête de mes rapports d'estimation
            </FilledButton>
            <FilledButton bgColor='var(--light-blue)' padding='20px' color='var(--dark-blue)'>
              <RapportLogo rotate='180deg' />
              Modifier mon logo en pieds de pages de mes rapports d'estimation
            </FilledButton>
            <FilledButton
              bgColor='var(--light-gold)'
              padding='20px'
              color='var(--dark-blue)'
              onClick={showForgotPasswordModal}>
              <Lock />
              Modifier mon mot de passe
            </FilledButton>
            <div className='mon-profil-links'>
              <Link className='text-grey link-with-arrow' to='TODO'>
                Désactiver mon compte
                <ArrowSm color='var(--grey)' rotation='180deg' />
              </Link>
              <Link className='text-grey link-with-arrow' to='TODO'>
                Supprimer mon compte
                <ArrowSm color='var(--grey)' rotation='180deg' />
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default MonProfil;
