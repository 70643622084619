import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/organisms/header/Header';
import Footer from '../../../components/organisms/footer/Footer';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import TextHr from '../../../components/molecules/textHr/TextHr';
import cookiesMesureAudienceImg from '../../../assets/politiqueConfidentialite/cookiesMesureAudience.png';
import cookiesFonctionnementImg from '../../../assets/politiqueConfidentialite/cookiesNecessairesBonFonctionnement.png';
import cookiesPerformanceImg from '../../../assets/politiqueConfidentialite/cookiesPerformance.png';
import './PolitiqueConfidentialite.css';

function PolitiqueConfidentialite() {
  return (
    <section className='container page-container'>
      <Breadcrumbs
        routes={[
          { name: 'Accueil', route: '/' },
          { name: 'Liens légaux: Politique de confidentialité', route: '/politique-confidentialite' },
        ]}
      />
      <Banner title='Polique de confidentialité' subtitle="Cette politique s'applique au 15 octobre 2021" />
      <TextHr className='mb-lg mt-xl'>DONNÉES PERSONNELLES</TextHr>
      <div className='big-text-container'>
        <h3 className='h2-uppercase my-md'>1 – Présentation du Site et du traitement de données personnelles</h3>
        <p>
          Le site Internet accessible à l’adresse{' '}
          <Link className='link' to='/'>
            www.estimermoncommerce.fr
          </Link>{' '}
          (ci-après désigné « le Site ») est édité et exploité par la société GALAXICO, SAS au capital social de 10 000
          €, immatriculée au RCS de PARIS sous le numéro 878 387 885, ayant son siège social situé 41 rue de Cronstadt
          75015 PARIS (FRANCE), et dont les coordonnées sont mentionnées dans les{' '}
          <Link className='link' to='mentions-legales'>
            mentions légales
          </Link>
          .
        </p>
        <p>
          Lors de votre accès et de votre utilisation du Site, la société GALAXICO est susceptible de collecter
          certaines données à caractère personnel vous concernant, c’est-à-dire des informations qui se rapportent à
          vous en tant que personne physique et qui permettent à la société GALAXICO de vous identifier, soit
          directement (comme par exemple, vos nom, prénom, adresse e-mail ou postale), soit indirectement (comme par
          exemple, l’adresse IP de votre terminal de navigation).
        </p>
        <p>
          Ces données personnelles peuvent être collectées lors de votre navigation sur le Site, de l’utilisation du
          formulaire de contact, de votre inscription à la newsletter, de la création d’un compte client ou encore de la
          passation d’une commande.
        </p>
        <p>
          Vos données personnelles font ensuite l’objet d’un traitement automatisé mis en œuvre par la société GALAXICO.
        </p>
        <p>
          La société GALAXICO s'engage à traiter vos données personnelles de manière loyale et licite, dans le respect
          des dispositions de la Loi n°78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux
          libertés et du Règlement (UE) n° 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à
          l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, dit
          Règlement Général sur la Protection des Données (ou « RGPD »).
        </p>
        <p>
          La présente Politique de confidentialité a pour objet de vous informer sur les conditions dans lesquelles la
          société GALAXICO traite vos données personnelles, tout en veillant à leur protection.
        </p>
        <h3 className='h2-uppercase my-md'>2 – Qui collecte vos données personnelles sur le Site&nbsp;?</h3>
        <p>Le responsable du traitement de vos données personnelles collectées sur le Site est la société GALAXICO.</p>
        <h3 className='h2-uppercase my-md'>
          3 – Quelles sont les données personnelles vous concernant susceptibles d’être traitées par GALAXICO&nbsp;?
        </h3>
        <p>La société GALAXICO peut être amenée à traiter les données personnelles suivantes vous concernant :</p>
        <ul className='dots-list'>
          <li>Votre nom ;</li>
          <li>Votre prénom ;</li>
          <li>Votre nature d’utilisateur ;</li>
          <li>Votre raison sociale ;</li>
          <li>Votre enseigne commerciale ;</li>
          <li>Votre numéro de Siret ;</li>
          <li>Votre numéro de téléphone ;</li>
          <li>Votre adresse e-mail ;</li>
          <li>Votre adresse postale ;</li>
          <li>Votre nature d'utilisateur ;</li>
          <li>Vos informations de paiement (carte bancaire) ;</li>
          <li>Vos données de connexion (date et heure de connexion, adresse IP) et de navigation ;</li>
          <li>L’historique de vos commandes passées sur le Site ;</li>
          <li>L’historique des produits/services consultés ;</li>
          <li>Des données relatives à un incident de livraison ;</li>
          <li>Des données relatives à une éventuelle réclamation.</li>
        </ul>
        <h3 className='h2-uppercase my-md'>4 – A quel moment vos données personnelles sont-elles collectées&nbsp;?</h3>
        <p>
          La société GALAXICO collecte les données personnelles que vous fournissez volontairement, notamment lorsque :
        </p>
        <ul className='dots-list'>
          <li>Vous remplissez un formulaire de demande de contact ;</li>
          <li>Vous vous inscrivez à la newsletter ;</li>
          <li>Vous vous inscrivez pour télécharger nos outils pratiques</li>
          <li>Vous créez ou modifiez votre compte client ;</li>
          <li>Vous effectuez le parcours d’estimation de fonds de commerce, titres de société ou murs commerciaux ;</li>
          <li>Vous remplissez les formulaires de simulation de crédit et de calcul de cotisations ;</li>
          <li>Vous effectuez une commande de produits/services sur le Site.</li>
        </ul>
        <p>
          Les champs visés par un astérisque (*) sur les formulaires de collecte de vos données personnelles sur le Site
          revêtent un caractère obligatoire : la société GALAXICO attire votre attention sur le fait que si vous ne
          fournissez pas ces données, vous ne pourrez pas bénéficier des produits/services proposés sur le Site.
        </p>
        <p>
          D’autres informations de nature technique concernant la consultation du Site ou l’utilisation de ses
          fonctionnalités, notamment l’adresse IP de votre terminal de navigation, sont collectées automatiquement du
          fait de votre navigation sur le Site et vos actions sur celui-ci, et ce par l’intermédiaire de cookies.
        </p>
        <p>Vous trouverez ci-après des informations sur les cookies utilisés par la société GALAXICO.</p>
        <h3 className='h2-uppercase my-md'>
          5 – Et si vous fournissez des données personnelles concernant des tiers&nbsp;?
        </h3>
        <p>
          Lorsque vous fournissez sur le Site des données personnelles concernant d’autres personnes que vous, vous
          garantissez à la société GALAXICO avoir reçu les autorisations et consentements nécessaires des personnes
          concernées par ces données personnelles avant de les fournir à la société GALAXICO.
        </p>
        <h3 className='h2-uppercase my-md'>
          6 – Sur quelles bases juridiques et pour quelles finalités sont traitées vos données personnelles&nbsp;?
        </h3>
        <p>
          Sur chacune des bases juridiques suivantes, la société GALAXICO effectue un traitement de vos données
          personnelles pour les finalités correspondantes :
        </p>
        <p>
          (i). Pour les{' '}
          <b>
            <u>besoins de l’exécution d’un contrat</u>
          </b>{' '}
          passé avec la société GALAXICO ou de{' '}
          <b>
            <u>mesures précontractuelles</u>
          </b>{' '}
          prises à votre demande, vos données personnelles sont traitées aux fins de :
        </p>
        <ul className='dots-list'>
          <li>
            Gérer la relation clients : gestion du compte client, des commandes de produits/services, des opérations de
            paiement, des livraisons de produits/services, des factures, de la comptabilité, de la relation client, des
            réclamations, des impayés et du contentieux ;
          </li>
          <li>Gérer vos demandes d’exercice de vos droits sur vos données personnelles.</li>
        </ul>
        <p>
          (ii). Sur la base de{' '}
          <b>
            <u>l’intérêt légitime</u>
          </b>{' '}
          de la société GALAXICO à améliorer la qualité du Site, de ses produits/services, vos données personnelles sont
          traitées aux fins de :
        </p>
        <ul className='dots-list'>
          <li>
            Réaliser des opérations de statistiques, d’analyse, de prospection et de segmentation des prospects et
            clients, afin d’améliorer la connaissance par la société GALAXICO de ses clients et prospects et d’adapter
            ses offres ;
          </li>
          <li>
            Vous adresser par e-mail des publicités et offres commerciales pour des produits/services analogues à ceux
            déjà fournis par la société GALAXICO ;
          </li>
          <li>
            Faciliter et rendre plus conviviale votre navigation sur le Site, le personnaliser selon votre terminal de
            navigation, vos habitudes de navigation ou vos affinités.
          </li>
        </ul>
        <p>
          (iii). Sur la base du{' '}
          <b>
            <u>consentement</u>
          </b>{' '}
          que vous avez donné à la société GALAXICO en ce sens, vos données personnelles sont traitées aux fins de :
        </p>
        <ul className='dots-list'>
          <li>Vous permettre d’entrer en contact avec la société GALAXICO au moyen du formulaire de contact ;</li>
          <li>
            Gérer la satisfaction des clients au moyen du recueil d’avis sur les produits/services proposés à la vente ;
          </li>
          <li>
            Vous adresser par e-mail la newsletter ainsi que des publicités et offres commerciales sur ses produits et
            services ;
          </li>
          <li>
            Vous informer par e-mail de la bonne livraison de votre commande et de sa disponibilité dans votre espace
            personnel sur le Site.
          </li>
        </ul>
        <h3 className='h2-uppercase my-md'>7 – A qui vos données personnelles sont-elles transmises&nbsp;?</h3>
        <p>Le destinataire de vos données personnelles est la société GALAXICO.</p>
        <p>
          La société GALAXICO est également amenée à transmettre vos données personnelles à des sous-traitants qui les
          traitent pour son compte et selon ses instructions. Ces destinataires sous-traitants de vos données
          personnelles sont notamment :
        </p>
        <ul className='dots-list'>
          <li>
            L’organisme bancaire chargé du paiement en ligne sécurisé des produits/services commandés sur le Site, en
            l'occurrence la société Stripe ;
          </li>
          <li>
            Les prestataires techniques externes de la société GALAXICO qui interviennent dans le développement, la
            diffusion, le fonctionnement et la maintenance du Site, notamment le développeur et l’hébergeur ;
          </li>
          <li>
            Les prestataires externes de la société GALAXICO intervenant dans l’amélioration de votre expérience lors de
            votre navigation sur le Site ou l’expédition de la newsletter.
          </li>
        </ul>
        <p>
          Enfin, la société GALAXICO pourrait être amenée à communiquer vos données personnelles à des tiers en
          application d’une loi, d’un règlement, d’une décision d’une autorité réglementaire ou judiciaire et enfin, si
          nécessaire, afin de préserver ses propres droits et intérêts, notamment en cas de violation des{' '}
          <Link className='link' to='CGU'>
            Conditions Générales d’Utilisation
          </Link>{' '}
          du Site ou des{' '}
          <Link className='link' to='CGV'>
            Conditions Générales de Vente
          </Link>
          .
        </p>
        <h3 className='h2-uppercase my-md'>
          8 – Vos données personnelles sont-elles transférées en dehors de l’Union Européenne&nbsp;?
        </h3>
        <p>
          Non, nos serveurs sont en France. La société GALAXICO n’effectue pas et n’a pas l’intention d’effectuer de
          transfert de vos données personnelles vers un pays tiers à l’Union Européenne et de l’Espace Economique
          Européen n’assurant pas un niveau adéquat de protection des données personnelles dans les conditions prévues
          par le RGPD.
        </p>
        <h3 className='h2-uppercase my-md'>
          9 – Combien de temps vos données personnelles sont-elles conservées&nbsp;?
        </h3>
        <p>
          La société GALAXICO conserve vos données personnelles pendant une durée qui n’excède pas la durée nécessaire
          aux finalités pour lesquelles elles ont été collectées, dans le respect de la législation en vigueur.
        </p>
        <p>
          Les données personnelles relatives aux prospects ayant uniquement formulé une demande de contact ou de devis
          sont conservées pendant 3 ans à compter de leur collecte.
        </p>
        <p>
          Les données personnelles relatives à un compte client n’ayant jamais servi à effectuer de commande de
          produits/services sont conservées pendant 3 ans à compter de la dernière activité enregistrée sur le compte.
          Passé ce délai, la société GALAXICO se réserve le droit de supprimer votre compte.
        </p>
        <p>
          Les données personnelles relatives à un compte client ayant servi à effectuer au moins une commande de
          produits/services sont conservées pendant 3 ans à compter de la dernière activité enregistrée sur le compte
          client ou de la commande, à l’exception des documents, pièces comptables et informations permettant de prouver
          un contrat de vente conclu sur le Site qui sont conservés pendant une durée de 10 ans à compter de leur
          collecte, et ce à titre de preuve juridique, comptable et fiscale.
        </p>
        <h3 className='h2-uppercase my-md'>
          10 – Quels sont vos droits sur vos données personnelles et comment pouvez-vous les exercer&nbsp;?
        </h3>
        <p>
          La société GALAXICO vous informe que, sur les données personnelles qu’elle traite vous concernant, vous
          disposez à tout moment des droits suivants :
        </p>
        <ul className='dots-list'>
          <li>Un droit d’accès ;</li>
          <li>Un droit de rectification ;</li>
          <li>Un droit d’effacement ;</li>
          <li>Un droit à la limitation du traitement ;</li>
          <li>Un droit à la portabilité ;</li>
          <li>Un droit d’opposition au traitement, notamment à des fins de prospection commerciale ;</li>
          <li>
            Un droit de retirer à tout moment le consentement que vous avez pu donner lors de la fourniture de certaines
            données personnelles, sans que cela ne porte atteinte à la licéité du traitement fondé sur votre
            consentement jusqu’au retrait de celui-ci ;
          </li>
          <li>
            Un droit de définir des directives générales ou particulières relatives à la conservation, à l’effacement ou
            à la communication de vos données personnelles après votre décès.
          </li>
        </ul>
        <p>
          Ces droits peuvent être exercés à tout moment en adressant une demande écrite en ce sens, accompagnée d’une
          copie de votre carte d’identité, à la société GALAXICO :
        </p>
        <ul className='dots-list'>
          <li>Soit par courrier postal en écrivant au 41 rue de Cronstadt 75015 PARIS (FRANCE) ;</li>
          <li>
            Soit par e-mail à l’adresse{' '}
            <Link className='link' to='mailto:contact@estimermoncommerce.fr'>
              contact@estimermoncommerce.fr
            </Link>
          </li>
        </ul>
        <p>
          Vous disposez également du droit d’introduire une réclamation auprès de la Commission Nationale de
          l’Informatique et des Libertés :
        </p>
        <ul className='dots-list'>
          <li>Soit par courrier postal en écrivant au 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07 ;</li>
          <li>
            Soit par Internet sur le site{' '}
            <Link className='link' to='https://www.cnil.fr' target='_blank'>
              www.cnil.fr
            </Link>
            .
          </li>
        </ul>
        <p>
          Images, photos et icônes : le site{' '}
          <Link className='link' to='/'>
            www.estimermoncommerce.fr
          </Link>{' '}
          recourt à l’utilisation d’images, photos et icônes en version gratuite recueillies sur les sites{' '}
          <Link className='link' to='https://www.cnil.fr' target='_blank'>
            www.cnil.fr
          </Link>{' '}
          et{' '}
          <Link className='link' to='https://www.pixabay.com' target='_blank'>
            www.pixabay.com
          </Link>
          .
        </p>
        <TextHr className='my-lg'>COOKIES</TextHr>
        <p>
          Afin d’améliorer la qualité du Site et d’y faciliter votre expérience utilisateur, des informations relatives
          à la navigation de votre terminal de navigation sont susceptibles d’être enregistrées dans des petits fichiers
          appelés « cookies » déposés sur votre terminal de navigation, sous réserve des choix que vous auriez exprimés
          à leur sujet et que vous pouvez modifier à tout moment.
        </p>
        <h3 className='h2-uppercase my-md'>1 – Qu’est-ce qu’un cookie ?</h3>

        <p>
          Un cookie est un petit fichier texte enregistré sur le disque dur de votre terminal de navigation (votre
          ordinateur, votre tablette, votre smartphone ou votre objet connecté) par le biais de votre navigateur Web à
          l'occasion de la consultation d'un service en ligne.
        </p>
        <p>
          L'objectif des cookies est de vous procurer un usage et un service de qualité lors de votre visite et, de
          cette façon, de vous proposer la meilleure expérience possible sur le Site.
        </p>
        <p>
          Les cookies ne stockent aucune donnée personnelle sensible vous concernant et reconnaissent simplement
          l'appareil que vous êtes en train d'utiliser pour vous permettre d’utiliser correctement les fonctionnalités
          proposées par la société GALAXICO sur le Site.
        </p>
        <h3 className='h2-uppercase my-md'>2 – Quels sont les cookies déposés sur le Site et qui les dépose ?</h3>
        <p>
          Pour vous rendre la navigation sur le Site toujours plus agréable et facile, la société GALAXICO utilise
          plusieurs types de cookies.
        </p>
        <h3 className='h2-uppercase my-md'>2.1 – Les cookies nécessaires au bon fonctionnement du Site</h3>
        <p>
          Ces cookies ont pour objectif de fluidifier votre navigation sur le Site et de permettre l’utilisation
          optimale de leurs différentes fonctionnalités.
        </p>
        <p>
          Ils sont essentiels pour que le Site ainsi que les fonctionnalités que la société GALAXICO y propose
          fonctionnent correctement.{' '}
        </p>
        <p>Ces cookies permettent au Site de fonctionner de manière optimale.</p>
        <p>Les cookies strictement nécessaires au fonctionnement du Site sont les suivants :</p>
        <img
          className='politique-confidentialite-table'
          src={cookiesFonctionnementImg}
          alt='cookies nécessaires bon fonctionnement'
        />
        <h3 className='h2-uppercase my-md'>2.2 – Les cookies de performance</h3>
        <p>
          Les cookies de performance ont pour objectif de rendre le fonctionnement du Site plus convivial et vous offrir
          une expérience en ligne plus personnalisée.
        </p>
        <p>
          Vous pouvez vous y opposer et les supprimer en utilisant les paramètres de votre navigateur, mais la société
          GALAXICO attire votre attention sur le fait que votre expérience utilisateur risque d’en être dégradée.
        </p>
        <p>Les cookies de performance sont les suivants :</p>
        <img className='politique-confidentialite-table' src={cookiesPerformanceImg} alt='cookies performance' />
        <h3 className='h2-uppercase my-md'>2.3 – Les cookies tiers de mesure d’audience</h3>
        <p>
          La société GALAXICO utilise des cookies de mesure d’audience pour analyser l’utilisation qui est faite du Site
          afin d’en améliorer le fonctionnement.
        </p>
        <p>
          Ces cookies permettent notamment de recenser le nombre de visiteurs, d’analyser les pages les plus visitées,
          d’enregistrer la façon dont vous utilisez le Site et ce afin d’améliorer sa performance et son contenu et
          d’améliorer votre expérience de navigation.
        </p>
        <p>Les cookies de mesure d’audience utilisés par la société GALAXICO sont les suivants :</p>
        <img className='politique-confidentialite-table' src={cookiesMesureAudienceImg} alt='cookies mesure audience' />
        <h3 className='h2-uppercase my-md'>2.4 – Les cookies tiers de réseaux sociaux et de contenu multimédia</h3>
        <p>
          Le Site contient certaines fonctionnalités visant à améliorer son interactivité et s’appuyant sur des services
          proposés par des sites tiers. Il s’agit notamment :
        </p>
        <ul className='dots-list'>
          <li>Des boutons de partage vers les réseaux sociaux (LinkedIn, Facebook, Instagram et Pinterest) ;</li>
          <li>Des vidéos diffusées sur le Site (YouTube).</li>
        </ul>
        <p>
          Si vous donnez votre consentement à l’utilisation de ces fonctionnalités, ces tiers (LinkedIn, Facebook,
          Instagram, Pinterest et YouTube) déposeront des cookies qui vous permettront de visualiser directement sur le
          Site du contenu hébergé par ces tiers ou de partager nos contenus sur le site de ces tiers.
        </p>
        <p>
          Via ces cookies, ces tiers collecteront et utiliseront vos données de navigation pour des finalités qui leur
          sont propres, conformément à leur politique de confidentialité.
        </p>
        <p>
          Nous vous invitons à consulter les politiques de confidentialité de ces tiers pour connaitre les conditions
          dans lesquelles ils traitent les données personnelles vous concernant recueillies lors de votre utilisation de
          leurs services.
        </p>
        <p>
          Pour exercer vos droits sur les données collectées par ces tiers ou pour toute question sur ces traitements,
          vous pouvez les contacter directement.
        </p>
        <p>Les cookies tiers de réseaux sociaux et de contenu multimédia utilisés sur le Site sont les suivants :</p>
        <p className='outfit-bold'>• Réseaux sociaux</p>
        <p>
          Les cookies qui sont déposés via les boutons de partage vers les réseaux sociaux ont pour finalité de vous
          permettre de faciliter le partage de contenu.
        </p>
        <p>Les cookies sont ceux proposés et utilisés par LinkedIn, Facebook, Instagram et Pinterest.</p>
        <p className='outfit-bold'>• Contenu multimédia</p>
        <p>
          Les cookies qui sont déposés via les services de consultation et de partage de contenu multimédia, en
          particulier de vidéo, ont pour finalité de vous permettre de visionner directement sur le Site le contenu
          multimédia.
        </p>
        <p>Les cookies sont ceux proposés et utilisés par YouTube.</p>
        <h3 className='h2-uppercase my-md'>3 – Comment accepter ou refuser le dépôt de cookies ?</h3>
        <p>
          Dès le début de votre navigation sur le Site, vous pouvez exprimer vos souhaits concernant les cookies, en
          sélectionnant votre choix en la matière dans le bandeau des cookies s’affichant sur le Site.
        </p>
        <p>
          Les cookies dont le dépôt est soumis à votre consentement préalable ne peuvent être déposés qu’après que vous
          ayez donné votre consentement exprès.
        </p>
        <p>
          Vous pouvez à tout moment mettre à jour vos choix et retirer votre consentement au dépôt des cookies en
          cliquant sur « Gérer les cookies » au bas de chaque page.
        </p>
        <p>
          La société GALAXICO attire toutefois votre attention sur le fait que si vous refusez les cookies, il est
          possible que vous ne puissiez pas bénéficier de certaines fonctionnalités du Site.
        </p>
      </div>
    </section>
  );
}

export default PolitiqueConfidentialite;
